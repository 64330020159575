import React, { useState } from "react";
import Alert from "../alert";
import { Grid } from "@material-ui/core";
import { deleteNotificationAction } from "redux/actions/notificationsAction";
import { useDispatch } from "react-redux";
import AnaliticNotifications from "./analitics"
const parseNotifications = (listNot = []) => {
  return listNot.map((item) => {
    item.show = true;
    return item;
  });
};

const ListNotifications = ({ notificationsProps = [], onClose }) => {
  const [notifications, setnotifications] = useState(
    parseNotifications(notificationsProps)
  );
  const dispatch = useDispatch();
  const removeNotification = (allNotifications, deleteNotification) =>
    dispatch(deleteNotificationAction(allNotifications, deleteNotification));

  function closeAlert(notification) {
    removeNotification([...notifications], notification);
    const indexNotif = notifications.findIndex(
      (item) => item.key === notification.key
    );
    notifications.splice(indexNotif, 1);
    if (notifications.length === 0) {
      setnotifications([
        { show: true, msg: "No tiene más notificaciones.", type: "info" },
      ]);
    } else {
      setnotifications([...notifications]);
    }

  }

  const handleClickInstruction = () => {
    if(typeof onClose === "function"){
      onClose();
    }
  }

  const getInstructions = (keyword) => {
    const Instructions = AnaliticNotifications.GetInstructionsErrors(keyword);
    if(typeof Instructions === "function"){
      return <Instructions onClick={handleClickInstruction}/>
    }
    return null;
  }

  return (
    <Grid container justify="center" direction="row" spacing={1}>
      {notifications.map((item) => (
        <Grid key={item.key} item xs={12}>
          <Alert
            tipo={item.type}
            mensaje={item.msg}
            mesajeSubrayado={getInstructions(item.keyword)}
            titulo={null}
            mostrar={item.show}
            cerrarAlerta={() => closeAlert(item)}
          />
          <hr />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListNotifications;
