import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  makeStyles,
  Grid,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { useStylesGlobal } from "configurations/styles/theme";
import ReferenceDocument from "../../General/ReferenceDocument";
import { useSelector, useDispatch } from "react-redux";
import HTTPAuth from "configurations/axios";
import apis from "../apis";
import {
  addRISEAction,
  addReasonAction,
  changeDateAuthAction,
  changeDateEmisionAction,
  changeNumDocAction,
  changeErrorLabelsAction,
} from "redux/actions/CreditNoteActions";

import { selectedResourceAction } from "redux/actions/resourcesActions";

import { getSub } from "redux/actions/userActions";
import checkInputs from "utils/checkInputs";
import AssignmentIcon from "@material-ui/icons/Assignment";

const useStyles = makeStyles({
  container: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    marginTop: "0.5%",
    marginBottom: "25px",
    paddingBottom: "0.5%",
    paddingLeft: "0%",
  },
  subtitle: {
    paddingLeft: "0%",
  },
  labelNumComp: {
    marginLeft: "1%",
  },
});

const DataCreditNote = () => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [secuencialComp, setSecuencialComp] = useState(null);
  const tokenUser = useSelector((state) => state.user.tk);
  const creditNote = useSelector((state) => state.creditNote);
  const isRecoveryInvoice = creditNote.recoveryData;
  
  const dispatch = useDispatch();
  const addRise = (rise) => dispatch(addRISEAction(rise));
  const addReason = (reason) => dispatch(addReasonAction(reason));
  const changeDateEmision = (date) => dispatch(changeDateEmisionAction(date));
  const changeDateAuth = (date) => dispatch(changeDateAuthAction(date));
  const changeNumDoc = (numDoc) => dispatch(changeNumDocAction(numDoc));
  const changeErrorLabels = (error) => dispatch(changeErrorLabelsAction(error));
  const redirectHome = () =>
    dispatch(
      selectedResourceAction({
        id: "INI",
        titlePage: "Inicio",
      })
    );

  useEffect(() => {
    const tk = tokenUser ? tokenUser : "";
    HTTPAuth.get(`${apis.get_secuencial_user}/${getSub(tk)}/04`)
      .then((response) => {
        const data = response.data;
        if (typeof data === "string" && data !== null) {
          setSecuencialComp(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire(
            "",
            "Se produjo un problema al consultar su secuencial. Contáctese con el administrador del Sistema.",
            "error"
          ).then((response) => {
            if (response.isConfirmed) {
              redirectHome();
            }
          });
        } else {
          Swal.fire(
            "",
            "Existen problemas en la red, no se pudo consultar su secuencial. Contáctese con el administrador del Sistema.",
            "error"
          ).then((response) => {
            if (response.isConfirmed) {
              redirectHome();
            }
          });
        }
      });
  }, []);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "motivo":
        if(!checkInputs.isAlphanumeric(event.target.value)){
          changeErrorLabels({motivo: "No ingrese caracteres especiales."});
        }else if(!checkInputs.countLenth(event.target.value) > 300){
          changeErrorLabels({motivo: "No ingrese más 300 caracteres."});
        }else{
          changeErrorLabels({motivo: null});
          addReason(event.target.value);
        }
        break;
      case "rise":
        if(!checkInputs.isAlphanumeric(event.target.value)){
          changeErrorLabels({rise: "No ingrese caracteres especiales."});
        }else if(checkInputs.countLenth(event.target.value) > 40){
          changeErrorLabels({rise: "No ingrese más 40 caracteres."});
        }else{
          changeErrorLabels({rise: null});
          addRise(event.target.value);
        }
        
        break;
      case "fechaEmision":
        changeErrorLabels({fechaEmision: null});
        changeDateEmision(event.target.value);
        break;
      case "fechaAutorizacion":
        changeErrorLabels({fechaDocSustento: null});
        changeDateAuth(event.target.value);
        break;
      case "numDoc":
        
        changeErrorLabels({numeroDocSustento: null});
        changeNumDoc(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classesGlobales.rootDiv}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid
          item
          xs={12}
          md={12}
          justify="center"
          className={classes.subtitle}
        >
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos Nota de Crédito"}
            avatar={<AssignmentIcon />}
          />
        </Grid>

        <Grid container item xs={12} className={classesGlobales.containerForm}>
          <Grid item xs={12} md={2}>
            Numeración:
          </Grid>
          <Grid item xs={12} md={4}>
            {secuencialComp ? (
              <input
                className="form-control is-valid"
                value={secuencialComp}
                disabled
              />
            ) : (
              <CircularProgress size="20px" color="primary" />
            )}
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <ReferenceDocument
            type="NC"
            State={creditNote}
            onChange={handleChange}
            isRecoveryInvoice={isRecoveryInvoice}
            errorsRedux={creditNote.errors}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DataCreditNote;
