import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Divider,
} from "@material-ui/core";
import {
  typeSupport,
  typeInvoice,
  tipoRegiType,
  tipoRegi01Type,
  tipoRegi02Type,
  tipoRegi03Type,
} from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import InputMask from "react-input-mask";
import useAddTax from "hooks/useAddTax";
import useAddSupportDoc from "hooks/useAddSupportDoc";
import AddTax from "./AddTax";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import tableStyle from "configurations/styles/table";
import DeleteTaxBtn from "../Buttons/DeleteTaxBtn";
import EditTaxBtn from "../Buttons/EditTaxBtn";
import EditTableRetenciones from "widgets/Tables/EditTableImpuestoRetencions";
import EditTableRetencionesDividends from "widgets/Tables/EditTableImpuestoRetencionsDividends";
import EditTableImpuestos from "widgets/Tables/EditTableImpuesto";
import EditTablePagos from "widgets/Tables/EditTablePagos";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useStylesApp } from "hooks/useStyles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

function AddExteriorSupportDoc({
  addInformationExterior,
  setAddInformationExterior,
}) {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobales = useStylesGlobal();
  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [addInformationExterior, setAddInformationExterior] = useState({
  //   tipoRegi: "",
  //   paisEfecPago: "",
  //   aplicConvDobTrib: "",
  //   pagExtSujRetNorLeg: "",
  //   pagoRegFis: "",
  // });

  const onHandleChangeInformation = (e) => {
    setAddInformationExterior({
      ...addInformationExterior,
      [e.target.name]: e.target.value,
    });
  };

  const onHandleSubmitExteriorInformation = (e) => {
    e.preventDefault();
    setOpenLoader(true);
    console.log("addInformationExterior", addInformationExterior);
    setOpenLoader(false);
    setOpen(false);
  };

  const paisEfecPagoSelect = (tipoRegi) => {
    if (tipoRegi === "01") {
      return tipoRegi01Type;
    } else if (tipoRegi === "02") {
      return tipoRegi02Type;
    } else if (tipoRegi === "03") {
      return tipoRegi03Type;
    }
  };

  return (
    <>
      <Grid container justifyContent="center" className={classes.divButton}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Añadir información exterior
        </Button>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Información exterior
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={() => {
              setOpenLoader(true);
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>

                  <Grid item xs={12} md={6} className={classes.divForm}>
                    <label>
                      Tipos de régimen fiscal del exterior:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.divInput}>
                    {
                      <Autocomplete
                        id="combo-box-demo"
                        size="medium"
                        name="tipoRegi"
                        value={tipoRegiType.CODE}
                        options={tipoRegiType}
                        onChange={(event, newValue) => {
                          setAddInformationExterior({
                            ...addInformationExterior,
                            ["tipoRegi"]: newValue
                              ? newValue.CODE
                                ? newValue.CODE
                                : ""
                              : "",
                          });
                          // setEmty({
                          //   ...empty,
                          //   ["documento"]: true,
                          // });
                          // setDocSustento(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.CODE} - ${option.DESCRIPTION}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Seleccione"
                          />
                        )}
                      />
                    }
                    {/* {empty.documento === true ? null : (
                      <p className={classesApp.errorLetters}>
                        Seleccione el tipo de documento sustento
                      </p>
                    )} */}
                  </Grid>

                  {addInformationExterior.tipoRegi !== "" ? (
                    <>
                      <Grid item xs={12} md={6} className={classes.divForm}>
                        <label>
                          País de residencia o establecimiento permanente a
                          quién se efectúa el pago:{" "}
                          <font color={palette.error.main}>*</font>
                        </label>
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.divInput}>
                        {
                          <Autocomplete
                            id="combo-box-demo"
                            size="medium"
                            name="paisEfecPago"
                            value={addInformationExterior.paisEfecPago.CODE}
                            options={
                              addInformationExterior.tipoRegi !== ""
                                ? paisEfecPagoSelect(
                                    addInformationExterior.tipoRegi
                                  )
                                : null
                            }
                            onChange={(event, newValue) => {
                              setAddInformationExterior({
                                ...addInformationExterior,
                                ["paisEfecPago"]: newValue
                                  ? newValue.CODE
                                    ? newValue.CODE
                                    : ""
                                  : "",
                              });
                              // setEmty({
                              //   ...empty,
                              //   ["documento"]: true,
                              // });
                              // setDocSustento(newValue);
                            }}
                            getOptionLabel={(option) =>
                              `${option.CODE} - ${option.DESCRIPTION}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="form-control"
                                variant="outlined"
                                placeholder="Seleccione"
                              />
                            )}
                          />
                        }
                        {/* {empty.documento === true ? null : (
                      <p className={classesApp.errorLetters}>
                        Seleccione el tipo de documento sustento
                      </p>
                    )} */}
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12} md={6} className={classes.divForm}>
                    <label>
                      Aplica Convenio de Doble Tributación en el pago?{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.divInput}>
                    <RadioGroup
                      row
                      value={addInformationExterior.aplicConvDobTrib}
                      onChange={onHandleChangeInformation}
                      name="aplicConvDobTrib"
                    >
                      <FormControlLabel
                        value="SI"
                        control={<Radio />}
                        label="SI"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </Grid>

                  {addInformationExterior.aplicConvDobTrib === "NO" ? (
                    <>
                      <Grid item xs={12} md={6} className={classes.divForm}>
                        <label>
                          Pago al exterior sujeto a retención en aplicación a la
                          norma legal? <font color={palette.error.main}>*</font>
                        </label>
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.divInput}>
                        <RadioGroup
                          row
                          value={addInformationExterior.pagExtSujRetNorLeg}
                          onChange={onHandleChangeInformation}
                          name="pagExtSujRetNorLeg"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12} md={6} className={classes.divForm}>
                    <label>
                      El pago es a un régimen fiscal preferente o de menor
                      imposición? <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.divInput}>
                    <RadioGroup
                      row
                      value={addInformationExterior.pagoRegFis}
                      onChange={onHandleChangeInformation}
                      name="pagoRegFis"
                    >
                      <FormControlLabel
                        value="SI"
                        control={<Radio />}
                        label="SI"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onHandleSubmitExteriorInformation}
                  className={classesGlobales.button}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default AddExteriorSupportDoc;
