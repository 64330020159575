import React, { useState } from "react";
import {
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Divider,
  CardHeader,
} from "@material-ui/core";
import { useStylesApp } from "hooks/useStyles";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import { typeInvoice } from "views/facturacion/Forms/typeVoucher";
import AdditionalInfoProdBtn from "./AdditionalInfoProdBtn";
import DeleteProductGuide from "./DeleteProductGuide";
import tableStyle from "configurations/styles/table";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import InputMask from "react-input-mask";

//Validations
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import TextRequiredAsterisks from "componets/TextRequiredAsterisks";
import TextRequired from "componets/TextRequired";
import AddDetailDispatcher from "../Forms/AddDetailDispatcher";
import { validations } from "../validations";
import EditDetailDispatcher from "./EditDetailDispatcher";
import Invoice from "../../invoices";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "1%",
    paddingRight: "1%",
    justify: "center",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  headAddressee: {
    textAlign: "center",
  },
}));

const EditAddresseeBtn = ({
  data,
  destinatarios,
  setDestinatarios,
  viewAddressee,
  setViewAddressee,
}) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});

  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [viewDetalle, setViewDetalle] = useState(1);
  const [detalleGuiaRemision, setDetalleGuiaRemision] = useState(
    data.detalleGuiaRemision
  );
  const [addressee, setAddressee] = useState(data);
  const columns = [
    {
      title: "Cod. interno",
      field: "codigoInterno",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Cod. adicional",
      field: "codigoAdicional",
      cellStyle: tableStyle.cellTable,
    },
    { title: "Cantidad", field: "cantidad", cellStyle: tableStyle.cellTable },
    {
      title: "Descripción",
      field: "descripcion",
      cellStyle: tableStyle.cellTable,
    },
    {
      title: "Det. adicional",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <AdditionalInfoProdBtn
          data={rowData}
          detalleGuiaRemision={detalleGuiaRemision}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
        />
      ),
    },
    {
      title: "Editar",
      field: "",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <EditDetailDispatcher
          data={rowData}
          detalleGuiaRemision={detalleGuiaRemision}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
        />
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <DeleteProductGuide
          data={rowData}
          detalleGuiaRemision={detalleGuiaRemision}
          setDetalleGuiaRemision={setDetalleGuiaRemision}
          viewDetalle={viewDetalle}
          setViewDetalle={setViewDetalle}
        />
      ),
    },
  ];

  const handleChangeDate = (name) => (event) => {
    setAddressee({
      ...addressee,
      [name]: event,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDocSustento = (codDocSustento) => {
    if (typeof codDocSustento === 'undefined' || codDocSustento === null || codDocSustento.toString() === '') {

      return {
        CODIGO: "",
        VERSION: "",
        NOMBRE: "",
      }
    } else {
      let invoice = typeInvoice.filter(invoice => invoice.CODIGO === codDocSustento);
      return invoice[0];
    }
  }
  const handleChangeAddressee = (event) => {
    event.preventDefault();
    const { error, value, keyWordError } = validations.onChange(
      event.target.name,
      event.target.value
    );

    if (event.target.name === "identificacionDestinatario") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setAddressee({
          ...addressee,
          [event.target.name]: value,
        });
      }

      setErrors({ ...errors, [event.target.name]: error });
    }
    if (event.target.name === "razonSocialDestinatario") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "dirDestinatario") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "motivoTraslado") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "numDocSustento") {
      if (error === null) {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "numAutDocSustento") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setAddressee({ ...addressee, [event.target.name]: value });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });

      if (
        !(
          addressee.numAutDocSustento.length === 9 ||
          addressee.numAutDocSustento.length === 49
        )
      ) {
        setErrors({
          ...errors,
          [event.target.name]: "Solo puede ingresar 10 o 49",
        });
      }
    }

    if (errors.codDocSustento) {
      setErrors({
        ...errors,
        ["codDocSustento"]: null,
      });
    }

    if (event.target.name === "docAduaneroUnico") {
      setAddressee({ ...addressee, [event.target.name]: value });
    }

    if (event.target.name === "ruta") {
      setAddressee({ ...addressee, [event.target.name]: value });
    }
    if (event.target.name === "codEstabDestino") {
      setAddressee({ ...addressee, [event.target.name]: value });
    }
  };

  const compareObject = (rowData, addressee) => {
    if (
      rowData.identificacionDestinatario ===
      addressee.identificacionDestinatario
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleEditAddressee = (event) => {
    event.preventDefault();

    let newErros = validations.onSubmit(addressee);
    if (!Object.keys(newErros).length > 0) {
      destinatarios.map(function edirAddressee(address, index) {
        if (compareObject(data, address)) {
          enqueueSnackbar(`Destinatario actualizado`, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          });
          destinatarios.splice(index, 1, {
            ...addressee,
            ["detalleGuiaRemision"]: detalleGuiaRemision,
          });
          if (viewAddressee === 1) {
            setViewAddressee(2);
          } else if (viewAddressee === 2) {
            setViewAddressee(1);
          }
          handleClose();
        }
      });
    } else {
      setErrors({ ...errors, ...newErros });
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
      >
        <EditIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Editar destinatario
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              setOpenLoader(true);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} className={classes.headAddressee}>
                    <TextRequiredAsterisks />
                  </Grid>
                  <Grid item md={12}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Identificación: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      placeholder="Ej. 1234567890"
                      name="identificacionDestinatario"
                      value={addressee.identificacionDestinatario}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={styleInput(
                        errors.identificacionDestinatario,
                        addressee.identificacionDestinatario
                      )}
                    />
                    {errors.identificacionDestinatario ? (
                      <p className={classesApp.errorLetters}>
                        {errors.identificacionDestinatario}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Razón social: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      placeholder="Ej. Empresa S.A."
                      name="razonSocialDestinatario"
                      value={addressee.razonSocialDestinatario}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={styleInput(
                        errors.razonSocialDestinatario,
                        addressee.razonSocialDestinatario
                      )}
                    />
                    {errors.razonSocialDestinatario ? (
                      <p className={classesApp.errorLetters}>
                        {errors.razonSocialDestinatario}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Dirección: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      placeholder="Ej. Calle 1 y Calle 2"
                      name="dirDestinatario"
                      value={addressee.dirDestinatario}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={styleInput(
                        errors.dirDestinatario,
                        addressee.dirDestinatario
                      )}
                    />
                    {errors.dirDestinatario ? (
                      <p className={classesApp.errorLetters}>
                        {errors.dirDestinatario}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Motivo traslado:
                      <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      placeholder="Ej. Venta"
                      name="motivoTraslado"
                      value={addressee.motivoTraslado}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={styleInput(
                        errors.motivoTraslado,
                        addressee.motivoTraslado
                      )}
                    />
                    {errors.motivoTraslado ? (
                      <p className={classesApp.errorLetters}>
                        {errors.motivoTraslado}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.divForm}>
                    <label>Doc. Aduanero:</label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      //placeholder="0002"
                      maxLength="13"
                      name="docAduaneroUnico"
                      value={addressee ? addressee.docAduaneroUnico : ""}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={
                        addressee
                          ? addressee.docAduaneroUnico.trim() !== ""
                            ? "form-control  is-valid"
                            : "form-control"
                          : "form-control"
                      }
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.divForm}>
                    <label>Código establecimiento destino:</label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <InputMask
                      type="text"
                      placeholder="Ej. 001"
                      mask="999"
                      name="codEstabDestino"
                      value={addressee ? addressee.codEstabDestino : ""}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={
                        addressee
                          ? addressee.codEstabDestino.trim() !== ""
                            ? "form-control  is-valid"
                            : "form-control"
                          : "form-control"
                      }
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.divForm}>
                    <label>Ruta:</label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      placeholder="Ej. Norte"
                      name="ruta"
                      value={addressee ? addressee.ruta : ""}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={
                        addressee
                          ? addressee.ruta.trim() !== ""
                            ? "form-control  is-valid"
                            : "form-control"
                          : "form-control"
                      }
                    />
                  </Grid>

                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Tipo Doc. sustento:{" "}
                    </label>
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      clearText={"Eliminar"}
                      disableClearable={false}
                      value={getDocSustento(addressee.codDocSustento)}
                      id="combo-box-demo"
                      options={typeInvoice}
                      onChange={(event, newValue) => {
                        //setDocSustento(newValue);
                        if (newValue) {
                          setAddressee({
                            ...addressee,
                            ["codDocSustento"]: newValue.CODIGO,
                          });
                        }
                      }}
                      getOptionLabel={(option) =>
                        `${option.CODIGO}-${option.NOMBRE}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          variant="outlined"
                          placeholder="Seleccione"
                          error={errors.codDocSustento ? true : false}
                        />
                      )}
                    />
                    {errors.codDocSustento ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codDocSustento}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Número Doc. sustento:{" "}
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <InputMask
                      type="text"
                      mask="999-999-999999999"
                      placeholder="Ej. 001-001-000000012"
                      name="numDocSustento"
                      value={addressee.numDocSustento}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={styleInput(
                        errors.numDocSustento,
                        addressee.numDocSustento
                      )}
                    />
                    {errors.numDocSustento ? (
                      <p className={classesApp.errorLetters}>
                        {errors.numDocSustento}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Num. Autorización Doc. sustento:{" "}
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <input
                      type="text"
                      //placeholder="0002"
                      name="numAutDocSustento"
                      value={addressee.numAutDocSustento}
                      onChange={handleChangeAddressee}
                      autoComplete="off"
                      className={styleInput(
                        errors.numAutDocSustento,
                        addressee.numAutDocSustento
                      )}
                    />
                    {errors.numAutDocSustento ? (
                      <p className={classesApp.errorLetters}>
                        {errors.numAutDocSustento}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={2} className={classes.divForm}>
                    <label>
                      Fecha emisión Doc. sustento:{" "}
                    </label>
                  </Grid>
                  <Grid item xs={4} className={classes.divInput}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <KeyboardDatePicker
                        className={"form-control "}
                        autoOk
                        variant="inline"
                        disableFuture
                        inputVariant="outlined"
                        invalidDateMessage="Formato inválido."
                        format="dd-MM-yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        name="fechaEmisionDocSustento"
                        value={addressee.fechaEmisionDocSustento}
                        onChange={handleChangeDate("fechaEmisionDocSustento")}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
              >
                <Grid item md={12}>
                  <Divider></Divider>
                </Grid>

                <AddDetailDispatcher
                  detalleGuiaRemision={detalleGuiaRemision}
                  setDetalleGuiaRemision={setDetalleGuiaRemision}
                  setViewDetalle={setViewDetalle}
                  viewDetalle={viewDetalle}
                ></AddDetailDispatcher>

                <Grid item xs={12}>
                  {detalleGuiaRemision.length > 0 ? (
                    viewDetalle === 1 ? (
                      <TableDataSimplePag
                        title=""
                        columns={columns}
                        data={detalleGuiaRemision}
                        buscar={false}
                      />
                    ) : (
                        <TableDataSimple
                          title=""
                          columns={columns}
                          data={detalleGuiaRemision}
                          buscar={false}
                        />
                      )
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              align="center"
              spacing={2}
            >
              <Grid item md={12}>
                <Divider></Divider>
              </Grid>

              <Grid item xs={2} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditAddressee}
                >
                  Actualizar destinatario
                </Button>
              </Grid>

              <Grid item xs={2} className={classes.divButton}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditAddresseeBtn;
