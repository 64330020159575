import {
  CANCEL_VOUCHER,
  CANCEL_VOUCHER_SECCESS,
  CANCEL_VOUCHER_ERROR,
  CANCEL_VOUCHER_CLEAR,
  START_DOWNLOAD_CVOUCHERS,
  DOWNLOAD_CVOUCHER_SUCCESS,
  DOWNLOAD_CVOUCHER_ERROR,
} from "../types/voucherTypes";

const initialState = {
  comprobantes: [],
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case START_DOWNLOAD_CVOUCHERS:
    case CANCEL_VOUCHER:
      return {
        ...state,
        loading: action.payload,
      };
    case CANCEL_VOUCHER_SECCESS:
      return {
        ...state,
        loading: false,
        comprobantes: [...state.comprobantes, action.payload],
      };
    case CANCEL_VOUCHER_ERROR:
    case DOWNLOAD_CVOUCHER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DOWNLOAD_CVOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        comprobantes: action.payload,
      };
    case CANCEL_VOUCHER_CLEAR:
      return initialState;
    default:
      return state;
  }
}
