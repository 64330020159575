import actions from "../types/notificationsTypes";

export const addNotificationsActions = (lstNotifications) => ({
  type: actions.ADD_NOTIFICATIONS,
  payload: lstNotifications,
});

export const cleanNotificationsActions = () => ({
  type: actions.CLEAR_NOTIFICATIONS,
});

export const deleteNotificationAction = (
  allNotifications,
  deleteNotification
) => {
  const indexNotif = allNotifications.findIndex(
    (item) => item.key === deleteNotification.key
  );
  allNotifications.splice(indexNotif, 1);

  return {
    type: actions.DELETE_NOTIFICATION,
    payload: allNotifications,
  };
};
