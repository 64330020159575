import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";
import { checkPreRequirements } from "widgets/menu/preRedirect";

import LstErrors from "./lstErros";
import { useStylesGlobal } from "configurations/styles/theme";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getSub, getRUCEmpresa } from "redux/actions/userActions";
import { selectedResourceAction } from "redux/actions/resourcesActions";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "5%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const NoPrerequisities = () => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const tokenUser = useSelector((state) => state.user.tk);
  const dispatch = useDispatch();
  const previousResourceDenied = useSelector(
    (state) => state.resources.resourceDenied
  );
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const tk = tokenUser ? tokenUser : "";
    if (tk !== "") {
      checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
        .then((newErrorsCompany) => {
          setShowLoader(false);
          if (Array.isArray(newErrorsCompany)) {
            if (newErrorsCompany.length === 0) {
              selectedResource(previousResourceDenied);
            }
          }
        })
        .catch(() => {
          setShowLoader(false);
        });
    }
  }, [tokenUser]);

  if (showLoader) {
    return (
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={11} sm={10} md={8}>
        <Card>
          <CardHeader
            title="Aún no puede generar documentos electrónicos por el/los siguiente(s)
            motivo(s):"
            className={classesGlobales.cabecera}
            avatar={<WarningIcon />}
          />
          <hr />
          <CardContent>
            <LstErrors />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NoPrerequisities;
