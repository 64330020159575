import React from "react";
import { Grid } from "@material-ui/core";
import { EnumErrorsNotifications } from "widgets/Notifications";
import ItemError from "./itemError";
import { useSelector } from "react-redux";

const checkPreRequirements = (errorsCompany) => {
  const errors = errorsCompany.filter(
    (item) =>
      item.keyword === EnumErrorsNotifications.sinCertificado ||
      item.keyword === EnumErrorsNotifications.certificadoExp ||
      item.keyword === EnumErrorsNotifications.sinNumeracion ||
      item.keyword === EnumErrorsNotifications.paqueteExp ||
      item.keyword === EnumErrorsNotifications.sinComprobantes
  );

  return errors;
};

const LstErrors = () => {
  const errorsCompany = useSelector((state) => state.resources.errorsCompany);
  const lstErrorsCompany = checkPreRequirements(errorsCompany);

  return (
    <Grid container xs={12}>
      {lstErrorsCompany.map((item) => (
        <ItemError key={item.key} error={item} />
      ))}
    </Grid>
  );
};

export default LstErrors;
