import React, { Fragment, useState } from 'react'
import { IconButton, Dialog, DialogTitle, DialogContent, Button, Grid, makeStyles } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { palette } from 'configurations/styles/theme';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}))
const ErrorMsgBtn = ({voucher}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}
            className={classes.IconButton}
            >
                <ReportProblemOutlinedIcon style={{ color: palette.error.main }}   className={classes.small} />
            </IconButton>

            <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Mensaje de Error</DialogTitle>
                <DialogContent>

                    <div className="alert alert-danger" role="alert">
                        <label>
                            
                            {voucher.comprobanteElectronico.mensajeAutorizacion
                                .concat(` ${voucher.comprobanteElectronico.informacionAdicionalProceso ?
                                    voucher.comprobanteElectronico.informacionAdicionalProceso.toLowerCase()
                                    :
                                    ''} `
                                ) }
                        </label>
                    </div>

                    <Grid container justify='center'>
                        <Button variant="outlined" color="secondary" onClick={handleClose} >
                            Cerrar
                        </Button>
                    </Grid>
                </DialogContent>

            </Dialog>

        </Fragment>
    );
}

export default ErrorMsgBtn;