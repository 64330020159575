import clientBackend from "configurations/axios";
import uuid from "react-uuid";
import { valorICE } from "../Forms/typeVoucher";
import { valorIva } from "../Forms/typeVoucher";
export const searchProduct_WebService = async (
  callbackSave,
  uri,
  params,
  setExistProduct
) => {
  if (
    typeof callbackSave === "function" &&
    typeof uri === "string" &&
    typeof params === "string"
  ) {
    await clientBackend
      .get(uri)
      .then((response) => {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              callbackSave(response.data);
              setExistProduct(false);
            } else {
              callbackSave([
                {
                  codigoPrincipal: "",
                  descripcion: `No se encontró datos con el argumento ingresado`,
                },
              ]);
              setExistProduct(true);
            }
          }
        }
      })
      .catch((exception) => {
        if (exception.response) {
          callbackSave([
            {
              codigoPrincipal: "",
              descripcion: `No se encontró datos con el argumento ingresado`,
            },
          ]);
          setExistProduct(true);
        } else {
          callbackSave([
            {
              codigoPrincipal: "",
              descripcion: `Error en la red al consultar el producto.`,
            },
          ]);
        }
      });
  }
};


export const serviceCalculationValues = (
  product,
  iva,
  ice,
  dataAditional,
  discountCheck
) => {
  let uuId = uuid();

  return {
    ...product,
    id: uuId,
    descuento: Number(product.descuentoProducto),
    cantidad: Number(product.cantidadProducto),
    isDescuentoPorcentaje: discountCheck,
    impuestoIva: valorIva.filter((aiva) => aiva.CODIGO === iva),
    impuestoIce: valorICE.filter((vice) => vice.CODIGO === ice),
    codigoPorcentajeICE: ice ? ice : "",
    codigoPorcentajeIVA: iva,
    precioProducto: Number(product.precioUnitario),
    informacionAdicional: dataAditional.length > 0 ? dataAditional : [],
  };
};

export const serviceUpdateValues = (
  product,
  iva,
  ice,
  dataAditional,
  discountCheck
) => {

  return {
    ...product,
    descuento: Number(product.descuentoProducto),
    cantidad: Number(product.cantidadProducto),
    isDescuentoPorcentaje: discountCheck,
    impuestoIva: valorIva.filter((aiva) => aiva.CODIGO === iva),
    impuestoIce: valorICE.filter((vice) => vice.CODIGO === ice),
    codigoPorcentajeICE: ice ? ice : "",
    codigoPorcentajeIVA: iva,
    precioProducto: Number(product.precioUnitario),
    informacionAdicional: dataAditional.length > 0 ? dataAditional : [],
  };
};
