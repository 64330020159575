import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import RateReviewSharpIcon from "@material-ui/icons/RateReviewSharp";
import { palette } from "configurations/styles/theme";
import { getValorICE, getValorIVA } from "../../../Forms/typeVoucher";

import { roundTwoDecimal } from "utils/parseInput";

import {
  calculateBase,
  calculateTaxICE,
  calculateTaxIVA,
} from "utils/checkTaxes";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },btnContainer: {
    marginBottom: "1%"
}
}));

const ShowTaxes = ({ product }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const builData = () => {
    let data = [];
    let totalTax = 0;
    if (product.codigoPorcentajeICE) {
      const taxICE = getValorICE(product.codigoPorcentajeICE);
      totalTax =
        Number(calculateBase(product)) + Number(calculateTaxICE(product));
      data.push({
        type: taxICE.NOMBRE,
        code: taxICE.CODIGO,
        percentage: taxICE.VALOR,
        base: roundTwoDecimal(calculateBase(product)),
        totalTax: roundTwoDecimal(calculateTaxICE(product)),
      });
    } else {
      totalTax = calculateBase(product);
    }
    const taxIVA = getValorIVA(product.codigoPorcentajeIVA);
    data.push({
      type: taxIVA.NOMBRE,
      code: taxIVA.CODIGO,
      percentage: taxIVA.VALOR,
      base: roundTwoDecimal(totalTax),
      totalTax: roundTwoDecimal(calculateTaxIVA(product)),
    });
    return data;
  };

  const taxes = builData();

  return (
    <>
      <IconButton onClick={handleClickOpen} className={classes.IconButton}>
        <RateReviewSharpIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>Impuestos del Producto</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <table className="table table-sm    table-bordered  ">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" align="center">Tipo</th>
                    <th scope="col" align="center">Código</th>
                    <th scope="col" align="center" >Porcentaje</th>
                    <th scope="col" align="center">Base Imponible</th>
                    <th scope="col" align="center">Total Impuestos</th>
                  </tr>
                </thead>
                <tbody>
                  {taxes.map((item, index) => (
                    <tr scope="row" key={index}>
                      <td>{item.type}</td>
                      <td align="center">{item.code}</td>
                      <td align="center">{item.percentage}</td>
                      <td align="right">{item.base}</td>
                      <td align="right">{item.totalTax}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
            <Grid container xs={12} justify="center" className={classes.btnContainer}>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShowTaxes;
