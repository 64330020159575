import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";

import UpdateCertificate from "views/administración/UserAccount/UpdateCertificate";
import Alert from "widgets/alert";
import { setFlagShowDialogCertificate } from "redux/sessionstorage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
      height: "320px"
  }
}));

const DialogUploadCertificate = ({ openDialog, idempresa, onClose }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [updateFileOk, setUpdateFileOk] = useState(false);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpen(false);
    setFlagShowDialogCertificate("N");
    if(typeof onClose === "function"){
      onClose();
    }
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleShowAlert = (alert) => {
    setMsg(alert);
    if (alert.type === "success") {
      setUpdateFileOk(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle  >Cargar Certificado</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <strong>
              El sistema necesita el Certificado de firma electrónica (archivo .p12) para generar los documentos
              electrónicos.
            </strong>
          </Grid>
          <Grid item xs={12}>
            {msgError.show ? (
              <Alert
                tipo={msgError.type}
                mensaje={msgError.msg}
                mesajeSubrayado={null}
                titulo={null}
                mostrar={msgError.show}
                cerrarAlerta={closeAlert}
              />
            ) : null}
            {updateFileOk ? null : (
              <UpdateCertificate
                idEmpresa={idempresa}
                onShowAlert={handleShowAlert}
              />
            )}
          </Grid>
          <Grid container xs={12} justify="center" spacing={3}>
            <Grid xs={12} md={3}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DialogUploadCertificate;
