import TypesActions from "../types/notificationsTypes";

const initialState = {
  lstNotifications: [],
  stateUser: null,
  loading: false,
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TypesActions.ADD_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case TypesActions.DELETE_NOTIFICATION:
      return {
        ...state,
        lstNotifications: action.payload,
      };
    case TypesActions.CLEAR_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
}
