import React, { useState, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Backdrop,
  CircularProgress,
  TextField,
  Divider,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useStylesApp } from "hooks/useStyles";
import Alert from "widgets/alert";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import {
  API_GET_ALL_SECUENCIAL_COMPANY,
  API_GET_ALL_USER_COMPANY,
  API_PATCH_REGISTER_USER_SECUENCIAL,
} from "../apis";
const useStyles = makeStyles((theme) => ({
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  textFieldComboBox: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}));

const AsignarSecuencialUsuario = ({ updateView }) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobal = useStylesGlobal();
  const [openLoader, setOpenLoader] = useState(false);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [open, setOpen] = useState(false);
  const [usersCompany, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ nombreUsuario: "" });
  const [secuencialCompany, setSecuencials] = useState([]);
  const [selectedSecuencial, setSelectedSecuencial] = useState({
    codigoEstablecimiento: "",
  });
  const userToken = useSelector((state) => state.user.tk);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErros] = useState({});
  const [loadSec, setLoadSec] = useState(true);
  const [loadUser, setLoadUser] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    setLoadSec(true);
    setLoadUser(true);
    setErros({});
    setSelectedSecuencial({ codigoEstablecimiento: "" });
    setSelectedUser({ nombreUsuario: "" });
  };

  const handleClose = () => {
    setOpen(false);
    setMsg({ show: false, msg: null, type: null });
    setUsers([]);
    setSecuencials([]);
  };
  function closeAlert() {
    setMsg({ show: false });
  }

  const getuUsers = async (token) => {
    setLoadUser(false);
    if (open) {
      await clientBackend
        .get(API_GET_ALL_USER_COMPANY + getRUCEmpresa(token))
        .then((result) => {
          if (result.status === 200) {
            let users = result.data;
            for (let i = 0; i < users.length; i++) {
              users[i].label =
                users[i].nombreUsuario +
                " - " +
                users[i].usuario.identificacionUsuario +
                " - " +
                users[i].correoPrincipalUsuario;
            }
            setUsers(users);
          }
        })
        .catch((exception) => {
          setUsers([{ label: "Error al cargar los usuarios" }]);
          setMsg({
            show: true,
            msg: "Ocurrió un problema al cargar los usuarios.",
          });
        });
    }
  };

  const renderUsers = () => {
    if (usersCompany.length === 0 && userToken && loadUser) {
      getuUsers(userToken);
    }
    return (
      <Autocomplete
        value={selectedUser}
        onChange={(event, newValue) => {
          setSelectedUser(newValue);
        }}
        options={usersCompany}
        getOptionLabel={(option) => option.label}
        style={{ width: "95%" }}
        loading={usersCompany.length === 0}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Lista de usuarios"
            classes={{ root: classes.textFieldComboBox }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {usersCompany.length === 0 ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  };

  const getSecuencials = async (token) => {
    if (open) {
      setLoadSec(false);
      await clientBackend
        .get(API_GET_ALL_SECUENCIAL_COMPANY + getRUCEmpresa(token))
        .then((result) => {
          if (result.status === 200) {
            let secuencialesApi = result.data;
            for (let i = 0; i < secuencialesApi.length; i++) {
              secuencialesApi[i].label =
                secuencialesApi[i].codigoEstablecimiento +
                " - " +
                secuencialesApi[i].puntoEmision;
            }
            setSecuencials(secuencialesApi);
          }
        })
        .catch((exception) => {
          setMsg({
            show: true,
            msg: "Ocurrió un problema al cargar los usuarios.",
          });
        });
    }
  };

  const renderSecuencial = () => {
    if (secuencialCompany.length === 0 && userToken && loadSec) {
      getSecuencials(userToken);
    }
    return (
      <Autocomplete
        value={selectedSecuencial}
        onChange={(event, newValue) => {
          setSelectedSecuencial(newValue);
        }}
        loadingText={"No dispone de secuenciales"}
        options={secuencialCompany}
        getOptionLabel={(option) => option.label}
        style={{ width: "95%" }}
        loading={secuencialCompany.length === 0}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Lista de puntos de emisión"
            classes={{ root: classes.textFieldComboBox }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {usersCompany.length === 0 ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  };

  const handleAddSecuencial = async (event) => {
    let validations = {};
    setOpenLoader(true);
    event.preventDefault();
    if (selectedUser) {
      if (
        selectedUser.idAsignacionEmpresaUsuario === null ||
        typeof selectedUser.idAsignacionEmpresaUsuario === "undefined"
      ) {
        validations.selectedUser = "Seleccione un usuario.";
      }
    } else {
      validations.selectedUser = "Seleccione un usuario.";
    }
    if (selectedSecuencial) {
      if (
        selectedSecuencial.idSecuencial === null ||
        typeof selectedSecuencial.idSecuencial === "undefined"
      ) {
        validations.selectedSecuencial = "Seleccione un punto de emisión.";
      }
    } else {
      validations.selectedSecuencial = "Seleccione un punto de emisión.";
    }
    if (Object.keys(validations).length === 0) {
      await clientBackend
        .get(
          API_PATCH_REGISTER_USER_SECUENCIAL +
            selectedUser.idAsignacionEmpresaUsuario +
            "/" +
            selectedSecuencial.idSecuencial
        )
        .then((response) => {
          enqueueSnackbar("Punto de emisión asignado.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          handleClose();
          setOpenLoader(false);
        })
        .catch((exception) => {
          if (exception.response) {
            if (exception.response.status === 400) {
              setMsg({
                show: true,
                msg: "No se pudo asignar el punto de emisión al usuario.",
              });
            } else if (exception.response.status === 404) {
              setMsg({
                show: true,
                msg: "No se encontró el usuario o el punto de emisión para asignar.",
              });
            } else if (exception.response.status === 409) {
              setMsg({
                show: true,
                msg:
                  "El usuario seleccionado, ya se encuentra asignado a otro punto de emisión.",
              });
            } else {
              setMsg({
                show: true,
                msg:
                  "Error al asignar el punto de emisión al usuario. Contáctese con el administrador del Sistema.",
              });
            }
          } else {
            setMsg({
              show: true,
              msg:
                "Existen problemas en la red. Contáctese con el administrador del Sistema.",
            });
          }
          setOpenLoader(false);
        });
    } else {
      setErros(validations);
      setOpenLoader(false);
    }
  };

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddCircleIcon
          style={{ color: palette.primary.contrastText, paddingRight: '3%' }}
          fontSize="small"
        />{"  "}
        Asignar Punto de Emisión
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Asignar Punto de Emisión{" "}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddSecuencial}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              {msgError.show ? (
                <Alert
                  tipo={msgError.type}
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
              <Grid item xs={12} md={2}>
                Usuario: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={10}>
                {renderUsers()}
                {errors.selectedUser ? (
                  <p className={classesApp.errorLetters}>
                    {errors.selectedUser}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2}>
                Punto de Emisión: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={10}>
                {renderSecuencial()}
                {errors.selectedSecuencial ? (
                  <p className={classesApp.errorLetters}>
                    {errors.selectedSecuencial}
                  </p>
                ) : null}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Asignar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AsignarSecuencialUsuario;
