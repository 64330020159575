import React, { useState } from "react";
import {
  makeStyles,
  CardHeader,
  Grid,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { valorICE, valorIva } from "../../../Forms/typeVoucher";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import {
  AddCodeTaxICEAction,
  AddCodeTaxIVAAction,
} from "redux/actions/debitNoteActions";
import { useDispatch, useSelector } from "react-redux";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TextError from "componets/TextError";

const useStyles = makeStyles((theme) => ({
  comboBox: {
    width: "100%",
  },
  subtitle: {
    color: palette.primary.contrastText,
    background: palette.primary.light,
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "1%",
    marginBottom: "6%",
  },
  containerDebitNote: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    flexGrow: 1,
    marginBottom: "1%",
    padding: "0% 0% 0% 0%",
  },
  formControl: {
    minWidth: "95%",
    maxWidth: "100%",
  },
}));

const FormTaxes = () => {
  const dispatch = useDispatch();
  const addCodeTaxIva = (code) => dispatch(AddCodeTaxIVAAction(code));
  const addCodeTaxIce = (code) => dispatch(AddCodeTaxICEAction(code));
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [codeIVA, setCodeIVA] = useState(null);
  const [codeICE, setCodeICE] = useState(null);
  const debitNote = useSelector((state) => state.debitNote);
  const handleChangeIva = (event) => {
    addCodeTaxIva(event.target.value);
    setCodeIVA(event.target.value);
  };

  const handleChangeICE = (event) => {
    addCodeTaxIce(event.target.value);
    setCodeICE(event.target.value);
  };

  return (
    <Grid container className={classes.containerDebitNote}>
      <Grid container xs={12} justify="center">
        <CardHeader
          className={classesGlobales.cabecera}
          title={"Impuestos Aplicables"}
          avatar={<AssignmentIcon />}
        />
      </Grid>

      <Grid
        container
        xs={12}
        className={classesGlobales.containerForm}
        spacing={1}
      >
        <Grid item xs={12} md={2}>
          <label> Impuesto al Valor Agregado (IVA): </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <Select value={codeIVA} onChange={handleChangeIva} fullWidth>
              {valorIva.map((item) => (
                <MenuItem value={item.CODIGO} key={item.CODIGO}>
                  {" "}
                  {item.NOMBRE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextError text={debitNote.errors.codigoImpuestoIva} />
        </Grid>
        <Grid item xs={12} md={2}>
          <label>Impuesto a los Consumos Especiales (ICE): </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <Select value={codeICE} onChange={handleChangeICE} fullWidth>
              {valorICE.map((item) => (
                <MenuItem value={item.CODIGO} key={item.CODIGO}>
                  {" "}
                  {item.NOMBRE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextError text={debitNote.errors.codigoImpuestoIva} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormTaxes;
