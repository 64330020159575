import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { debounce } from 'throttle-debounce';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

export default function SearchUsers({ backupParam = '', sendData }) {
    const classes = useStyles();
    const [searchParam, setParam] = useState(backupParam);
    const [showLoader, setShowLoader] = useState(false);

    const requestData = useCallback(debounce(500,  param => {
        sendData(param);
        setShowLoader(false);
    }),[])

    const handleChange = event => {
        setParam(event.target.value);
        let param = event.target.value;
        setShowLoader(true);
        requestData(param);
    }

    return (
        <div className={classes.root}>
            <from style={{ width: '100%' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={4}>
                        <strong>Buscar por: </strong> <label style={{ marginBottom: '0px' }} > RUC/Cédula/Nombres</label>
                        <br /> <small>Ingrese por lo menos 3 caracteres.</small>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <input type="text" className="form-control"
                            id="searchInput"
                            value={searchParam}
                            onChange={handleChange}
                            autoFocus
                            autoComplete="off"
                        />
                        {showLoader? <LinearProgress color="primary" style={{ marginTop: '1%' }} /> : null}
                    </Grid>
                </Grid>
            </from>
        </div>
    );
}