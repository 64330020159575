import checkInputs from "utils/checkInputs";


const  validations = {
  onChange: function checkOnChange(nameInput, value) {
    const response = { value: value, error: null, keyWordError: null };

    switch (nameInput) {
      case "claveDeAcceso":
        if (!checkInputs.isNumeric(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "Solo ingrese caracteres numéricos.";
        } else if (value.length > 49 ) {
          response.error = "No ingrese más de 49 caracteres.";
          response.keyWordError = "isLong";
        }
        return response;
      default:
        return response;
    }
  },
};

export default validations