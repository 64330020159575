import React, { useState } from "react";
import {
  Grid,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import TextError from "componets/TextError";
import validations from "../validations";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  form: {
    width: "100%",
  },
}));

const initData = {
  razon: "",
  valor: "",
};

const initErrors = {
  razon: null,
  valor: null,
};

const AddReason = ({ onSubmit }) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [errors, setErrors] = useState(initErrors);
  const [data, setData] = useState(initData);
  const [openDialog, setOpenDialog] = useState(false);
  const codeIva = useSelector((state) => state.debitNote.codigoImpuestoIva);
  const codeIce = useSelector((state) => state.debitNote.codigoImpuestoIce);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    if (codeIva !== null || codeIce !== null) {
      setOpenDialog(true);
      setData(initData);
      setErrors(initErrors);
    } else {
      Swal.fire(
        "",
        `Antes de agregar un motivo, debe seleccionar al menos un impuesto, IVA y/o ICE.`,
        "info"
      );
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    const newError = validations.newReason.onChange(
      event.target.name,
      event.target.value
    );
    setErrors({
      ...errors,
      [event.target.name]: newError,
    });
    if (newError === null) {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validations.newReason.onSubmit(data);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (typeof onSubmit === "function") {
        onSubmit({ ...data, key: uuid() });
        handleCloseDialog();
        enqueueSnackbar(`Motivo agregado al comprobante`, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        });
      }
    }
  };

  return (
    <Grid
      container
      xs={12}
      className={classesGlobales.containerForm}
      spacing={1}
      justify="center"
    >
      <Grid item xs={12} md={2}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleOpenDialog}
        >
          Agregar Motivo
        </Button>
      </Grid>
      <Dialog open={openDialog} fullWidth maxWidth="sm">
        <DialogTitle>Nuevo Motivo</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={3}>
                <label>
                  Motivo: <font color={palette.error.main}> *</font>{" "}
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <input
                  type="text"
                  className={styleInput(errors.razon, data.razon)}
                  autoComplete="off"
                  name="razon"
                  value={data.razon}
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <TextError text={errors.razon} />
              </Grid>
              <Grid item xs={12} md={3}>
                <label>
                  {" "}
                  Valor: <font color={palette.error.main}> *</font>{" "}
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <input
                  className={styleInput(errors.valor, data.valor)}
                  autoComplete="off"
                  name="valor"
                  value={data.valor}
                  onChange={handleChange}
                  autoComplete="off"
                  onBlur={handleChange}
                />
                <TextError text={errors.valor} />
              </Grid>
              <br />
              <Grid container xs={12} justify="center" spacing={1}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseDialog}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default AddReason;
