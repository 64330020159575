import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UseSecurity from "security/useSecurity";
import { useDispatch } from "react-redux";
import { signOffUserAction } from "redux/actions/userActions";

import { makeStyles, CircularProgress, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSingIn } = UseSecurity();
  const clearProcess = () => dispatch(signOffUserAction());

  useEffect(() => {
    if (isSingIn()) {
      history.push("/facturacion");
    } else {
      history.push("/");
      clearProcess();
    }
  }, []);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default NotFound;
