import checkInputs from "utils/checkInputs";
import { validarNumDocumentoSRI } from "utils/checkDocument";

const validations = {
  onSubmit: function validationSubmit(creditNote) {
    const errors = {};
    if (!checkInputs.notNull(creditNote.idAsignacionEmpresaCliente)) {
      errors.idAsignacionEmpresaCliente = "Ingrese los datos del cliente.";
    }

    if (!checkInputs.notNull(creditNote.numeroDocSustento)) {
      errors.numeroDocSustento = "Ingrese el número del Doc. Sustento.";
    } else if (!validarNumDocumentoSRI(creditNote.numeroDocSustento)) {
      errors.numeroDocSustento =
        "Ingrese el número completo. Ejemplo 001-001-000000001";
    }

    if (!checkInputs.notNull(creditNote.fechaDocSustento)) {
      errors.fechaDocSustento =
        "Ingrese la fecha de Emisión del Doc. Sustento.";
    }

    if (!checkInputs.notNull(creditNote.fechaEmision)) {
      errors.fechaEmision = "Ingrese la Fecha de Emisión.";
    }

    if (
      checkInputs.notNull(creditNote.fechaDocSustento) &&
      checkInputs.notNull(creditNote.fechaEmision)
    ) {
      try {
        const fechaDocSustento = new Date(creditNote.fechaDocSustento);
        const fechaEmision = new Date(creditNote.fechaEmision);
        if (fechaDocSustento.getFullYear() === fechaEmision.getFullYear()) {
          if (fechaDocSustento.getMonth() <= fechaEmision.getMonth()) {
            if(fechaDocSustento.getMonth() === fechaEmision.getMonth()){
              if (fechaDocSustento.getDate() > fechaEmision.getDate()) {
                errors.fechaDocSustento =
                  "La fecha de Emisión de la Nota de Crédito no puede ser antes de la fecha del Documento de Sustento.";
              }
            }
          } else {
            errors.fechaDocSustento =
              "La fecha de Emisión de la Nota de Crédito no puede ser antes de la fecha del Documento de Sustento.";
          }
        } else if(fechaDocSustento.getFullYear() > fechaEmision.getFullYear()){
          //new validation
          errors.fechaDocSustento =
            "La fecha de Emisión de la Nota de Crédito no puede ser antes de la fecha del Documento de Sustento.";
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (!checkInputs.notNull(creditNote.motivo)) {
      errors.motivo = "Ingrese el motivo.";
    } else if (!checkInputs.isAlphanumeric(creditNote.motivo)) {
      errors.motivo = "No ingrese caracteres especiales, en el motivo.";
    } else if (!checkInputs.countLenth(creditNote.motivo) > 300) {
      errors.motivo = "No ingrese más de 300 caracteres, en el motivo.";
    }

    if (checkInputs.notNull(creditNote.rise)) {
      if (!checkInputs.isAlfanumericStrict(creditNote.rise)) {
        errors.rise = "El rise solo puede tener letras o números";
      } else if (!checkInputs.countLenth(creditNote.motivo) > 40) {
        errors.rise = "El rise puede tener máximo 40 caracteres.";
      }
    }

    if (Array.isArray(creditNote.detalles)) {
      if (creditNote.detalles.length === 0) {
        errors.detalles = "Ingrese al menos un detalle.";
      }
    } else {
      errors.detalles = "Ingrese al menos un detalle.";
    }

    return errors;
  },
};

export default validations;
