import List from "./list";
import Dialog from "./dialog";
import Btn from "./button";
import IconBtn from "./iconbutton";
import Analitics from "./analitics";
import Enum from "./EnumErrorsNotifications"

export const BtnDialog = Btn;
export const IconbuttonDialog = IconBtn;
export const DialogNotification = Dialog;
export const ListNotifications = List;
export const AnaliticNotifications = Analitics;
export const EnumErrorsNotifications = Enum;

