import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";
import { palette } from "../../../../configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  containderInfoUser: {
    fontSize: "15px",
    padding: "2%",
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    marginTop: "0.5%",
    color: "#919191",
  },
  containderInfoSecuencial: {
    // borderStyle: 'solid',
    //backgroundColor: palette.info.light,
    //borderRadius: '3px',
    fontSize: "15px",
    padding: "2%",
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    // borderBottomWidth: '1px',
    marginTop: "0.5%",
    marginRight: "2%",
    color: "#919191",
  },
  buttonClose: {
    margin: "2%",
  },
  infoTitle: {
    color: "#001038",
    fontWeight: 400,
  },
}));
const InfoSecuencialBtn = ({ secuencial }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <InfoIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">
          Detalle del Punto de Emisión
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={2} className={classes.infoTitle}>
              Establecimiento:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoUser}
              align="center"
            >
              {secuencial.codigoEstablecimiento}
            </Grid>
            <Grid item md={2} className={classes.infoTitle}>
              Punto de Emisión:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoUser}
              align="center"
            >
              {secuencial.puntoEmision}
            </Grid>
            <Grid item md={1} align="right" className={classes.infoTitle}>
              Estado:
            </Grid>
            <Grid
              item
              md={3}
              className={classes.containderInfoUser}
              align="center"
            >
              {secuencial.estado ? "Abierto" : "Cerrado"}
            </Grid>
            <Grid item md={2} className={classes.infoTitle}>
              Dirección:
            </Grid>
            <Grid item md={10} className={classes.containderInfoUser}>
              {secuencial.direccion}
            </Grid>
            <Grid item md={12} align="center">
              <Divider></Divider>
            </Grid>
            <Grid item md={12} align="center" className={classes.infoTitle}>
              Secuenciales
            </Grid>
            <Grid item md={12} align="center">
              <Divider></Divider>
            </Grid>
            <Grid item md={3} className={classes.infoTitle}>
              Factura:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoSecuencial}
              align="center"
            >
              {secuencial.secuencialFactura}
            </Grid>
            <Grid item md={3} className={classes.infoTitle}>
              Nota de crédito:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoSecuencial}
              align="center"
            >
              {secuencial.secuencialNotaCredito}
            </Grid>
            <Grid item md={3} className={classes.infoTitle}>
              Nota de débito:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoSecuencial}
              align="center"
            >
              {secuencial.secuencialNotaDebito}
            </Grid>
            <Grid item md={3} className={classes.infoTitle}>
              Guía de remisión:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoSecuencial}
              align="center"
            >
              {secuencial.secuencialGuiaRemision}
            </Grid>
            <Grid item md={3} className={classes.infoTitle}>
              Comprobante de retención:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoSecuencial}
              align="center"
            >
              {secuencial.secuencialRetencion}
            </Grid>
            <Grid item md={3} className={classes.infoTitle}>
              Liquidación de Compra:
            </Grid>
            <Grid
              item
              md={2}
              className={classes.containderInfoSecuencial}
              align="center"
            >
              {secuencial.secuencialLiquidacionCompra}
            </Grid>
            <Grid item md={12} align="center">
              <Divider></Divider>
            </Grid>
            <Grid item md={12} align="center" style={{ padding: "0px" }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={classes.buttonClose}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default InfoSecuencialBtn;
