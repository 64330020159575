import actions from "../types/creditNoteType";

export const changeErrorLabelsActionGuide = (error) => ({
    type: actions.CHANGE_ERRORS_LABELS_GUIDE,
    payload: error
})


export const addRUCAction = (ruc) => {
    return ({
        type: actions.ADD_RUC,
        payload: ruc
    })
}

export const addDirAction = (dir) => {
    return ({
        type: actions.ADD_DIR,
        payload: dir
    })
}

export const addReasonAction = (reason) => {
    return ({
        type: actions.ADD_REASON,
        payload: reason
    })
}

export const addPlacaAction = (placa) => {
    return ({
        type: actions.ADD_PLACA,
        payload: placa
    })
}

export const clearReferralGuideAction = () => {
    return ({
        type: actions.CLEAR_ALL,
    })
}
