import React from "react";
import MaterialTable from "material-table";
import tableStyle from "configurations/styles/table";
import { palette } from "configurations/styles/theme";
import { useSnackbar } from "notistack";
import checkInputs from "utils/checkInputs";
import { TextField, makeStyles } from "@material-ui/core";
import { tableIcons } from "./OverrideIcons";

const useStyles = makeStyles({
  editComponent: {
    paddingRight: "5px",
  },
});

export default function EditTable({
  dataAditional,
  setDataAditional,
  maxRows = 3,
}) {
  const letterColor = palette.primary.main;
  const classes = useStyles();
  const handleSubmit = (data) => {
    let errors = [];

    if (!checkInputs.notNull(data.nombre)) {
      errors.push("Ingrese el nombre.");
    } else if (!checkInputs.isAlphanumeric(data.nombre)) {
      errors.push("No ingrese caracteres especiales, en nombre.");
    } else if (checkInputs.countLenth(data.nombre) > 300) {
      errors.push("No ingrese más de 300 caracteres, en nombre.");
    } else if (
      typeof data.nombre === "string" &&
      checkInputs.notNull(data.valor)
    ) {
      if (data.nombre === "CORREO") {
        if (!checkInputs.validationEmail(data.valor)) {
          errors.push(
            "El correo ingresado debe tener el formato: user@ejemplo.com"
          );
        }
      }
    }

    if (!checkInputs.notNull(data.valor)) {
      errors.push("Ingrese el valor.");
    } else if (checkInputs.countLenth(data.valor) > 300) {
      errors.push("No ingrese más de 300 caracteres, en valor.");
    }

    errors.forEach((item) => {
      enqueueSnackbar(item, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 10000,
      });
    });
    return errors.length === 0;
  };

  const columns = [
    {
      title: "Nombre",
      field: "nombre",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          type="text"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          variant="standard"
          placeholder="Nombre"
          className={classes.editComponent}
        />
      ),
    },
    {
      title: "Valor",
      field: "valor",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          type="text"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          variant="standard"
          placeholder="Valor"
          className={classes.editComponent}
        />
      ),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  return (
    <MaterialTable
      title="Información Adicional"
      columns={columns}
      data={dataAditional.length > 0 ? dataAditional : []}
      localization={{
        header: {
          actions: "",
        },
        body: {
          editRow: {
            deleteText: "¿Está seguro de eliminar la fila ?",
            saveTooltip: "Guardar cambios.",
            cancelTooltip: "Cancelar cambios.",
          },
          addTooltip: "Agregar",
          editTooltip: "Editar fila",
          deleteTooltip: "Eliminar fila",
          emptyDataSourceMessage: "No existen datos",
        },
        pagination: {
          labelRowsSelect: "Filas por página",
          labelDisplayedRows: "{from}-{to} de {count}",
        },
      }}
      options={{
        search: false,
        sorting: false,
        headerStyle: {
          backgroundColor: letterColor,
          color: "#FFFFFF",
          borderStyle: "solid",
          borderColor: letterColor,
          borderWidth: "1px",
          alignItems: "center",
          textAlign: "center",
          padding: "0px",
        },
        rowStyle: {
          borderStyle: "solid",
          borderColor: letterColor,
          borderWidth: "1px 1px ",
          alignContent: "center",
          textAlign: "center",
          paddingRight: "1px",
        },
      }}
      editable={{
        isEditable: (rowData) =>
          typeof rowData.isEditable === "boolean" ? rowData.isEditable : true,
        isDeletable: (rowData) =>
          typeof rowData.isDeletable === "boolean" ? rowData.isDeletable : true,
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (dataAditional.length < maxRows) {
                if (typeof newData === "object") {
                  const isValid = handleSubmit(newData);
                  if (isValid) {
                    setDataAditional([...dataAditional, newData]);
                    resolve();
                  } else {
                    reject();
                  }
                }
              } else {
                enqueueSnackbar(`Únicamente puede agregar ${maxRows} filas`, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 4000,
                });
                resolve();
              }
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...dataAditional];
              const index = oldData.tableData.id;
              const isValid = handleSubmit(newData);
              if (isValid) {
                dataUpdate[index] = newData;
                setDataAditional([...dataUpdate]);
                resolve();
              } else {
                reject();
              }
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...dataAditional];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setDataAditional([...dataDelete]);
              resolve();
            }, 1000);
          }),
      }}
      icons={tableIcons}
    />
  );
}
