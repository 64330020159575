import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { palette } from 'configurations/styles/theme';

//pdf
import PDFInformacion from "assets/files/info-basica-comprobantes-electronicos.pdf"
import PDFPreguntasFrecuentes from "assets/files/preguntas-frecuentes.pdf"
const useStyles = makeStyles({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%'
    },
    paperHeader: {
        borderColor: palette.primary.main,
        borderStyle: 'solid',
        paddingBottom: '1%',
        borderWidth: "2px",
        borderRadius: "6px"
    },
    tab: {
        minWidth: "fit-content"
    },
});

const Information = () => {
    const classesView = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section className={classesView.root}>
            <Paper className={classesView.paperHeader}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Información general sobre Comprobantes Electrónicos"
                        className={classesView.tab}
                    />
                    <Tab label="Preguntas frecuentes" />
                </Tabs>
            </Paper>
            {value === 0 ?
                <iframe src={PDFInformacion} width="100%" height="650px" />
                :
                <iframe src={PDFPreguntasFrecuentes}  width="100%" height="650pxx" />
            }
        </section>
    );
}

export default Information;