import React, { Fragment, useState } from 'react';
import { Grid, FormControlLabel, makeStyles, Paper, Button, Checkbox, CardHeader } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import tableStyle from 'configurations/styles/table';
import DeleteAdditionalInfoInvBtn from '../Buttons/DeleteAdditionalInfoInvBtn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import uuid from 'react-uuid';
import { useStylesApp } from 'hooks/useStyles';
import { useSnackbar } from 'notistack';
import EditTable from 'widgets/Tables/EditTable';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        boxShadow: "5px 3px 3px #aaaaaa",
        borderRadius: '8px',
        borderWidth: '2px'
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,

    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
        textAlign: 'justify',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%',
        textAlign: 'justify',

    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    haedClient: {
        backgroundColor: palette.primary.main,
        width: '10%',
        marginBottom: '2%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '8px',
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 'auto',
    },
    divLabel: {
        marginBottom: '0%',
        textAlign: 'justify',
    },
}));

const AdditionalInfo = ({ additionalInfoInvoice, setAdditionalInfoInvoice }) => {
    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobales = useStylesGlobal();
    const [isAditional, setIsAdditional] = useState(false);
    const columns = [
        { title: 'Nombre', field: `nombre`, cellStyle: tableStyle.cellTable, width: '10%' },
        { title: 'Valor', field: 'valor', cellStyle: tableStyle.cellLeft, width: '30%' },
        {
            title: 'Eliminar', cellStyle: tableStyle.cellTable, width: '10%', render: rowData => <DeleteAdditionalInfoInvBtn data={rowData} additionalInfoInvoice={additionalInfoInvoice} setAdditionalInfoInvoice={setAdditionalInfoInvoice} viewAdditionalInfo={viewAdditionalInfo}
                setViewAdditionalInfo={setViewAdditionalInfo} />
        },
    ];
    //state para actualizar la vista
    const [update, setUpdate] = useState(1);
    //para agregar información a la Factura
    const [additionalInfo, setAdditionalInfo] = useState({
        id: uuid(),
        nombre: '',
        valor: ''
    });
    const [errorInfo, setErrorInfo] = useState({
        nombre: true,
        valor: true,
    })
    const [viewAdditionalInfo, setViewAdditionalInfo] = useState(1);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangeCheck = (event) => {
        setIsAdditional(event.target.checked);
        if (event.target.checked) {
            setAdditionalInfoInvoice([]);
            setAdditionalInfo({
                id: uuid(),
                nombre: '',
                valor: ''
            });
        }
    }

    const addInfoAdicional = () => {
        if (validateAdditionalInfo()) {
            if (additionalInfoInvoice.length <= 13) {
                setAdditionalInfoInvoice([...additionalInfoInvoice, additionalInfo])
                setAdditionalInfo({
                    id: uuid(),
                    nombre: '',
                    valor: ''
                });
                setErrorInfo({
                    nombre: true,
                    valor: true,
                })


                if (viewAdditionalInfo === 1) {
                    setViewAdditionalInfo(2);
                } else {
                    setViewAdditionalInfo(1);
                }
            } else {
                enqueueSnackbar(`Unicamente puede ingresar 14 filas`, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
            }
        }
    }

    const validateAdditionalInfo = () => {
        if (additionalInfo.nombre.trim() === "") {
            setErrorInfo({
                ...errorInfo,
                ["nombre"]: false,
            });
            return false;
        } else if (additionalInfo.valor.trim() === "") {
            setErrorInfo({
                ...errorInfo,
                ["valor"]: false,
            });
            return false;
        } else {
            return true;
        }
    };



    return (
        <Fragment  >
            <div className={classesGlobales.rootDiv} >
                <Grid container xs={12} md={12}
                    justify='center'        >
                    <CardHeader
                        className={classesGlobales.cabecera}
                        title={'Información Adicional'}
                        avatar={<AssignmentIcon></AssignmentIcon>}
                    />
                </Grid>
                <Grid container spacing={1} justify='center' className={classes.formClient} >
                    <Grid item xs={12} md={8}  >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAditional}
                                    onChange={handleChangeCheck}
                                    name="isAditional"
                                    color="primary"
                                />
                            }
                            label="Pulse para agregar información adicional al comprobante"
                        />
                    </Grid>
                </Grid>


                {isAditional ?
                    <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12} className={classes.divLabel}>
                                <label className={classes.divLabel}><font color={palette.error.main}>*</font> Solo se pueden agregar 14 campos de Información Adicional por comprobante.</label>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divForm}>
                                <label className={classes.divLabel}><font color={palette.error.main}>*</font> Para ingresar un e-mail se debe poner CORREO en nombre y el correo en el valor.</label>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divForm}>
                                <label className={classes.divLabel}><font color={palette.error.main}>*</font> Por ejemplo: Nombre Campo: CORREO. Valor Campo: ejemplo@ebox.ec.</label>
                            </Grid>

                        </Grid>
                        <Grid container spacing={1}>
                            {/*<Grid item xs={12} md={3} className={classes.divForm}>
                                <label>Nombre Campo: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={9} className={classes.divInput}>
                                <input className={additionalInfo ? additionalInfo.nombre ? 'form-control is-valid' : 'form-control' : 'form-control'}
                                    type="text"
                                    placeholder="Ej. CORREO"
                                    autoComplete='off'
                                    name="nombre"
                                    value={additionalInfo.nombre}
                                    onChange={event => {
                                        setErrorInfo({
                                            ...errorInfo,
                                            [event.target.name]: true,
                                        })
                                        setAdditionalInfo({
                                            ...additionalInfo,
                                            [event.target.name]: event.target.value
                                        })
                                    }
                                    }

                                    textAlign='left'

                                />
                                {errorInfo.nombre === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Debe ingresar el nombre del campo</p>
                                }
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.divForm}>
                                <label>Valor Campo: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={9} className={classes.divInput}>
                                <input className={additionalInfo ? additionalInfo.valor ? 'form-control is-valid' : 'form-control' : 'form-control'}
                                    type="text"
                                    placeholder="Ej. ejemplo@email.com"
                                    autoComplete='off'
                                    name="valor"
                                    value={additionalInfo.valor}
                                    onChange={event => {
                                        setErrorInfo({
                                            ...errorInfo,
                                            [event.target.name]: true,
                                        })
                                        setAdditionalInfo({
                                            ...additionalInfo,
                                            [event.target.name]: event.target.value
                                        })
                                    }}
                                    textAlign='left'

                                />
                                {errorInfo.valor === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Debe ingresar el valor del campo</p>
                                }
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divButton}>
                                <Button fullWidth variant="outlined" color="primary" onClick={addInfoAdicional} >
                                    Agregar
                                </Button>
                            </Grid>*/}
                            <Grid item xs={12} md={12} className={classes.divForm}>
                                {<EditTable
                                    dataAditional={additionalInfoInvoice}
                                    setDataAditional={setAdditionalInfoInvoice}
                                    maxRows={14}
                                />}
                                {/*additionalInfoInvoice ?
                                    viewAdditionalInfo === 1 ?
                                        <TableDataSimplePag
                                            columns={columns}
                                            data={additionalInfoInvoice}
                                            title=""
                                            buscar={false} />
                                        :
                                        <TableDataSimple
                                            columns={columns}
                                            data={additionalInfoInvoice}
                                            title=""
                                            buscar={false} />
                                    :
                                    null

                                */}
                            </Grid>


                        </Grid>

                    </Paper>

                    :

                    null
                }


            </div>

        </Fragment >
    );
}

export default AdditionalInfo;