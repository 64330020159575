import instanceBackend from "configurations/axios";

import apis from "./apis";



export async function searchCostumer( param,  ruc = "0") {
    return await instanceBackend.get(apis.GET_SEARCH_COSTUMER + ruc + "?parametroBusqueda=" + param );
}

const services = {
    searchCostumer: searchCostumer
}

export default services;