import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { palette } from "configurations/styles/theme";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  divInfoUser: {
    borderStyle: "solid",
    borderColor: palette.primary.main,
    borderWidth: "1px",
    borderRadius: "8px",
    marginTop: "1%",
  },
  divlabel: {
    marginTop: "1%",
  },
  buttonClose: {
    width: "75%",
  },
}));

const DeleteProductGuide = ({
  data,
  detalleGuiaRemision,
  setDetalleGuiaRemision,
  viewDetalle,
  setViewDetalle,
}) => {
  const classes = useStyles();

  const deleteProduct = () => {
    ConfirmAlert(
      `¿Está seguro de eliminar este producto?`,
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        setDetalleGuiaRemision(
          detalleGuiaRemision.filter(
            (product) => product.id !== data.id
          )
        );
        //setDetalles(detalles.filter(detalle => detalle.idProducto !== data.idProducto))
        if (viewDetalle === 1) {
          setViewDetalle(2);
        } else {
          setViewDetalle(1);
        }
        
      },
      () => {
        if (viewDetalle === 1) {
          setViewDetalle(2);
        } else {
          setViewDetalle(1);
        }
      }
    );
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        className={classes.IconButton}
        onClick={deleteProduct}
      >
        <DeleteForeverIcon style={{ color: palette.error.main }} />
      </IconButton>
    </>
  );
};

export default DeleteProductGuide;
