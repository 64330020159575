import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { palette } from "configurations/styles/theme";
import EditTable from "widgets/Tables/EditTable";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  divInfoUser: {
    borderStyle: "solid",
    borderColor: palette.primary.main,
    borderWidth: "1px",
    borderRadius: "8px",
    marginTop: "1%",
  },
  divlabel: {
    marginTop: "1%",
  },
  buttonClose: {
    width: "75%",
  },
  table: {
    flexGrow: 1,
    borderWidth: "2px",
    borderRadius: "8px",
    borderCollapse: "collapse",
    border: "1px solid black",
    textAlign: "center",
    padding: "0px",
  },
}));

const AdditionalInfoProdBtn = ({
  data,
  detalleGuiaRemision,
  viewDetalle,
  setViewDetalle,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [dataAditional, setDataAditional] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setDataAditional(data.detallesAdicionales);
    setOpen(true);
  };
  const handleAddAditional = () => {
    detalleGuiaRemision.map(function addAditional(prod, index, array) {
      if (compareObject(data, prod)) {
        detalleGuiaRemision.splice(index, 1, {
          ...data,
          ["detallesAdicionales"]: dataAditional,
        });
        setDataAditional([]);
        if (viewDetalle === 1) {
          setViewDetalle(2);
        } else if (viewDetalle === 2) {
          setViewDetalle(1);
        }
        handleClose();
      }
    });
  };

  const compareObject = (rowData, prod) => {
    if (rowData.codigoInterno === prod.codigoInterno) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
      >
        <AddBoxIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">
          Agregar Información Adicional
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              setOpenLoader(true);
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              align="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <EditTable
                  dataAditional={dataAditional}
                  setDataAditional={setDataAditional}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleAddAditional}
                >
                  Agregar
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdditionalInfoProdBtn;
