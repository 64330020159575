import React, { useEffect } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { palette } from "../../../../../configurations/styles/theme";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  divInfoUser: {
    borderStyle: "solid",
    borderColor: palette.primary.main,
    borderWidth: "1px",
    borderRadius: "8px",
    marginTop: "1%",
  },
  divlabel: {
    marginTop: "1%",
  },
  buttonClose: {
    width: "75%",
  },
}));

const DeleteDocSustentoBtn = ({
  docsSustento,
  setDocsSustento,
  docSustentoDetail,
  setDocSustentoDetail,
  viewDetalle,
  setViewDetalle,
  data,
}) => {
  const classes = useStyles();
  const dataAux = docSustentoDetail;
  const toErase = dataAux.find((doc) => doc.id === data.id);

  const newData = docSustentoDetail.filter((doc) => doc.id !== toErase.id);

  const dataAux1 = docsSustento;
  const toErase1 = dataAux1.find((doc) => doc.id === data.id);

  const newData1 = docsSustento.filter((doc) => doc.id !== toErase1.id);
  const deleteDetailDocSustento = () => {
    ConfirmAlert(
      `¿Está seguro de eliminar este documento sustento`,
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        try {
          setDocSustentoDetail(newData ? newData : []);
          setDocsSustento(newData1 ? newData1 : []);
        } catch (error) {
          console.log(error);
        }
        // setDocSustentoDetail(
        //   docSustentoDetail.filter((docSustento) => docSustento.id !== data.id)
        // );
        // setDocsSustento(
        //   docsSustento.filter((docSustento) => docSustento.id !== data.id)
        // );
        // console.log('aceptado')
        if (viewDetalle === 1) {
          setViewDetalle(2);
        } else {
          setViewDetalle(1);
        }
      },
      () => {
        // console.log('cancelado')
        if (viewDetalle === 1) {
          setViewDetalle(2);
        } else {
          setViewDetalle(1);
        }
      }
    );
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={deleteDetailDocSustento}
        className={classes.IconButton}
      >
        <DeleteForeverIcon style={{ color: palette.error.main }} />
      </IconButton>
    </>
  );
};

export default DeleteDocSustentoBtn;
