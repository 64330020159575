const ACTIONS = {
    ADD_COSTUMER: "ADD_COSTUMER",
    ADD_MORE_INFO: "ADD_MORE_INFO_DEBIT_NOTE",
    ADD_RISE: "ADD_RISE",
    ADD_RUC: "ADD_RUC",
    ADD_USER: "ADD_USER",
    ADD_REASONS: "ADD_REASONS",
    ADD_TAXES: "ADD_TAXES",
    ADD_CODE_TAX_ICE: "ADD_CODE_TAX_ICE",
    ADD_CODE_TAX_IVA: "ADD_CODE_TAX_IVA",
    CHANGE_DATE_AUTH: "CHANGE_DATE_AUTH",
    CHANGE_DATE_EMISION:  "CHANGE_DATE_EMISION",
    CHANGE_NUM_DOC: "CHANGE_NUM_DOC",
    CHANGE_ERRORS_LABELS: "CHANGE_ERRORS_LABELS_DEBIT_NOTE",
    CLEAR_ALL: "CLEAR_ALL",
    SAVE_CACHE: "SAVE_CACHE",
};

export default ACTIONS;