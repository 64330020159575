import actions from "../types/creditNoteType";

const initialState = {
  rucTransportista: null,
  dirPartida: null,
  razonSocialTransportista: null,
  fechaIniTransporte: null,
  fechaFinTransporte: null,
  placa: null,

  errors: {
    rucTransportista: null,
    dirPartida: null,
    razonSocialTransportista: null,
    fechaIniTransporte: null,
    fechaFinTransporte: null,
    placa: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.ADD_RUC:
      return { ...state, rucTransportista: action.payload };

    case actions.ADD_REASON:
      return { ...state, razonSocialTransportista: action.payload };

    case actions.ADD_DIR:
      return { ...state, dirPartida: action.payload };

    case actions.ADD_PLACA:
      return { ...state, placa: action.payload };
    case actions.CLEAR_ALL:
      return initialState;

    case actions.CHANGE_ERRORS_LABELS_GUIDE:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
