import { clientPay } from 'configurations/axios';
import {
    SATRT_GET_NUMBER,
    GET_NUMBER_VOUCHERS,
    GET_NUMBER_SUCCESS,
    GET_NUMBER_ERROR,
} from '../types/voucherTypes';
import { getRUCEmpresa } from './userActions';

export function getNumberVoucher(requestConfig) {

    return async (dispatch) => {

        dispatch(getNumberVoucherStart());
        await clientPay.get(`${requestConfig.uri}${requestConfig ? requestConfig.tok ? getRUCEmpresa(requestConfig.tok) : '0' : '0'}`).
            then(
                response => {
                    if (response.status === 200) {
                        dispatch(getNumberVoucherSuccess(response.data));
                    }
                }
            ).catch(exception => {
                if (exception.response) {
                    console.error('error 1', exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error en la red' : 'Error en la red');
                } else {
                    console.error('error 2', exception);
                }
                dispatch(getNumberVoucherError())
            })
    }

}

export const getNumberVoucherStart = () => ({
    type: SATRT_GET_NUMBER,
    payload: true,
})

export function getNumberVoucherSuccess(numberVoucer) {
    return ({
        type: GET_NUMBER_SUCCESS,
        payload: numberVoucer,
    })
}

export const getNumberVoucherError = () => ({
    type: GET_NUMBER_ERROR,
    payload: false,
})