import React, { useState,  useCallback } from "react";
import { makeStyles, Chip } from "@material-ui/core";
import typesNotification from "../alert/types";
import { palette } from "configurations/styles/theme";
import DialogNotifications from "./dialog";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
    padding: "2%",
    display: "flex",
  },
  btnInfo: {
    backgroundColor: palette.info.light,
  },
  btnSuccess: {
    backgroundColor: palette.success.light,
  },
  btnError: {
    backgroundColor: palette.error.light,
  },
  btnWarn: {
    backgroundColor: palette.warning.light,
  },
}));

const getNotifications = (lstNotifications, typeNotification) => {
  if (
    typeNotification === typesNotification.info ||
    typeNotification === typesNotification.success ||
    typeNotification === typesNotification.warning ||
    typeNotification === typesNotification.error
  ) {
    return lstNotifications.filter((item) => item.type === typeNotification);
  } else {
    return lstNotifications;
  }
};

const BtnNotifications = ({
  titleBtn = "Notificaciones",
  typeNotification = "info",
}) => {
  const classes = useStyles();
  const [openDialog, setOpen] = useState(false);

  const lstNotifications =
    useSelector((state) => state.notifications.lstNotifications) || [];

  const [notifications, setNotification] = useState(
    getNotifications(lstNotifications, typeNotification)
  );


  const buildStyleBtn = () => {
    switch (typeNotification) {
      case typesNotification.info:
        return classes.btnInfo;
      case typesNotification.success:
        return classes.btnSuccess;
      case typesNotification.warning:
        return classes.btnWarn;
      case typesNotification.error:
        return classes.btnError;
      default:
        return classes.btnInfo;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setNotification(getNotifications(lstNotifications, typeNotification))
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Chip
        className={buildStyleBtn()}
        onClick={handleClickOpen}
        label={titleBtn}
      />
      <DialogNotifications
        open={openDialog}
        onClose={handleClose}
        notifications={notifications}
      />
    </>
  );
};

export default React.memo(BtnNotifications);
