import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TextError from "componets/TextError";
import validations from "../validations";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { palette, styleInput } from "configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  form: {
    width: "100%",
  },
}));


const initErrors = {
  razon: null,
  valor: null,
};

/**
 * @author {Dany_Lasso}
 * @param {Object} reason
 */
const BtnEditReason = ({ reason, onSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = useState(initErrors);
  const [data, setData] = useState(reason);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setData(reason);
    setErrors(initErrors);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    const newError = validations.newReason.onChange(
      event.target.name,
      event.target.value
    );
    setErrors({
      ...errors,
      [event.target.name]: newError,
    });
    if (newError === null) {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validations.newReason.onSubmit(data);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (typeof onSubmit === "function") {
        onSubmit(data)
        enqueueSnackbar("Motivo editado.", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 10000,
        });
        handleCloseDialog();
      }
    }
  };

  if (!openDialog) {
    return (
      <IconButton
        color="primary"
        onClick={handleOpenDialog}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleOpenDialog}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
      <Dialog open={openDialog} fullWidth maxWidth="sm">
        <DialogTitle>Editar Motivo</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={3}>
                <label>
                  Motivo: <font color={palette.error.main}> *</font>{" "}
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <input
                  type="text"
                  className={styleInput(errors.razon, data.razon)}
                  autoComplete="off"
                  name="razon"
                  value={data.razon}
                  onChange={handleChange}
                />
                <TextError text={errors.razon} />
              </Grid>
              <Grid item xs={12} md={3}>
                <label>
                  {" "}
                  Valor: <font color={palette.error.main}> *</font>{" "}
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <input
                  className={styleInput(errors.valor, data.valor)}
                  autoComplete="off"
                  name="valor"
                  value={data.valor}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <TextError text={errors.valor} />
              </Grid>
              <br />
              <Grid container xs={12} justify="center" spacing={1}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseDialog}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BtnEditReason;
