import React, { Fragment } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { palette } from 'configurations/styles/theme';
import { ConfirmAlert, iconsAlert } from 'utils/AlertsCustom';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
}))


const DeleteAdditionalInfoInvBtn = ({ data, additionalInfoInvoice, setAdditionalInfoInvoice, viewAdditionalInfo, setViewAdditionalInfo }) => {

    const classes = useStyles();
    const deleteAdditionalInfo = () => {
        ConfirmAlert(
            `¿Está seguro de eliminar esta información?`,
            "",
            iconsAlert.question,
            true,
            "Sí",
            "Cancelar",
            () => {
                setAdditionalInfoInvoice(additionalInfoInvoice.filter(info => info.id !== data.id));
                if (viewAdditionalInfo === 1) {
                    setViewAdditionalInfo(2);
                } else {
                    setViewAdditionalInfo(1);
                }
            },
            () => {
                if (viewAdditionalInfo === 1) {
                    setViewAdditionalInfo(2);
                } else {
                    setViewAdditionalInfo(1);
                }
            }
        );
    }

    return (
        <Fragment>

            <IconButton color="primary" aria-label="upload picture" component="span" className={classes.IconButton} onClick={deleteAdditionalInfo} >
                <DeleteForeverIcon style={{ color: palette.error.main }} />
            </IconButton>

        </Fragment>

    );
}

export default DeleteAdditionalInfoInvBtn;