import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';

const DateProvaider = ({ children }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            {children}
        </MuiPickersUtilsProvider>
    );
}

export default DateProvaider;