import {
    SATRT_GET_NUMBER,
    GET_NUMBER_SUCCESS,
    GET_NUMBER_ERROR,
} from '../types/voucherTypes';

const initialState = {
    numberVouchers: 0,
    error: null,
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SATRT_GET_NUMBER:
            return ({
                ...state,
                loading: action.payload,
            })
        case GET_NUMBER_SUCCESS:
            return ({
                ...state,
                error: null,
                numberVouchers: Number(action.payload),
            })
        case GET_NUMBER_ERROR:
            return ({
                ...state,
                loading: false,
                error: action.payload,
            })
        default:
            return state;
    }


}