//Libreria para los calculos
import bigDecimal from 'js-big-decimal';
/**
 * @author Dany_Lasso
 * @param {*} fecha 
 */
export const formatoFechaDD_MM_AAAA = fecha => {
    let anio = fecha.substring(0, 4);
    let mes = fecha.substring(5, 7);
    let dia = fecha.substring(8, 10);
    return dia + "/" + mes + "/" + anio;
}

/**
 * @author Dany_Lasso
 * @param {*} fecha - Date
 */
export const calcularDiasEntreFechas = (fecha) => {
    let fechaInicial = new Date(fecha);
    let fechaActual = new Date();
    //la fecha actual menos la fecha inicial dara un resultado en milisegundos por eso se divide 
    //la cantidad de milisegundos que tiene un dia
    let diasEntreFechas = (fechaInicial.getTime() - fechaActual.getTime()) / (1000 * 3600 * 24);
    return Number(bigDecimal.floor(diasEntreFechas));
}

export const buildDate = date => {
    if (typeof date === "string") {
        if(date.substring(11,19) === "00:00:00"){
            return date.substring(0,11) + "05:59:00" + date.substring(19,);
        }else{
            return date;
        }
    } else {
        return null;
    }

}

export const parseDate = (dateSys, tipo)=> {
    if (tipo === 1) {
      var date = new Date(dateSys);
      let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = " 00:00:00";
      let dateWithHour = [date.getFullYear(), mnth, day].join("-");
      let fullDate = dateWithHour + hours;

      return fullDate;
    } else {
      var date = new Date(dateSys);
      let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = " 23:59:59";
      let dateWithHour = [date.getFullYear(), mnth, day].join("-");
      let fullDate = dateWithHour + hours;
      return fullDate;
    }
  }


