import React from "react";
import { Grid, CardHeader } from "@material-ui/core";
import { useStylesGlobal } from "configurations/styles/theme";
import AssignmentIcon from "@material-ui/icons/Assignment";
//componentes propios
//import TypeCostumer from "./TypeCostumer";
import DataClient from "./DataClient";

const ClientForm = ({
  stateRedux,
  action,
  saveCache,
  id,
  ErrorRedux = null,
}) => {
  const classesGlobales = useStylesGlobal();
  //TODO: comentado por inestabilidad del SRI
  //const [typeCostumer, setTypeCostumer] = useState("D");

  /*const handleChangeTypeCostumer = (event) => {
    setTypeCostumer(event.target.value);
    if (event.target.value === "C") {
      action("0");
    } else if (event.target.value === "D") {
      action(null);
    }
  };*/

  return (
    <div className={classesGlobales.rootDiv} id={id}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid container xs={12} md={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos Cliente"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>

        {/*<Grid item xs={12} md={12} >
                    <TypeCostumer onChange={handleChangeTypeCostumer} value={typeCostumer} />
    </Grid>*/}

        {
          //typeCostumer === "D" ? (
          //<>
          <DataClient
            action={action}
            stateRedux={stateRedux}
            saveCache={saveCache}
            ErrorRedux={ErrorRedux}
          />
          //</Grid></>
          //) : null
        }
      </Grid>
    </div>
  );
};

export default ClientForm;
