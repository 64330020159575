import React, { Fragment } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";
import { palette } from 'configurations/styles/theme';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
}))

const DeleteProductDetail = ({ productsDetalle, setProductsDetalle, data, viewDetalle, setViewDetalle, detalles, setDetalles }) => {
    const classes = useStyles();
    const deleteDetail = () => {
        ConfirmAlert(
            `¿Está seguro de eliminar el detalle?`,
            "",
            iconsAlert.question,
            true,
            "Sí",
            "Cancelar",
            () => {
                setProductsDetalle(productsDetalle.filter(product => product.id !== data.id))
                setDetalles(detalles.filter(detalle => detalle.id !== data.id))
                if (viewDetalle === 1) {
                    setViewDetalle(2)
                } else {
                    setViewDetalle(1)
                }
            },
            () => {
                if (viewDetalle === 1) {
                    setViewDetalle(2)
                } else {
                    setViewDetalle(1)
                }
            }
        );

    }

    return (
        <Fragment>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={deleteDetail} className={classes.IconButton}>
                <DeleteForeverIcon style={{ color: palette.error.main }} />
            </IconButton>

        </Fragment>
    )
}

export default DeleteProductDetail;