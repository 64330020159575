import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { makeStyles, Grid, CardHeader } from "@material-ui/core";
import { useStylesGlobal } from "configurations/styles/theme";
import tableStyle from "configurations/styles/table";
import { calculateTotal } from "utils/checkTaxes";
import BtnAddDetail from "../../../Buttons/AddDetailGeneric";
import BtnEditDetail from "../../../Buttons/EditDetailGeneric";
import { useSelector, useDispatch } from "react-redux";
import {
  AddDetailAction,
  AddOneDetailAction,
} from "redux/actions/CreditNoteActions";
import { countDecimals } from "utils/checkInputs";
import bigDecimal from "js-big-decimal";
import DeleteProduct from "../Buttons/DeleteProduct";
import ShowTaxes from "../Buttons/ShowTaxes";
import ShowMoreInfo from "../Buttons/ShowMoreInfo";
import TotalsDocument from "../../General/TotalsDocument";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";

const useStyles = makeStyles({
  anyProductContainer: {
    textAlign: "center",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "8px",
    minHeight: "80px",
    maxHeight: "120px",
    verticalAlign: "middle",
  },
  subtitle: {
    paddingLeft: "0%",
  },
  btnAdd: {
    padding: "1%  1.5%",
  },
});

const FormCreditNote = ({ id }) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [updateView, setUpdate] = useState(true);
  const creditNote = useSelector((state) => state.creditNote);
  const data = creditNote ? creditNote.detalles : [];
  const isRecoveryInvoice = creditNote.recoveryData;
  const totalRecoveryInvoice = creditNote.totalRecoveryInvoice;
  const dispatch = useDispatch();
  const addDetails = (details) => dispatch(AddDetailAction(details));

  const handleSubmitDetail = (detail) => {
    if (validatenNewDetail([...data, { ...detail }])) {
      setUpdate(true);
      dispatch(AddOneDetailAction(detail));
    }
  };

  const handleRemoveProduct = (item) => {
    const listProduct = creditNote.detalles;
    for (let i = 0; i < listProduct.length; i++) {
      if (listProduct[i].id === item.id) {
        listProduct.splice(i, 1);
      }
    }
    setUpdate(true);
    addDetails(listProduct);
  };

  useEffect(() => {
    if (updateView) {
      setUpdate(false);
    }
  }, [updateView]);

  useEffect(() => {
    if (creditNote.recoveryData) {
      setUpdate(true);
    }
  }, [creditNote.recoveryData]);

  const handleChangeProduct = (product) => {
    const listProduct = creditNote.detalles;
    for (let i = 0; i < listProduct.length; i++) {
      if (listProduct[i].id === product.id) {
        listProduct[i] = product;
      }
    }
    if (validatenNewDetail(listProduct)) {
      setUpdate(true);
      addDetails(listProduct);
    }
  };

  const handleCalculateTotalProduct = (product) => {
    const price = new bigDecimal(product.precioUnitario);
    const count = new bigDecimal(product.cantidad);
    let discount = new bigDecimal(product.descuento);
    const CienConstant = new bigDecimal(100);
    if (product.isDescuentoPorcentaje) {
      discount = price.multiply(count).multiply(discount).divide(CienConstant);
    }

    return price
      .multiply(count)
      .subtract(discount)
      .round(2, bigDecimal.RoundingModes).value;
  };

  const handlePriceValue = (product) => {
    if (countDecimals(product.precioProducto) < 2) {
      return product.precioProducto.toFixed(2);
    }
    return product.precioProducto;
  };

  const handleDiscountValue = (product) => {
    if (product.isDescuentoPorcentaje) {
      const price = new bigDecimal(product.precioUnitario);
      const count = new bigDecimal(product.cantidad);
      let discount = new bigDecimal(product.descuento);
      const CienConstant = new bigDecimal(100);
      return price.multiply(count).multiply(discount).divide(CienConstant).round(2, bigDecimal.RoundingModes.HALF_UP).value;
    } else {
      if (countDecimals(product.descuento) < 2) {
        return product.descuento.toFixed(2);
      }
      return product.descuento;
    }
  };

  const columns = [
    {
      title: "Cantidad",
      field: "cantidad",
      cellStyle: tableStyle.cellTable,
      width: "4%",
    },
    {
      title: " Descripción",
      field: "descripcion",
      cellStyle: tableStyle.cellLeft,
      width: "20%",
    },
    {
      title: "Valor Unitario",
      cellStyle: tableStyle.cellRight,
      width: "11%",
      render: handlePriceValue,
    },
    {
      title: "Descuento",
      cellStyle: tableStyle.cellTable,
      width: "5%",
      render: handleDiscountValue,
    },
    {
      title: "Total",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => handleCalculateTotalProduct(rowData),
    },
    {
      title: "Detalles Adicionales",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => <ShowMoreInfo product={rowData} />,
    },
    {
      title: "Impuestos",
      cellStyle: tableStyle.cellTable,
      width: "5%",
      render: (rowData) => <ShowTaxes product={rowData} />,
    },
    {
      title: "Editar",
      cellStyle: tableStyle.cellTable,
      width: "5%",
      render: (rowData) => (
        <BtnEditDetail productProp={rowData} onSubmit={handleChangeProduct} />
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "5%",
      render: (rowData) => (
        <DeleteProduct product={rowData} onDelete={handleRemoveProduct} />
      ),
    },
  ];

  const validatenNewDetail = (newDetails = data) => {
    if (isRecoveryInvoice) {
      if (Array.isArray(newDetails)) {
        const total = calculateTotal(newDetails);
        if (total <= totalRecoveryInvoice) {
          return true;
        } else {
          setTimeout(() => {
            Swal.fire(
              "No puede agregar más detalles, porque excede el valor de la factura cargada.",
              "",
              "warning"
            );
          }, 100);
          return false;
        }
      }
    }
    return true;
  };

  return (
    <div>
      <div className={classesGlobales.rootDiv} id={id}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={12}
            justify="center"
            className={classes.subtitle}
          >
            <CardHeader
              className={classesGlobales.cabecera}
              title={"Detalles"}
              avatar={<AssignmentIcon></AssignmentIcon>}
            />
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            className={classes.btnAdd}
            justify="center"
          >
            <Grid item xs={12} sm={4} md={2}>
              <BtnAddDetail
                onSubmit={handleSubmitDetail}
                onOpen={validatenNewDetail}
              />
            </Grid>
          </Grid>
          {data.length > 0 ? (
            <>
              <Grid
                item
                xs={12}
                md={12}
                className={classesGlobales.containerForm}
              >
                {updateView ? (
                  <div className={classes.anyProductContainer}>
                    No ha seleccionado los productos.
                  </div>
                ) : (
                    <TableDataSimplePag
                      title=""
                      columns={columns}
                      data={[...data]}
                      buscar={false}
                    ></TableDataSimplePag>
                  )}
              </Grid>
              <Grid item xs={12} md={12}>
                <TotalsDocument listOfProducts={data} update={updateView} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </div>
    </div>
  );
};

export default FormCreditNote;
