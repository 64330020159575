import React, { useState } from "react";
import MaterialTable from "material-table";
import tableStyle from "configurations/styles/table";
import { palette } from "configurations/styles/theme";
import { useSnackbar } from "notistack";
import {
  TextField,
  makeStyles,
  FormControl,
  NativeSelect,
} from "@material-ui/core";
import { tableIcons } from "./OverrideIcons";
import { Autocomplete } from "@material-ui/lab";
import checkInputs from "utils/checkInputs";

const useStyles = makeStyles({
  editComponent: {
    paddingRight: "5px",
  },
});

export default function EditTablePagos({
  dataAditionalPagos,
  setDataAditionalPagos,
  maxRows = 20,
}) {
  const letterColor = palette.primary.main;
  const classes = useStyles();
  const [dataTax, setDataTax] = useState();
  const [tax, setTax] = useState();
  const [retainedValue, setRetainedValue] = useState();
  const [retention, setRetention] = useState({
    CODIGO: "",
    VALOR: 0,
    NOMBRE: "",
  });
  const [retentionTax, setRetentionTax] = useState({
    baseImponible: 0,
    codigoImpuesto: "",
    codigoRetencion: "",
    porcentajeARetener: 0,
  });
  const [empty, setEmty] = useState({
    impuesto: true,
    retencion: true,
    codigo: true,
    base: true,
    documento: true,
    numero: true,
    fecha: true,
  });

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erroneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = (data) => {
    let errors = [];

    if (!checkInputs.notNull(data.formaPago)) {
      errors.push("Ingrese la forma de pago.");
    } else if (!checkInputs.isAlphanumeric(data.formaPago)) {
      errors.push("Ingrese solo caracteres alfanuméricos.");
    } else if (checkInputs.countLenth(data.formaPago) > 2) {
      errors.push("No ingrese más de 2 caracteres.");
    }

    if (!checkInputs.notNull(data.total)) {
      errors.push("Ingrese el total.");
    } else if (!checkInputs.isDecimal(data.total)) {
      errors.push("Ingrese solo números y use (.) para separar decimales.");
    }

    errors.forEach((item) => {
      enqueueSnackbar(item, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 10000,
      });
    });

    return errors.length === 0;
  };

  const columns = [
    {
      title: "Forma Pago",
      field: "formaPago",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <FormControl>
          <NativeSelect
            className="form-control"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <option aria-label="None" value="">
              --Seleccione--
            </option>
            <option value="01">Sin utilización del sistema financiero</option>
            <option value="15">Compensación de deudas</option>
            <option value="16">Tarjeta de débito</option>
            <option value="17">Dinero electrónico</option>
            <option value="18">Tarjeta pre-pago</option>
            <option value="19">Tarjeta de crédito</option>
            <option value="20">
              Otros con utilización del sistema financiero
            </option>
            <option value="21">Endoso de títulos</option>
          </NativeSelect>
        </FormControl>
      ),
    },
    {
      title: "Total",
      field: "total",
      cellStyle: tableStyle.cellTable,
      editComponent: (props) => (
        <TextField
          type="text"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          variant="outlined"
          placeholder="Total"
          className={classes.editComponent}
        />
      ),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  return (
    <MaterialTable
      title={
        <div>
          Pagos <font color={palette.error.main}> * </font>
          {dataAditionalPagos.length > 0 ? null : (
            <span style={{ color: "red" }}>
              por favor ingresa al menos un pago
            </span>
          )}
        </div>
      }
      columns={columns}
      data={dataAditionalPagos.length > 0 ? dataAditionalPagos : []}
      localization={{
        header: {
          actions: "",
        },
        body: {
          editRow: {
            deleteText: "¿Está seguro de eliminar la fila ?",
            saveTooltip: "Guardar cambios.",
            cancelTooltip: "Cancelar cambios.",
          },
          addTooltip: "Agregar",
          editTooltip: "Editar fila",
          deleteTooltip: "Eliminar fila",
          emptyDataSourceMessage: "No existen datos",
        },
        pagination: {
          labelRowsSelect: "Filas por página",
          labelDisplayedRows: "{from}-{to} de {count}",
        },
      }}
      options={{
        search: false,
        sorting: false,
        headerStyle: {
          backgroundColor: letterColor,
          color: "#FFFFFF",
          borderStyle: "solid",
          borderColor: letterColor,
          borderWidth: "1px",
          alignItems: "center",
          textAlign: "center",
          padding: "0px",
        },
        rowStyle: {
          borderStyle: "solid",
          borderColor: letterColor,
          borderWidth: "1px 1px ",
          alignContent: "center",
          textAlign: "center",
          paddingRight: "1px",
        },
      }}
      editable={{
        isEditable: (rowData) =>
          typeof rowData.isEditable === "boolean" ? rowData.isEditable : true,
        isDeletable: (rowData) =>
          typeof rowData.isDeletable === "boolean" ? rowData.isDeletable : true,
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (dataAditionalPagos.length < maxRows) {
                if (typeof newData === "object") {
                  const isValid = handleSubmit(newData);
                  if (isValid) {
                    newData.total = +newData.total;
                    setDataAditionalPagos([...dataAditionalPagos, newData]);
                    resolve();
                  } else {
                    reject();
                  }
                }
              } else {
                enqueueSnackbar(`Únicamente puede agregar ${maxRows} filas`, {
                  variant: "warning",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 4000,
                });
                resolve();
              }
            }, 1000);
          }),
        // onRowUpdate: (newData, oldData) =>
        //   new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       const dataUpdate = [...dataAditionalPagos];
        //       const index = oldData.tableData.id;
        //       const isValid = handleSubmit(newData);
        //       if (isValid) {
        //         dataUpdate[index] = newData;
        //         setDataAditionalPagos([...dataUpdate]);
        //         resolve();
        //       } else {
        //         reject();
        //       }
        //     }, 1000);
        //   }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...dataAditionalPagos];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setDataAditionalPagos([...dataDelete]);
              resolve();
            }, 1000);
          }),
      }}
      icons={tableIcons}
    />
  );
}
