import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

//componentes del sistema
import { palette } from "configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  form: {
    width: "100%",
  },
}));

/**
 * @author {Dany_Lasso}
 * @param {Object} reason
 */
const BtnEditReason = ({ reason, onDelete }) => {
  const classes = useStyles();

  const handleDelete = () => {
    if (typeof onDelete === "function") {
      onDelete(reason);
    }
  };

  const handleClick = () => {
    ConfirmAlert(
      "",
      "¿Está seguro que desea eliminar el motivo?",
      iconsAlert.question,
      true,
      "Eliminar",
      "Cancelar",
      handleDelete,
      () => {}
    );
  };

  return (
    <IconButton
      color="primary"
      onClick={handleClick}
      className={classes.IconButton}
    >
      <DeleteIcon style={{ color: palette.error.main  }} />
    </IconButton>
  );
};

export default BtnEditReason;
