import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux'
import { getRUCEmpresa } from 'redux/actions/userActions';
//componentes del sistema
import TablePaginated from 'widgets/Tables/TablePaginated';
import { API_GET_ALL_SECUENCIALES } from './apis';
import tableStyle from 'configurations/styles/table'
import AgregarSecuencial from './Buttons/AgregarSecuencial';
import InfoSecuencial from './Buttons/InfoSecuencial';
import UpdateSecuencial from './Buttons/UpdateSecuencial';
import { CardGeneric } from '../components/CardGeneric';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '35px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: "50px"
    },
    headerBottons: {
        padding: '0.5%'
    }
}));

const AdminSecuencial = () => {
    const tokenUser = useSelector(state => state.user.tk);
    const classesView = useStyles();
    const [update, setUpdate] = useState(0);

    const ParseStateSecuencial = data => {
        return data.data.estado ? 'Abierto' : 'Cerrado';
    }

    const columns = [
        { title: 'Establecimiento', field: 'codigoEstablecimiento', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'Punto de Emisión', field: 'puntoEmision', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'Dirección', field: 'direccion', cellStyle: tableStyle.cellLeft, width: '26%' },
        { title: 'S. Factura', field: 'secuencialFactura', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'S. Crédito', field: 'secuencialNotaCredito', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'S. Débito', field: 'secuencialNotaDebito', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'S. Remisión', field: 'secuencialGuiaRemision', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'S. Retención', field: 'secuencialRetencion', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'S. Compra', field: 'secuencialLiquidacionCompra', cellStyle: tableStyle.cellTable, width: '7%' },
        { title: 'Estado', cellStyle: tableStyle.cellTable, width: '7%', render: rowData => <ParseStateSecuencial data={rowData} /> },
        { title: 'Ver', cellStyle: tableStyle.cellTable, width: '5%', render: rowData => <InfoSecuencial secuencial={rowData} /> },
        { title: 'Editar', cellStyle: tableStyle.cellTable, width: '5%', render: rowData => <UpdateSecuencial secuencial={rowData} updateView={reload} /> },
    ];

    const requestConfig = {
        uri: API_GET_ALL_SECUENCIALES + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'codigoEstablecimiento,desc'
    }

    const reload = () => { setUpdate(1); }

    useEffect(() => {
        if (update === 1) { setUpdate(0); }
    }, [update]);

    const headerBottons = () => {
        return (
            <Grid container direction="row" justify="flex-start" alignItems="center" >

            </Grid>
        );

    }

    const renderTable = () => {
        switch (update) {
            case 0:
                return (
                    <TablePaginated
                        title=""
                        columns={columns}
                        requestConfig={requestConfig}
                        BotonesHeader={headerBottons}
                    />
                );
            case 1:
                return (
                    <div align='center'>
                        <CircularProgress />
                    </div>
                );
            default:
                break;
        }
    }

    return (
        <div className={classesView.root}>
            <CardGeneric>
                <Grid item xs={12} md={3}>
                    <AgregarSecuencial updateView={reload} />
                </Grid>
            </CardGeneric>
            {renderTable()}
        </div>
    );
}

export default AdminSecuencial;