import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import { msmSwalError, msmSwalExito, palette, useStylesGlobal } from 'configurations/styles/theme';
import CompanyLogo from 'views/administración/UserAccount/CompanyLogo';
import clientBackend, { clientPay } from 'configurations/axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "1%",
        marginBottom: "1%",
        padding: "1px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: "3000",
        color: "#fff",
    },
    divForm: {
        marginBottom: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
    },
    divButton: {
        paddingLeft: "3%",
        paddingRight: "3%",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "1%",
        marginBottom: "0.5%",
    },
    aligGrid: {
        textAlign: '-webkit-center'
    }
}))
const UpVoucherImg = ({ plan, reload = () => { }, setUpdate = () => { } }) => {

    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const [openVoucher, setOpenVoucher] = useState(false);
    const [voucher, setVoucher] = useState();
    const [openLoader, setOpenLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpenVoucher(true);
    };
    const handleShowAlert = (alert) => {
        enqueueSnackbar(`Unicamente puede subir archivos .png o .jpg`, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          });
      };
    const handleCloseVoucher = () => {
        setOpenVoucher(false);

    };

    const handleSubmitLogo = (logo) => {
        //setOpenLoader(true);
        setVoucher(logo);
    };

    const validateVoucher = () => {
        if (typeof voucher === 'undefined' || voucher === '') {
            return true;
        } else {
            return false;
        }
    }

    const saveVoucher = async () => {
        if (validateVoucher()) {
            enqueueSnackbar(`Debe subir el comprobante de pago`, {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                autoHideDuration: 4000,
            });
        } else {
            setOpenLoader(true);
            await clientPay
                .put('/pago/subir/voucher', {
                    "idAsignacionPaqueteEmpresa": plan.idAsignacionPaqueteEmpresa,
                    "voucher": voucher
                })
                .then((response) => {
                    if (response.status >= 200 && response.status <= 300) {
                        handleCloseVoucher();
                        reload();
                        msmSwalExito(
                            "La transacción sera verificada en los próximos minutos, si tiene alguna inquietud por favor contáctese con su proveedor.",
                        ).then((response) => {
                            if (response.isDismissed) {

                            }
                        });


                    }
                    setOpenLoader(false);
                })
                .catch((exception) => {
                    if (exception.response) {
                        handleCloseVoucher();
                        reload();
                        if (exception.response.status === 400) {
                            msmSwalError(
                                "Error",
                                `No se pudo registrar la solicitud. Contáctese con el administrador del Sistema.`,
                            )
                        } else if (exception.response.status === 404) {
                            console.log('hay un error 404')
                            msmSwalError(
                                "Transacción fallida",
                                exception.response.data.apierror.mensaje +
                                ". Contáctese con el administrador del Sistema.",
                                { show: true, }
                            )

                        } else {
                            msmSwalError(
                                "Error",
                                `No se pudo registrar la solicitud. Contáctese con el administrador del Sistema.`,
                            )
                        }
                    } else {
                        msmSwalError(
                            "Error",
                            `No se pudo registrar la solicitud. Contáctese con el administrador del Sistema.`,
                        )
                    }
                    setOpenLoader(false);
                });
        }
    };

    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen} className={classes.IconButton}>
                <PublishIcon style={{ color: palette.primary.main }} textDecoration={'hola'} />
            </IconButton>


            <Dialog
                fullWidth
                maxWidth="sm"
                open={openVoucher}
                onClose={handleCloseVoucher}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog">Subir comprobante</DialogTitle>
                <DialogContent>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} md={12}>
                            <label>
                                <strong>Paquete Seleccionado</strong>
                            </label>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.divForm}>
                            <label>
                                Paquete:
                            </label>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.divInput}>
                            <input
                                className="form-control  is-valid"
                                type="text"
                                disabled
                                placeholder="Ej. Ordenador"
                                name="descripcionProducto"
                                value={plan.paquete.nombre}
                                //onChange={handleChange}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.divForm}>
                            <label>
                                Total:
                            </label>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.divInput}>
                            <input
                                className="form-control  is-valid"
                                type="text"
                                disabled
                                placeholder="Ej. 12.00"
                                name="precioUnitario"
                                value={`$. ${plan.valorTotalPaquete.toFixed(2)}`}
                                autoComplete="off"
                            />

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>
                        <Grid item sm={12} md={12} className={classes.aligGrid} >
                            <CompanyLogo
                                title={'Comprobante de pago'}
                                urlImage={voucher}
                                onSubmit={handleSubmitLogo}
                                onShowAlert={handleShowAlert}
                                textBtn="Subir comprobante"
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.divButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveVoucher}
                                className={classesGlobales.button}
                            >
                                Continuar
                                </Button>

                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCloseVoucher}
                                className={classesGlobales.button}
                            >
                                Cancelar
                                </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Fragment>
    );
}

export default UpVoucherImg;