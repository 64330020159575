import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Avatar,
  CardHeader,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { palette } from "configurations/styles/theme";
import pdfIcon from "assets/icons/pdf.png";
import SaveIcon from "@material-ui/icons/Save";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import EditTable from "widgets/Tables/EditTable";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useStylesGlobal } from "configurations/styles/theme";
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    flexGrow: 1,
    marginBottom: "40px",
    marginTop: "0.5%",
    paddingBottom: "0.5%",
    paddingLeft: "0.5%",
  },
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  form: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "0px",
    borderColor: palette.primary.main,
    width: "100%",
    paddingTop: "2%",
    paddingBottom: "2%",
    paddingLeft: "2%",
  },
  btnReport: {
    borderRadius: "8px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: palette.primary.main,
    color: palette.primary.main,
    marginTop: "1%",
    marginRight: "1%",
  },
  containerMoreInfo: {
    marginTop: "1%",
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    flexGrow: 1,
    marginBottom: "1%",
    padding: "0% 0% 0% 0%",
  }
}));

const FooterForm = ({
  msgMoreInfo,
  dataAditional = [],
  setDataAditional,
  onClickBtnPdf,
  onClickBtnSign,
  validatePrerequisities,
  onClickCleanForm = () => { },
  id,
}) => {
  const classes = useStyles();

  const classesGlobales = useStylesGlobal();
  const [showMoreInfo, setShowMoreInfor] = useState(false);

  const handleChange = (event) => {
    setShowMoreInfor(event.target.checked);
  };

  return (
    <Grid container spacing={1} className={classes.root} id={id}>
      <Grid item xs={12} md={5}>
        <Grid item xs={12} container spacing={1} className={classes.containerMoreInfo}>
          <Grid container xs={12} justify="center">
            <CardHeader
              className={classesGlobales.cabecera}
              title={"Información Adicional"}
              avatar={<AssignmentIcon />}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <label>{msgMoreInfo}</label>
          </Grid>
          <Grid item xs={12} md={2}>
            <Checkbox
              checked={showMoreInfo}
              onChange={handleChange}
              color="primary"
              inputProps={{ "aria-label": "show more info" }}
            />
          </Grid>
          {showMoreInfo ? (
            <Grid item xs={12} md={12}>
              <Grid container xs={12} spacing={2} className={classes.form}>
                <Grid item md={10}>
                  <small>
                    * Solo se pueden agregar 14 campos de Información Adicional
                    por Comprobante.
                  </small>
                  <br />
                  <small>
                    * Para ingresar un e-mail se debe poner CORREO en nombre y
                    el correo en valor. <br />
                    Por ejemplo: Nombre Campo: CORREO. Valor Campo:
                    user@ejemplo.com
                  </small>
                </Grid>
                <Grid item xs={12} md={12} align="center ">
                  <EditTable
                    dataAditional={dataAditional}
                    setDataAditional={setDataAditional}
                    maxRows={15}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} md={2} align="center">
        <Button
          onClick={() => validatePrerequisities(true)}
          variant="text"
          className={classes.btnReport}
          fullWidth
        >
          <Avatar variant="square" src={pdfIcon} className={classes.small} />
          Visualizar RIDE
        </Button>
      </Grid>
      <Grid item xs={12} md={2} align="center">
        <Button
          onClick={() => validatePrerequisities(false)}
          variant="text"
          className={classes.btnReport}
          fullWidth
        >
          <SaveIcon style={{ color: palette.info.main }} fontSize="large" />
          Firmar y Autorizar
        </Button>
      </Grid>
      <Grid item xs={12} md={2} align="center">
        <Button
          onClick={onClickCleanForm}
          variant="text"
          className={classes.btnReport}
          fullWidth
        >
          <DeleteSweepIcon
            style={{ color: palette.error.main, marginRight: "10px" }}
            fontSize="large"
          />
          Limpiar Formulario
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterForm;
