import React, { Fragment, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { API_PUT_UPDATE_USER } from "../apis";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { useStylesApp } from "hooks/useStyles";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  textFieldComboBox: {
    minWidth: "75%",
    maxWidth: "95%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: palette.primary.main,
  },
  formControl: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}));

/**
 * @author {Dany_Lasso}
 * @param {Object} user
 */
const EditUserBtn = ({ user, updateView }) => {
  const [open, setOpen] = useState(false);
  const classesGlobal = useStylesGlobal();
  const [updateUser, serUpdateUser] = useState(user);
  const [openLoader, setOpenLoader] = useState(false);
  const [inputsStyle, setInputStyle] = useState("form-control");
  const classes = useStyles();
  const classesApp = useStylesApp();
  const { enqueueSnackbar } = useSnackbar();
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [stateUser, setStateUser] = useState(user.estadoUsuario);
  const existError = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    setOpenLoader(true);
    updateUser.estadoUsuario = stateUser;
    await clientBackend
      .put(
        API_PUT_UPDATE_USER + updateUser.idAsignacionEmpresaUsuario,
        updateUser
      )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Usuario editado.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          setOpenLoader(false);
          handleClose(false);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo actualizar el usuario." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró el usuario a actualizar.",
            });
          } else {
            setMsg({
              show: true,
              msg:
                "Error al actualizar el usuario. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg:
              "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.editUser,
    existError
  );

  const handleClickOpen = () => {
    setOpen(true);
    setInputStyle("form-control");
    serUpdateUser(user);
    setStateUser(user.estadoUsuario);
    getObject({
      ...user,
      estadoUsuario: user.estadoUsuario,
    });
    setMsg({ show: false, msg: null, type: null });
    errors.nombreUsuario = null;
    errors.correoPrincipalUsuario = null;
    errors.telefonoPrincipalUsuario = null;
    errors.estadoUsuario = null;
    setStateUser(user.estadoUsuario);
  };

  function closeAlert() {
    setMsg({
      show: false,
    });
  }

  const handleChangeUser = (event) => {
    serUpdateUser({
      ...updateUser,
      [event.target.name]: event.target.value,
    });

    getObject({
      ...updateUser,
      [event.target.name]: event.target.value,
      estadoUsuario: stateUser,
    });
  };

  const handleChangeStateUser = (event) => {
    setStateUser(event.target.value);
  };

  const renderStateUser = () => {
    if (stateUser === "PRI") {
      return (
        <Grid item xs={12} sm={9} md={5} className={classes.divInfoUser}>
          <input
            className={inputsStyle}
            value="Aún no realiza el primer inicio de sesión."
            onFocus={handleFocusStatePri}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm={9} md={4} className={classes.divInfoUser}>
          <FormControl className={classes.formControl}>
            <Select
              value={stateUser}
              variant="filled"
              onChange={handleChangeStateUser}
            >
              <MenuItem value="ACT">Activo</MenuItem>
              <MenuItem value="DES">Inactivo</MenuItem>
              <MenuItem value="BLO">Bloqueado</MenuItem>
            </Select>
            {errors.estadoUsuario ? (
              <p className={classesApp.errorLetters}>{errors.estadoUsuario}</p>
            ) : null}
          </FormControl>
        </Grid>
      );
    }
  };

  const handleFocusStatePri = () => {
    setMsg({
      show: true,
      msg:
        "Puede cambiar el estado  del usuario, posterior al primer inicio de sesión.",
      type: "warning",
    });
  };

  if (open) {
    return (
      <Fragment>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleClickOpen}
          className={classes.IconButton}
        >
          <EditIcon style={{ color: palette.primary.main }} />
        </IconButton>
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClose}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="max-width-dialog-title">Editar Usuario</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <label>
                    Los campos marcados con ({" "}
                    <font color={palette.error.main}> *</font> ) son
                    obligatorios:
                  </label>
                </Grid>
                {msgError.show ? (
                  <Alert
                    tipo={msgError.type}
                    mensaje={msgError.msg}
                    mesajeSubrayado={null}
                    titulo={null}
                    mostrar={msgError.show}
                    cerrarAlerta={closeAlert}
                  />
                ) : null}
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  Identificación:
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <input
                    value={updateUser.usuario.identificacionUsuario}
                    disabled
                    className="form-control is-valid"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  Nombre de usuario:
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <input
                    value={updateUser.nombreUsuarioAcceso}
                    disabled
                    className="form-control is-valid"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  Nombre: <font color={palette.error.main}> *</font>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <input
                    type="text"
                    className={
                      errors.nombreUsuario
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={updateUser.nombreUsuario}
                    name="nombreUsuario"
                    onChange={handleChangeUser}
                    autoComplete="off"
                  />
                  {errors.nombreUsuario ? (
                    <p className={classesApp.errorLetters}>
                      {errors.nombreUsuario}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3} md={2} className={classes.divlabel}>
                  Correo principal: <font color={palette.error.main}> *</font>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <input
                    type="text"
                    className={
                      errors.correoPrincipalUsuario
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={updateUser.correoPrincipalUsuario}
                    name="correoPrincipalUsuario"
                    onChange={handleChangeUser}
                    autoComplete="off"
                  />
                  {errors.correoPrincipalUsuario ? (
                    <p className={classesApp.errorLetters}>
                      {errors.correoPrincipalUsuario}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3} md={2} className={classes.divlabel}>
                  Teléfono:
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <input
                    type="text"
                    className={
                      errors.telefonoPrincipalUsuario
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={updateUser.telefonoPrincipalUsuario}
                    name="telefonoPrincipalUsuario"
                    onChange={handleChangeUser}
                    autoComplete="off"
                  />
                  {errors.telefonoPrincipalUsuario ? (
                    <p className={classesApp.errorLetters}>
                      {errors.telefonoPrincipalUsuario}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3} md={2} className={classes.divForm}>
                  <label>Dirección:</label>
                </Grid>
                <Grid item xs={12} sm={9} md={4} className={classes.divInput}>
                  <input
                    type="text"
                    className={
                      errors.direccionUsuario
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    name="direccionUsuario"
                    value={updateUser.direccionUsuario}
                    onChange={handleChangeUser}
                    autoComplete="off"
                  />
                  {errors.direccionUsuario ? (
                    <p className={classesApp.errorLetters}>
                      {errors.direccionUsuario}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3} md={2} className={classes.divlabel}>
                  Estado:
                </Grid>

                {renderStateUser()}

                <Grid container spacing={3} justify="center">
                  <Grid item md={12} xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classesGlobal.marginButton}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                    className={classesGlobal.marginButton}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
        <Backdrop className={classes.backdrop} open={openLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    );
  } else {
    return (
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
        className={classes.IconButton}
      >
        <EditIcon style={{ color: palette.primary.main }} />
      </IconButton>
    );
  }
};

export default EditUserBtn;
