import actions from "../types/creditNoteType";

export const addDocAction = (doc) => {
    return ({
        type: actions.ADD_DOC,
        payload: doc
    })
}



export const addRUCAction = (ruc) => {
    return ({
        type: actions.ADD_RUC,
        payload: ruc
    })
}

export const addRISEAction = (rise) => {
    return ({
        type: actions.ADD_RISE,
        payload: rise
    })
}

export const addReasonAction = (reason) => {
    return ({
        type: actions.ADD_REASON,
        payload: reason
    })
}

export const AddUserAction = (user) => {
    return ({
        type: actions.ADD_USER,
        payload: user
    })
}

export const AddCostumerAction = (costumer) => {
    return ({
        type: actions.ADD_COSTUMER,
        payload: costumer
    })
}

export const SaveCacheAction = (cache) => {
    return ({
        type: actions.SAVE_CACHE,
        payload: cache
    })
}

export const AddDetailAction = detail => {
    return ({
        type: actions.ADD_DETAIL,
        payload: detail
    })
}

export const AddOneDetailAction = detail => {
    return ({
        type: actions.ADD_ONE_DETAIL,
        payload: detail
    })
}

export const AddMoreInfoAction = moreInfo => {
    return ({
        type: actions.ADD_MORE_INFO,
        payload: moreInfo
    })
}

export const changeDateEmisionAction = date => {
    return ({
        type: actions.CHANGE_DATE_EMISION,
        payload: date
    })
}

export const changeDateAuthAction = date => {
    return ({
        type: actions.CHANGE_DATE_AUTH,
        payload: date
    })
}

export const changeNumDocAction = numDoc => {
    return ({
        type: actions.CHANGE_NUM_DOC,
        payload: numDoc
    })
}

export const clearCreditNoteAction = () => {
    return ({
        type: actions.CLEAR_ALL,
    })
}

export const recoveryAllDataAction = data => {
    return ({
        type: actions.RECOVERY_ALL_DATA,
        payload: data
    })
}

export const clearFormCostumerAction = () => ({
    type: actions.CLEAR_FORM_COSTUMER
})


export const changeErrorLabelsAction = (error) => ({
    type: actions.CHANGE_ERRORS_LABELS,
    payload: error
})