import {
    PROCESS_LOADING,
    PROCESS_FINISHED
} from '../types/processAsyncType';

export const processoLoadingAction = () => ({
    type: PROCESS_LOADING
});

export const processoFinishedAction = () => ({
    type: PROCESS_FINISHED
});