import {
  START_SEARCHAUT_VOUCHERS,
  SEARCHAUT_VOUCHERS_SECCES,
  SEARCHAUT_VOUCHERS_ERROR,
  SEARCHAUT_VOUCHERS_CLEAR,
} from "../types/voucherTypes";

const initialSate = {
  comprobantes: [],
  loading: false,
  error: null,
};
export default function searchVouchersReducer(state = initialSate, action) {
  switch (action.type) {
    case START_SEARCHAUT_VOUCHERS:
      return {
        loading: action.payload,
      };
    case SEARCHAUT_VOUCHERS_SECCES:
      return {
        comprobantes: action.payload,
        error: null,
        loading: false,
      };
    case SEARCHAUT_VOUCHERS_ERROR:
      return {
        error: action.payload,
        loading: false,
      };
    case SEARCHAUT_VOUCHERS_CLEAR:
      return initialSate;
    default:
      return state;
  }
}
