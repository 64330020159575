import { combineReducers } from 'redux';
import resourcesReducer from './resourcesReducer';
import userReducer from './userReducer';
import processAsyncReducer from './processAsyncReducer';
import cancelVoucherReducer from './cancelVoucherReducer';
import authorizedVouchersReducer from './authorizedVouchersReducer';
import searchVouchersReducer from './searchVouchersReducre';
import creditNoteReducer from "./CreditNoteReducer";
import debitNoteReducer from "./debitNoteReducer";
import notificationsReducer from "./notificationsReducer";
import referralGuideReducer from './referralGuideReducer';
import companyReducer from "./companyReducer";
import numberVouchersReducer from './numberVouchersReducer';

export default combineReducers({
    authorizedVouchers: authorizedVouchersReducer,
    cancelVoucher: cancelVoucherReducer,
    creditNote: creditNoteReducer,
    referralGuide:referralGuideReducer,
    debitNote: debitNoteReducer,
    processAsync: processAsyncReducer,
    resources: resourcesReducer,
    searchVouchers: searchVouchersReducer,
    user: userReducer,
    notifications: notificationsReducer,
    company: companyReducer,
    numberVouchers:numberVouchersReducer,
});