import { Backdrop, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { msmSwalError, msmSwalExito, palette, useStylesGlobal } from 'configurations/styles/theme';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { clientPay } from 'configurations/axios';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "1%",
        marginBottom: "1%",
        padding: "1px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    divForm: {
        marginBottom: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
    },
    divButton: {
        paddingLeft: "3%",
        paddingRight: "3%",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "1%",
        marginBottom: "0.5%",
    },
}))

const CancelPackage = ({ plan, reload, setUpdate }) => {
    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();



    const submitCancelPackeage = (event) => {

        Swal.fire({
            title: 'Anular paquete',
            text: "¿Está seguro de anular el paquete?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            allowEnterKey:false,
            allowOutsideClick:false,

        }).then((result) => {
            if (result.value) {
                deletePackage();
            }
        })

    }

    const deletePackage = async () => {
        setOpenLoader(true);
        await clientPay.put(`/pago/anular/${plan.idAsignacionPaqueteEmpresa}`
        ).then(response => {
            if (response.status === 200) {
                reload();
                setOpenLoader(false);
                msmSwalExito('Proceso Exitoso', 'El paquete se ha anulado con éxito!');


            }
        })
            .catch(exception => {
                reload();
                setOpenLoader(false);
                if (exception.response) {
                    msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error en la red' : 'Error en la red')
                }
                else {
                    msmSwalError('Error en la red')
                }
            }
            )
    }

    return (
        <div>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={submitCancelPackeage}
                className={classes.IconButton}>
                <CancelOutlinedIcon style={{ color: palette.error.main }} textDecoration={'hola'} />
            </IconButton>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    );
}

export default CancelPackage;
