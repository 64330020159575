import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
//componentes del sistema
import TablePaginated from "widgets/Tables/TablePaginated";
import { API_GET_ALL_SECUENCIAL_USER } from "./apis";
import tableStyle from "configurations/styles/table";
import AsignarSecuencialUsuario from "./buttons/AsignarSecuencialUsuario";
import DeleteSecuencialUsuario from "./buttons/DeleteSecuencialUsuario";
import Alert from "widgets/alert";
import { CardGeneric } from "../components/CardGeneric";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "35px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "50px"
  },
  headerBottons: {
    padding: "0.5%",
  },
}));

const Home = () => {
  const tokenUser = useSelector((state) => state.user.tk);
  const classesView = useStyles();
  const [update, setUpdate] = useState(0);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const columns = [
    {
      title: "Usuario",
      field: "asignacionEmpresaUsuario.nombreUsuario",
      cellStyle: tableStyle.cellLeft,
      width: "15%",
    },
    {
      title: "Establecimiento",
      field: "secuencial.codigoEstablecimiento",
      cellStyle: tableStyle.cellTable,
      width: "7%",
    },
    {
      title: "Punto de Emisión",
      field: "secuencial.puntoEmision",
      cellStyle: tableStyle.cellTable,
      width: "7%",
    },
    {
      title: "Dirección",
      field: "secuencial.direccion",
      cellStyle: tableStyle.cellLeft,
      width: "30%",
    },
    {
      title: "Retirar asignación",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => (
        <DeleteSecuencialUsuario data={rowData} updateView={reload} />
      ),
    },
  ];

  const requestConfig = {
    uri:
      API_GET_ALL_SECUENCIAL_USER +
      (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
    metodo: "get",
    body: null,
    page: 0,
    elementos: 15,
    sort: "idAsignacionSecuencialUsuario,desc",
  };

  const reload = () => {
    setUpdate(1);
  };


  function closeAlert() {
    setMsg({ show: false });
  }

  useEffect(() => {
    if (update === 1) {
      setUpdate(0);
    }
  }, [update]);

  const headerBottons = () => {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} className={classesView.headerBottons}>
          {msgError.show ? (
            <Alert
              tipo={msgError.type}
              mensaje={msgError.msg}
              mesajeSubrayado={null}
              titulo={null}
              mostrar={msgError.show}
              cerrarAlerta={closeAlert}
            />
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const renderTable = () => {
    switch (update) {
      case 0:
        return (
          <TablePaginated
            title=""
            columns={columns}
            requestConfig={requestConfig}
            BotonesHeader={headerBottons}
          />
        );
      case 1:
        return (
          <div align="center">
            <CircularProgress />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={classesView.root}>
      <CardGeneric>
        <AsignarSecuencialUsuario updateView={reload} />
      </CardGeneric>
      {renderTable()}
    </div>
  );
};

export default Home;
