import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import { getRUCEmpresa } from '../../../redux/actions/userActions';
//componentes del sistema
import TablePaginatedVoucher from '../../../widgets/Tables/TablePaginatedVoucher'
import { API_GET_VOUCHER_CANCEL, API_GET_SEARCH_CANCEL } from './apis';
import tableStyle from '../../../configurations/styles/table'
import SearchVoucher from '../Forms/SearchVoucher';
import CancelVoucher from './Froms/CancelVoucher';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import PDFVoucherBtn from '../AuthorizedIssuedVouchers/Buttons/PDFVoucherBtn';
import XMLVoucherBtn from '../Buttons/XMLVoucherBtn';
import TablePaginatedVoucherSearch from 'widgets/Tables/TablePaginatedVoucherSearch';
import TablePaginatedSearchVoucher from 'widgets/Tables/TablePaginatedSearchVoucher';
import TablePaginated from 'widgets/Tables/TablePaginated';
import TableVoucherPaginated from 'widgets/Tables/TableVoucherPaginated';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '5%'
    },
}));


const VoucherCancel = () => {
    const tokenUser = useSelector(state => state.user.tk);
    const classesView = useStyles();
    const [paramSearch, setParamSearch] = useState('');
    //state para actualizar la vista
    const [update, setUpdate] = useState(1);
    //State para la búsqueda
    const [foundVouchers, setfoundVouchers] = useState([]);
    const columns = [
        { title: 'Número', field: `comprobanteElectronico.secuencial`, cellStyle: tableStyle.cellTable, width: '10%', render: rowData => `${rowData.comprobanteElectronico.codigoEstablecimiento}-${rowData.comprobanteElectronico.puntoEmision}-${rowData.comprobanteElectronico.secuencial}` },
        { title: 'Fecha Emisión', field: 'comprobanteElectronico.fechaEmision', cellStyle: tableStyle.cellTable, width: '10%', render: rowData => rowData.comprobanteElectronico.fechaEmision.substring(0, 10) },
        { title: 'Fecha Autorización', field: 'comprobanteElectronico.fechaAutorizacion', cellStyle: tableStyle.cellTable, width: '15%', render: rowData => rowData.comprobanteElectronico.fechaAutorizacion ? (rowData.comprobanteElectronico.fechaAutorizacion.substring(0, 10)).concat((new Date(rowData.comprobanteElectronico.fechaAutorizacion)).toString().substring(15, 24)) : 'No disponible' },
        { title: 'Receptor', field: 'receptor.razonSocial', cellStyle: tableStyle.cellLeft, width: '30%' },
        { title: 'Tipo', field: 'comprobanteElectronico.tipoComprobanteElectronico.nombreComprobante', cellStyle: tableStyle.cellTable, width: '12%', render: rowData => rowData.comprobanteElectronico.tipoComprobanteElectronico.nombreComprobante.replace("_", ' DE ') },
        { title: 'PDF', cellStyle: tableStyle.cellTable, width: '5%', render: rowData => <PDFVoucherBtn voucher={rowData} /> },
        { title: 'XML', cellStyle: tableStyle.cellTable, width: '5%', render: rowData => <XMLVoucherBtn voucher={rowData} /> }
    ];

    /*
    const requestConfig = {
        uri: API_GET_VOUCHER_CANCEL,
        metodo: 'get',
        rucEmpresa: getRUCEmpresa(tokenUser),
        page: 0,
        elementos: 5,
        paginasCargadas: []
    }*/

    const requestConfig = {
        uri: API_GET_VOUCHER_CANCEL + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'idAsignacionComprobanteElectronico,desc'
    }

    const requestConfigSeacrh = {
        uri: `${API_GET_SEARCH_CANCEL}${tokenUser ? getRUCEmpresa(tokenUser) : "0"}`,
        metodo: 'get',
        body: null,
        page: 0,
        elementos: 15,
        sort: 'idAsignacionComprobanteElectronico,desc',
    }


    useEffect(() => {
    }, [update])

    const headerBottons = () => {
        if (update === 6) {
            return (
                <Grid container direction="row" justify="flex-start" alignItems="center" >

                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => setUpdate(2)}>
                            Mostrar Todos
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    }

    const renderTable = () => {
        switch (update) {
            case 1:
                setUpdate(2);
                return (
                    <div align='center'>
                        <CircularProgress />
                    </div>
                );
            case 2:
                return (
                    <TablePaginatedVoucher
                        title=""
                        columns={columns}
                        requestConfig={requestConfig}
                        BotonesHeader={headerBottons}
                        seleccion={false}
                    />
                );
            case 3:
                return (
                    <Fragment>
                        <TablePaginatedVoucherSearch
                            title=""
                            columns={columns}
                            numeroFilas={5}
                            requestConfig={requestConfigSeacrh}
                            BotonesHeader={headerBottons}
                            paramSearch={paramSearch}
                            seleccion={false}
                        />

                    </Fragment>
                );
            case 4:
                return (
                    <Fragment>
                        <TablePaginatedSearchVoucher
                            title=""
                            columns={columns}
                            numeroFilas={5}
                            requestConfig={requestConfigSeacrh}
                            BotonesHeader={headerBottons}
                            paramSearch={paramSearch}
                            seleccion={false}
                        />

                    </Fragment>
                );
            case 5:
                return (
                    <Fragment>
                        <TableVoucherPaginated
                            title=""
                            columns={columns}
                            requestConfig={requestConfig}
                            BotonesHeader={headerBottons}
                            seleccion={false}
                        />

                    </Fragment>
                );
            default:
                break;
        }
    }



    return (
        <div className={classesView.root}>

            <SearchVoucher
                update={update}
                setUpdate={setUpdate}
                setParamSearch={setParamSearch}
            />


            <CancelVoucher
                update={update}
                setUpdate={setUpdate}
            />
            <br />
            {renderTable()}
        </div>
    );
}
export default VoucherCancel;