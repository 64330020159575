import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { palette } from 'configurations/styles/theme';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    }
}))
const DeletePaymentBtn = ({ data, pagos, setPagos, viewPago, setViewPago }) => {

    const classes = useStyles();

    const deletePayment = () => {

        ConfirmAlert(
            `¿Está seguro de eliminar este pago?`,
            "",
            iconsAlert.question,
            true,
            "Sí",
            "Cancelar",
            () => {
                setPagos(pagos.filter(pago => pago.id !== data.id))
                if (viewPago === 1) {
                    setViewPago(2)
                } else {
                    setViewPago(1)
                }
            },
            () => {
                if (viewPago === 1) {
                    setViewPago(2)
                } else {
                    setViewPago(1)
                }
            }
        );
    }


    return (
        <>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={deletePayment} className={classes.IconButton}>
                <DeleteForeverIcon style={{ color: palette.error.main }} />
            </IconButton>

        </>
    );
}

export default DeletePaymentBtn;