import React, { useEffect, useState } from "react";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import TableSimple from "widgets/Tables/TableDataSimplePag";
import tableStyle from "configurations/styles/table";
import { useDispatch, useSelector } from "react-redux";
import BtnDeleteReason from "../Buttons/DeleteReason";
import BtnEditReason from "../Buttons/EditReason";
import { addReasonsAction } from "redux/actions/debitNoteActions";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    display: "flow-root",
    padding: "1%",
  },
});

const AllReasons = () => {
  const reasons = useSelector((state) => state.debitNote.motivos);
  const classesView = useStyles();
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (reasons.length > 0) {
      setUpdate(true);
    }
  }, [reasons.length]);

  const columns = [
    {
      title: "Motivo",
      field: "razon",
      cellStyle: tableStyle.cellTable,
      width: "40%",
    },
    {
      title: "Valor",
      cellStyle: tableStyle.cellTable,
      width: "40%",
      render: (rowData) => Number(rowData.valor).toFixed(2),
    },
    {
      title: "Editar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <BtnEditReason reason={rowData} onSubmit={handleEditReason} />
      ),
    },
    {
      title: "Eliminar",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => (
        <BtnDeleteReason reason={rowData} onDelete={handleDeleteReason} />
      ),
    },
  ];

  const handleEditReason = (editReason) => {
    let newReasons = reasons.filter((item) => item.key !== editReason.key);
    newReasons.push(editReason);
    dispatch(addReasonsAction(newReasons));
    setUpdate(true);
  };

  const handleDeleteReason = (deletedReason) => {
    let newReasons = reasons.filter((item) => item.key !== deletedReason.key);
    dispatch(addReasonsAction(newReasons));
    setUpdate(true);
  };

  if (reasons.length === 0) {
    return null;
  }

  if (update) {
    setTimeout(() => {
      setUpdate(false);
    }, 500);
    return (
      <Grid container xs={12} justify="center">
        <Grid item xs={12} align="center">
          <CircularProgress size="30px" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container xs={12} justify="center" className={classesView.root}>
      <TableSimple
        columns={columns}
        data={reasons}
        title=""
        
      />
    </Grid>
  );
};

export default AllReasons;
