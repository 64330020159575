import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { palette } from "configurations/styles/theme";
import {
  AnaliticNotifications,
  EnumErrorsNotifications,
} from "widgets/Notifications";
import Alert from "widgets/alert";

const useStyles = makeStyles((theme) => ({
  item: {
    borderColor: palette.error.main,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
  },
}));

const getInstructions = (keyword) => {
  const Instructions = AnaliticNotifications.GetInstructionsErrors(keyword);
  if (typeof Instructions === "function") {
    return <Instructions />;
  }
  return null;
};


const ItemErrors = ({ error = {} }) => {
  const classes = useStyles();
  return (
    <Grid container xs={12} className={classes.item}>
      <Alert
        titulo={null}
        cerrarAlerta={() => {}}
        mensaje={error.msg}
        mesajeSubrayado={getInstructions(error.keyword)}
        tipo="error"
        mostrar={true}
      />
    </Grid>
  );
};

export default ItemErrors;
