import React, { Fragment, useState } from "react";
import {
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  makeStyles,
  TextField,
  FormControl,
  NativeSelect,
  withStyles,
  InputBase,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useStylesGlobal, palette } from "configurations/styles/theme";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useStylesApp } from "hooks/useStyles";
import InputMask from "react-input-mask";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { useSnackbar } from "notistack";
import bigDecimal from "js-big-decimal";
import { typeInvoice, typeSupport } from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";
import checkInputs from "utils/checkInputs";
import EditTableImpuestoRetencions from "widgets/Tables/EditTableImpuestoRetencions";
import EditTableImpuestos from "widgets/Tables/EditTableImpuesto";
import EditTablePagos from "widgets/Tables/EditTablePagos";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "0.5%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const EditTaxBtn = ({
  data, //Only the row data
  docsSustento,
  setDocsSustento,
  docSustentoDetail,
  setDocSustentoDetail,
  viewDetalle,
  setViewDetalle,
}) => {
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [dataDocSustento, setDataDocSustento] = useState(data);
  const [docSustento, setDocSustento] = useState(data.codigoDocSustento);
  const minDate = new Date();
  const minDate1 = minDate.setDate(minDate.getDate() - 30);
  // const [minDate, setMinDate] = useState(new Date());
  // const [minDate1, setMinDate1] = useState(
  //   minDate.setDate(minDate.getDate() - 30)
  // );
  const [dataAditional, setDataAditional] = useState(data.impuestoRetencions);

  const [dataAditionalImpuestos, setDataAditionalImpuestos] = useState(
    data.impuestos
  );

  const [dataAditionalPagos, setDataAditionalPagos] = useState(data.pagos);

  const [empty, setEmty] = useState({
    documento: true,
    numero: true,
    fecha: true,
  });

  const handleClose = () => {
    console.log("cerré la vaina");
    setOpen(false);
  };

  const handleTable = () => {
    console.log("abrí la vaina");
    setOpen(true);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "numeroDocSustento":
        event.preventDefault();
        setDataDocSustento({
          ...dataDocSustento,
          ["numeroDocSustento"]: event.target.value,
        });
        break;
      case "codigoDocSustento":
        event.preventDefault();
        setDataDocSustento({
          ...dataDocSustento,
          ["numeroDocSustento"]: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erroneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleUpdate = () => {

    if (validateDocSustento()) {
      // docSustento is the current register
      // array is the set of register
      docSustentoDetail.map((ds, index, array) => {
        if (compareObject(data, ds)) {
          try {
            const aux = [ds];
            docsSustento.splice(index, 1, {
              ...ds,
              codigoDocSustento: dataDocSustento.codigoDocSustento,
              fechaEmisionDocSustento: dataDocSustento.fechaEmisionDocSustento,
              numeroDocSustento: dataDocSustento.numeroDocSustento,
              codSustento: dataDocSustento.codSustento,
            });

            docsSustento[index].pagos = dataAditionalPagos;
            docsSustento[index].impuestoRetencions = dataAditional;
            docsSustento[index].impuestos = dataAditionalImpuestos;
            docSustentoDetail.splice(index, 1, dataDocSustento);
            docSustentoDetail[index].pagos = dataAditionalPagos;
            docSustentoDetail[index].impuestoRetencions = dataAditional;
            docSustentoDetail[index].impuestos = dataAditionalImpuestos;

            //detalles.splice(index, 1, updateDetalle);
            enqueueSnackbar(`Impuesto editado`, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              autoHideDuration: 4000,
            });
            // setDocsSustento(aux);
            if (viewDetalle === 1) {
              setViewDetalle(2);
            } else {
              setViewDetalle(1);
            }
            handleClose();
          } catch (error) {
            console.log(error);
          }
          // docSustento.splice(index,1,{
          //   codigoDocSustento: dataDocSustento.codigoDocSustento,
          //   fechaEmisionDocSustento: dataDocSustento.fechaEmisionDocSustento,
          //   numeroDocSustento: dataDocSustento.numeroDocSustento,
          //   codSustento: dataDocSustento.codSustento,
          // })
          // console.log(ds);
        }
      });
    }
    // if (validateDocSustento) {
    //   docSustentoDetail.map(function updateProducts(docSustento, index, array) {
    //     if (compareObject(data, docSustento)) {
    //       docsSustento.splice(index, 1, {
    //         codigoDocSustento: dataDocSustento.codigoDocSustento,
    //         fechaEmisionDocSustento: dataDocSustento.fechaEmisionDocSustento,
    //         numeroDocSustento: dataDocSustento.numeroDocSustento,
    //         codSustento: dataDocSustento.codSustento,
    //       });
    //       docSustentoDetail.splice(index, 1, dataDocSustento);
    //       //detalles.splice(index, 1, updateDetalle);
    //       enqueueSnackbar(`Impuesto editado`, {
    //         variant: "success",
    //         anchorOrigin: {
    //           vertical: "top",
    //           horizontal: "right",
    //         },
    //         autoHideDuration: 4000,
    //       });
    //     }
    //   });

    //   if (viewDetalle === 1) {
    //     setViewDetalle(2);
    //   } else {
    //     setViewDetalle(1);
    //   }
    //   handleClose();
    // }
  };

  const compareObject = (data, tax) => {
    var aKeys = Object.keys(tax).sort();
    var bKeys = Object.keys(tax).sort();
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    if (aKeys.join("") !== bKeys.join("")) {
      return false;
    }
    for (var i = 0; i < aKeys.length; i++) {
      if (data[aKeys[i]] !== tax[bKeys[i]]) {
        return false;
      }
    }
    return true;
  };

  const validateDocSustento = () => {
    let state = true;
    /* if (typeof tax === 'undefined' || tax === '') {
             setEmty({ ...empty, ['impuesto']: false })
             return false;
         } else if (typeof dataTax.retention === 'undefined' || dataTax.retention === '') {
             setEmty({ ...empty, ['retencion']: false })
             return false;
         } else*/

    if (
      typeof dataDocSustento.codigoDocSustento === "undefined" ||
      dataDocSustento.codigoDocSustento.toString().trim() === ""
    ) {
      setEmty({ ...empty, ["documento"]: false });
      return false;
    } else if (
      typeof dataDocSustento.codigoDocSustento !== "undefined" &&
      dataDocSustento.codigoDocSustento.trim() !== "" &&
      dataDocSustento.codigoDocSustento !== "12"
    ) {
      if (
        typeof dataDocSustento.numeroDocSustento === "undefined" ||
        dataDocSustento.numeroDocSustento.trim() === "" ||
        dataDocSustento.numeroDocSustento.replace(/\D/g, "").length < 15
      ) {
        setEmty({ ...empty, ["numero"]: false });
        return false;
      } else {
        return true;
      }
    } else if (
      typeof dataDocSustento.fechaEmisionDocSustento === "undefined" ||
      dataDocSustento.fechaEmisionDocSustento === ""
    ) {
      setEmty({ ...empty, ["fecha"]: false });
      return false;
    } else {
      return state;
    }
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => handleTable()}
      >
        <EditIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Editar Documento Sustento
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {" "}
                <Grid item xs={12} md={12}>
                  <label>
                    Los campos marcados con ({" "}
                    <font color={palette.error.main}> *</font> ) son
                    obligatorios:
                  </label>
                </Grid>
                <Grid item xs={12} md={4} className={classes.divForm}>
                  <label>
                    Código documento Sustento:{" "}
                    <font color={palette.error.main}>*</font>
                  </label>
                </Grid>
                <Grid item xs={12} md={8} className={classes.divForm}>
                  <FormControl className={classesGlobales.select}>
                    <NativeSelect
                      value={dataDocSustento.codigoDocSustento}
                      onChange={(event) => {
                        if (event.target.value.trim() === "12") {
                          setEmty({ ...empty, ["numero"]: true });
                        }
                        setDataDocSustento({
                          ...dataDocSustento,
                          ["codigoDocSustento"]: event.target.value
                            ? event.target.value
                            : "",
                        });
                      }}
                      input={<BootstrapInput />}
                    >
                      <option aria-label="None" value="">
                        --Seleccione--
                      </option>
                      {typeInvoice.map((voucher) => (
                        <option key={voucher.CODIGO} value={voucher.CODIGO}>
                          {`${voucher.CODIGO} - ${voucher.NOMBRE}`}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>

                  {/*<Autocomplete
                                    id="combo-box-demo"
                                    size='medium'
                                    //inputValue={`${docSustento.CODIGO}-${docSustento.NOMBRE}`}
                                    value={docSustento}
                                    options={typeInvoice}
                                    onChange={(event, newValue) => {
                                        setDocSustento(newValue);
                                        setDataTax({ ...dataTax, ['codigoDocSustento']: newValue ? newValue.CODIGO ? newValue.CODIGO : '' : '' })
                                    }}
                                    getOptionLabel={(option) => `${option.CODIGO}-${option.NOMBRE}`}
                                renderInput={(params) => <TextField {...params} className='form-control' variant="outlined" placeholder="Seleccione" />} />*/}
                  {empty.documento === true ? null : (
                    <p className={classesApp.errorLetters}>
                      Seleccione el tipo de documento sustento
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={4} className={classes.divForm}>
                  <label>
                    Número Doc. Sustento:{" "}
                    <font color={palette.error.main}>*</font>
                  </label>
                </Grid>
                <Grid
                  fullWidth
                  item
                  xs={12}
                  md={8}
                  className={classes.divInput}
                >
                  <InputMask
                    className={
                      dataDocSustento.numeroDocSustento !== ""
                        ? "form-control  is-valid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Ej. 001-001-000000321"
                    mask="999-999-999999999"
                    name="numeroDocSustento"
                    value={dataDocSustento.numeroDocSustento}
                    onChange={handleChange}
                    autoComplete="off"
                  />

                  {empty.numero === true ? null : (
                    <p className={classesApp.errorLetters}>
                      Ingrese el número de documento sustento válido
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={4} className={classes.divForm}>
                  <label>
                    Fecha Emisión Doc. Sustento:{" "}
                    <font color={palette.error.main}>*</font>
                  </label>
                </Grid>
                <Grid item xs={12} md={8} className={classes.divInput}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <DatePicker
                      className={
                        dataDocSustento.fechaEmisionDocSustento
                          ? "form-control  is-valid"
                          : "form-control "
                      }
                      disableToolbar
                      autoOk
                      disableFuture
                      minDate={minDate1}
                      InputAdornmentProps={{ position: "start" }}
                      variant="dialog"
                      inputVariant="outlined"
                      cancelLabel="Cancelar"
                      okLabel="Seleccionar"
                      invalidDateMessage="Formato inválido."
                      format="dd-MM-yyyy"
                      name="fechaEmisionDocSustento"
                      value={dataDocSustento.fechaEmisionDocSustento}
                      onChange={(event) =>
                        setDataDocSustento({
                          ...dataDocSustento,
                          ["fechaEmisionDocSustento"]: event,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                  {empty.fecha === true ? null : (
                    <p className={classesApp.errorLetters}>
                      seleccione la fecha
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={4} className={classes.divForm}>
                  <label>
                    Tipo documento sustento:{" "}
                    <font color={palette.error.main}>*</font>
                  </label>
                </Grid>
                <Grid item xs={12} md={8} className={classes.divForm}>
                  <FormControl className={classesGlobales.select}>
                    <NativeSelect
                      value={dataDocSustento.codSustento}
                      onChange={(event) => {
                        if (event.target.value.trim() === "12") {
                          setEmty({ ...empty, ["numero"]: true });
                        }
                        setDataDocSustento({
                          ...dataDocSustento,
                          ["codSustento"]: event.target.value
                            ? event.target.value
                            : "",
                        });
                      }}
                      input={<BootstrapInput />}
                    >
                      <option aria-label="None" value="">
                        --Seleccione--
                      </option>
                      {typeSupport.map((voucher) => (
                        <option key={voucher.CODIGO} value={voucher.CODIGO}>
                          {`${voucher.CODIGO} - ${voucher.NOMBRE}`}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>

                  {empty.documento === true ? null : (
                    <p className={classesApp.errorLetters}>
                      Seleccione el tipo de documento sustento
                    </p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                xs={12}
                md={12}
                justifyContent="center"
                alignItems="center"
              >
                <EditTableImpuestoRetencions
                  key={dataDocSustento.codigoDocSustento}
                  dataAditional={dataAditional}
                  setDataAditional={setDataAditional}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                xs={12}
                md={12}
                justifyContent="center"
                alignItems="center"
              >
                <EditTableImpuestos
                  key={dataDocSustento.codigoDocSustento}
                  dataAditionalImpuestos={dataAditionalImpuestos}
                  setDataAditionalImpuestos={setDataAditionalImpuestos}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                xs={12}
                md={12}
                justifyContent="center"
                alignItems="center"
              >
                <EditTablePagos
                  key={dataDocSustento.codigoDocSustento}
                  dataAditionalPagos={dataAditionalPagos}
                  setDataAditionalPagos={setDataAditionalPagos}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.divButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                className={classesGlobales.button}
              >
                Actualizar
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={classesGlobales.button}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default EditTaxBtn;
