import {
  CANCEL_VOUCHER,
  CANCEL_VOUCHER_SECCESS,
  CANCEL_VOUCHER_ERROR,
  CANCEL_VOUCHER_CLEAR,
  START_DOWNLOAD_CVOUCHERS,
  DOWNLOAD_CVOUCHER_SUCCESS,
  DOWNLOAD_CVOUCHER_ERROR,
} from "../types/voucherTypes";

import clientBackend, { httpRepo } from "configurations/axios";
/**
 * Método para anular un comprobante
 * @param {idComprobante} idComprobante
 */
export function cancelVoucherAction(idComprobante) {
  return async (dispatch) => {
    //cancela el voucher
    dispatch(addCancelVoucher());
    //Lllamo api para cancelar comprobante
    await httpRepo
      .put(`/comprobantes/anulados/anular-comprobante/${idComprobante}`)
      .then((response) => {
        if (response.status === 200 && response.status !== "") {
          //agrega el voucher cancelado al state
          dispatch(voucherSuccessCanceled(response.data));
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (
            typeof exception.response.data === "object" &&
            exception.response.data !== null
          ) {
            if (
              typeof exception.response.data.apierror === "object" &&
              exception.response.data.apierror !== null
            ) {
              console.error(
                "error 1",
                exception.response.data.apierror.mensaje
              );
            }
          }
        } else {
          console.error("error 2", exception);
        }
        dispatch(addCancelVoucherError(true));
      });
  };
}

const addCancelVoucher = () => ({
  type: CANCEL_VOUCHER,
  payload: true,
});

const voucherSuccessCanceled = (comprobante) => ({
  type: CANCEL_VOUCHER_SECCESS,
  payload: comprobante,
});

const addCancelVoucherError = (estado) => ({
  type: CANCEL_VOUCHER_ERROR,
  payload: estado,
});

//Función para descargar productos
export function getCancelVouchersAction(requestConfig) {
  return async (dispatch) => {
    await clientBackend
      .get("/comprobantes-anulados")
      .then((response) => {
        if (response.status === 200) {
          //obteniendo los vouchers
          dispatch(getCancelVoucher());

          //agregar los vouchers al state
          dispatch(getCancelVoucherSuccess(response.data));
        }
      })
      .catch((exception) => {
        if (exception.response) {
          console.error("error 1", exception.response.data.apierror.mensaje);
        } else {
          console.error("error 2", exception);
        }
        getCancelVoucherError(true);
      });
  };
}
const getCancelVoucher = () => ({
  type: START_DOWNLOAD_CVOUCHERS,
  payload: true,
});

const getCancelVoucherSuccess = (comprobantes) => ({
  type: DOWNLOAD_CVOUCHER_SUCCESS,
  payload: comprobantes,
});

const getCancelVoucherError = (estado) => ({
  type: DOWNLOAD_CVOUCHER_ERROR,
  pauload: estado,
});

export const clearCancelVoucherAction = () => ({
  type: CANCEL_VOUCHER_CLEAR,
});
