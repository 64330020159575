import React, { useState } from "react";
import {
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import tableStyle from "configurations/styles/table";
import { httpRepo } from "configurations/axios";
import TablePaginatedClickOnRow from "widgets/Tables/TablePaginatedClickOnRow";
import { calculateTotal } from "utils/checkTaxes";
import apis from "../apis";
import { httpCore } from "configurations/axios";
import DateProvaider from "providers/DateProvaider";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Alert from "widgets/alert";
//redux
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import {
  recoveryAllDataAction,
  clearCreditNoteAction,
} from "redux/actions/CreditNoteActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "10px",
    paddingTop: "0px",
  },
  container: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "8px",
    marginTop: "0.5%",
  },
  content: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
  containerBtn: {
    padding: "2%",
  },
}));

const RecoverInvoice = () => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadVoucher, setIsLoadVoucher] = useState("N");
  const [showLoader, setShowLoader] = useState(false);
  const tokenUser = useSelector((state) => state.user.tk);
  const [voucherSelected, setVoucherSelected] = useState(null);
  const [selectedDate, setDate] = useState(null);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [numDoc, setNumDoc] = useState(null);
  const [isSearch, setIsSearchin] = useState(false);
  const dispatch = useDispatch();
  const clearCreditNote = () => dispatch(clearCreditNoteAction());
  const recoveryAllData = (data) => dispatch(recoveryAllDataAction(data));
  function closeAlert() {
    setMsg({ show: false });
  }

  const handleDateChange = (value) => {
    if (value) {
      const date = new Date(value);
      setDate(date);
    }
  };

  const columns = [
    {
      title: "Número",
      cellStyle: tableStyle.cellLeftSmall,
      width: "7%",
      render: (rowData) =>
        rowData.comprobanteElectronico.codigoEstablecimiento +
        "-" +
        rowData.comprobanteElectronico.puntoEmision +
        "-" +
        rowData.comprobanteElectronico.secuencial,
    },
    {
      title: "Fecha Emisión	",
      cellStyle: tableStyle.cellTable,
      width: "6%",
      render: (rowData) =>
        rowData.comprobanteElectronico.fechaEmision.substring(0, 10),
    },
    {
      title: "Fecha Autorización",
      cellStyle: tableStyle.cellTable,
      width: "6%",
      render: (rowData) =>
        rowData.comprobanteElectronico.fechaAutorizacion
          ? rowData.comprobanteElectronico.fechaAutorizacion.substring(0, 10)
          : "",
    },
    {
      title: "Receptor",
      field: "correoReceptor",
      cellStyle: tableStyle.cellLeft,
      width: "25%",
      render: (rowData) =>
        rowData.receptor ? rowData.receptor.razonSocial : "Consumidor Final",
    },
    {
      title: "Tipo",
      field:
        "comprobanteElectronico.tipoComprobanteElectronico.nombreComprobante",
      cellStyle: tableStyle.cellTable,
      width: "5%",
    },
  ];

  const buildDate = () => {
    if (selectedDate) {
      return (
        selectedDate.getFullYear() +
        "-" +
        (selectedDate.getMonth() + 1) +
        "-" +
        selectedDate.getDate()
      );
    } else {
      return "";
    }
  };

  const requestConfig = {
    uri:
      apis.get_vouchers_auth +
      (tokenUser ? getRUCEmpresa(tokenUser) : "0") +
      "?secuencial=" +
      (numDoc ? numDoc : "0") +
      "&codigoComprobante=01&fechaInicio=" +
      buildDate() +
      "&fechaFin=" +
      buildDate() +
      "&",
    metodo: "get",
    body: null,
    page: 0,
    elementos: 15,
    sort: "idAsignacionComprobanteElectronico,desc",
  };

  const handleIsEqual = (row, selectedRow) => {
    setVoucherSelected(selectedRow);
    if (
      row.idAsignacionComprobanteElectronico ===
      selectedRow.idAsignacionComprobanteElectronico
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event) => {
    setIsLoadVoucher(event.target.value);
    if (event.target.value === "S") {
      setOpenDialog(true);
      setVoucherSelected(null);
      setMsg({ show: false, msg: null, type: null });
      setNumDoc(null);
      handleDateChange(null);
      setIsSearchin(false);
    } else if (event.target.value === "N") {
      clearCreditNote();
    }
  };

  const handleClose = () => {
    setIsLoadVoucher("N");
    setOpenDialog(false);
  };

  const renderTable = () => {
    if (showLoader) {
      return <CircularProgress color="primary" />;
    } else {
      return (
        <TablePaginatedClickOnRow
          title=""
          columns={columns}
          requestConfig={requestConfig}
          Middleware={httpRepo}
          isEquals={handleIsEqual}
        />
      );
    }
  };

  const handleSubmit = async () => {
    if (tokenUser) {
      if (voucherSelected.idAsignacionComprobanteElectronico) {
        setShowLoader(true);
        await httpCore
          .post(apis.post_find_invoice, {
            idAsigancionEmpresaUsuario: getSub(tokenUser),
            idComprobanteElectronico: voucherSelected.comprobanteElectronico
              .idComprobanteElectronico
              ? voucherSelected.comprobanteElectronico.idComprobanteElectronico.toString()
              : "0",
            rucEmpresa: getRUCEmpresa(tokenUser),
          })
          .then((response) => {
            let detalles = [];
            if (typeof response.data === "object") {
              if (Array.isArray(response.data.detalles)) {
                detalles = response.data.detalles;
                detalles = detalles.map((item) => {
                  item.precioProducto = item.precioUnitario;
                  item.isDescuentoPorcentaje = false;                  
                  return item;
                });
              }
            }

            recoveryAllData({
              ...response.data,
              detalles,
              recoveryData: true,
              totalRecoveryInvoice: calculateTotal(detalles),
              formCostumer: {
                nombresCliente: response.data.nombresCliente,
                telefonoCliente: response.data.telefonoCliente,
                correoCliente: response.data.correoCliente,
                direccionCliente: response.data.direccionCliente,
              },
              numAuxDocSustento: response.data.numeroDocSustento,
              infromacionAdicional: [
                { isEditable: false, nombre: "CORREO", valor: response.data.correoCliente, isDeletable: false, key: "-1" },
              ],
              errors: {
                fechaDocSustento: null,
                fechaEmision: null,
                motivo: null,
                numeroDocSustento: null,
                rise: null,
              },
            });
            setShowLoader(false);
            setOpenDialog(false);
          })
          .catch((exception) => {
            if (exception.response) {
              if (exception.response.status === 400) {
                setMsg({
                  show: true,
                  msg: "El comprobante no es una factura.",
                  type: "error",
                });
              } else if (exception.response.status === 404) {
                setMsg({
                  show: true,
                  msg:
                    "No se encontró el cliente o los productos de la factura. Contáctese con el administrador del Sistema.",
                  type: "error",
                });
              } else {
                setMsg({
                  show: true,
                  msg:
                    "Error recuperar la información del comprobante. Contáctese con el administrador del Sistema.",
                });
              }
            } else {
              setMsg({
                show: true,
                msg:
                  "Existen problemas en la red. Contáctese con el administrador del Sistema.",
              });
            }
            setShowLoader(false);
          });
      } else {
        setMsg({ show: true, msg: "Seleccioné una factura.", type: "error" });
      }
    } else {
      setMsg({ show: true, msg: "Su sesión expiro.", type: "error" });
    }
  };

  const handleClickSearch = (event) => {
    event.preventDefault();
    setShowLoader(true);
    setIsSearchin(true);
    setTimeout(() => setShowLoader(false), 1000);
  };

  const handleShowAll = (event) => {
    event.preventDefault();
    setIsSearchin(false);
    setNumDoc(null);
    setDate(null);
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);
  };

  return (
    <>
      <div className={classesGlobales.rootDiv}>
        <div class="row pl-2 justify-content-md-center">
          <div class="col-sm-7 pt-2">
            <label>
              ¿Desea cargar la información del documento de sustento desde un
              comprobante almacenado en el sistema?
            </label>
          </div>
          <div class="col-sm-2">
            <FormControl component="fieldset" className={"formControl"}>
              <RadioGroup
                aria-label="TipoCliente"
                name="typeCliente"
                className={classes.radio}
                required
                value={isLoadVoucher}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="S"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Si"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="No"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
      <Dialog fullWidth maxWidth="xl" open={openDialog} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">
          Cargar una factura del sistema
        </DialogTitle>
        <DialogContent className={classes.content}>
          <form onSubmit={handleClickSearch}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={3} md={3}>
                <label>Buscar factura según su fecha de emisión:</label>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <DateProvaider>
                  <KeyboardDatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="dd/MM/yyyy"
                    fullWidth
                    autoOk
                    variant="dialog"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    disableFuture
                    cancelLabel="Cancelar"
                    okLabel="Seleccionar"
                    todayLabel="Hoy"
                    invalidDateMessage="Formato inválido."
                  />
                </DateProvaider>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                {isSearch ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleShowAll}
                  >
                    Mostrar Todas
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              {msgError.show ? (
                <Alert
                  tipo={msgError.type}
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} md={12}>
              {renderTable()}
            </Grid>
            <Grid
              container
              xs={12}
              justify="center"
              spacing={2}
              className={classes.containerBtn}
            >
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Seleccionar
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default RecoverInvoice;
