import {
    START_AUTHORIZED_VOUCHERS,
    AUTHORIZED_VOUCHERS_SECCESS,
    AUTHORIZED_VOUCHERS_ERROR,
    CLEAR_AUTHORIZED_VOUCHERS
} from '../types/voucherTypes';
import  {httpRepo}  from 'configurations/axios';
import { getRUCEmpresa } from './userActions';



export function getAuthorizedVouchersAction(requestConfig) {
    return async (dispatch) => {
        //extaer vouchers  ?page=numeroPagina&size=numeroFilas&sort= atributoAOrdenar,desc 
        
        dispatch(getAuthorizedVoucher());
        await httpRepo.get(`${requestConfig.uri}${getRUCEmpresa(requestConfig.rucEmpresa)}?page=${requestConfig.page}&size=${requestConfig.elementos}`,
            ).then(
                response => {
                    if (response.status <= 201) {                        
                        //agrega el voucher  al state
                        dispatch(getAuthorizedVoucherSuccess(response.data.content));
                    }
                }
            ).catch(exception => {
                if (exception.response) {
                    console.error('error 1', exception.response.data.apierror.mensaje);
                } else {
                    console.error('error 2', exception);
                }
                dispatch(authorizedVoucherError(true));
            }
            );

    }

}
const getAuthorizedVoucher=()=>({
    type:START_AUTHORIZED_VOUCHERS,
    payload: true
});

const getAuthorizedVoucherSuccess=(comprobantes)=>({
    type:AUTHORIZED_VOUCHERS_SECCESS,
    payload: comprobantes

})

const authorizedVoucherError=(estado)=>({
    type:AUTHORIZED_VOUCHERS_ERROR,
    payload: estado
})

export const clearAuthorizedVoucherAction = () => ({
    type: CLEAR_AUTHORIZED_VOUCHERS
})