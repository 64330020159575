import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { palette } from "configurations/styles/theme";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  btnContainer: {
      marginBottom: "1%"
  }
}));

const ShowMoreInfo = ({ product }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const moreInfo = product.informacionAdicional
    ? product.informacionAdicional
    : [];

  return (
    <>
      <IconButton onClick={handleClickOpen} className={classes.IconButton}>
        <AddBoxIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Detalles Adicionales</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label>Detalles Adicionales</label>
            </Grid>
            <Grid item xs={12}>
              <table className="table table-sm  table-bordered ">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {moreInfo.map((item, index) => (
                    <tr key={index}>
                      <td>{item.nombre}</td>
                      <td>{item.valor}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
            <Grid container xs={12} justify="center" className={classes.btnContainer}>
              <Grid item xs={12} sm={3} md={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShowMoreInfo;
