import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AuthorizedIssuedVouchersProvaider } from "./Context";
import TableVoucherAuthorized from "./Table";
import Filters from "./filters";

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '50px',
        paddingBottom: '50px',
    },
}));


const VoucherAuthorized = () => {

    const classesView = useStyles();
    return (
        <AuthorizedIssuedVouchersProvaider>
            <div className={classesView.root}>
                <Filters />
                <TableVoucherAuthorized />
            </div>
        </AuthorizedIssuedVouchersProvaider>
    );
}
export default VoucherAuthorized;