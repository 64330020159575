import React, { Fragment } from "react";
import { palette } from "configurations/styles/theme";

const TextRequired = ({ text }) => {
  return (
    <Fragment>
      <label>
        {text + ": "}
        <font color={palette.error.main}>*</font>
      </label>
    </Fragment>
  );
};

export default TextRequired;
