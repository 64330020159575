const ACTIONS = {
    ADD_DETAIL: "ADD_DETAIL",
    ADD_ONE_DETAIL: "ADD_ONE_DETAIL_CREDIT_NOTE",
    ADD_DOC: "ADD_DOC",
    ADD_REASON: "ADD_REASON",
    ADD_RISE: "ADD_RISE",
    ADD_RUC: "ADD_RUC",
    ADD_MORE_INFO: "ADD_MORE_INFO_CREDIT_NOTE",
    ADD_USER: "ADD_USER",
    ADD_COSTUMER: "ADD_COSTUMER",
    SAVE_CACHE: "SAVE_CACHE",
    CHANGE_DATE_EMISION:  "CHANGE_DATE_EMISION",
    CHANGE_DATE_AUTH: "CHANGE_DATE_AUTH",
    CHANGE_NUM_DOC: "CHANGE_NUM_DOC",
    CHANGE_ERRORS_LABELS: "CHANGE_ERRORS_LABELS_CREDIT_NOTE",
    CLEAR_ALL: "CLEAR_ALL",
    CLEAR_FORM_COSTUMER: "CLEAR_FORM_COSTUMER",
    RECOVERY_ALL_DATA: "RECOVERY_ALL_DATA",
    CHANGE_ERRORS_LABELS_GUIDE: "CHANGE_ERRORS_LABELS_GUIDE",
    ADD_DIR:"ADD_DIR",
    ADD_PLACA:"ADD_PLACA"
};

export default ACTIONS;