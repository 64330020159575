import React, { useState, Fragment } from 'react';
import { Radio, makeStyles, RadioGroup, FormControl, FormControlLabel, Grid, TextField, CircularProgress, CardHeader } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { getRUCEmpresa } from 'redux/actions/userActions';
import clientBackend from 'configurations/axios';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddProviderBtn from 'views/administración/providers/Buttons/AddProvider';


const useStyles = makeStyles((theme) => ({
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
}));


const ClientData1 = ({ setIdAsgCliente, setFoundClient, foundClient, setOptions, options }) => {

    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const userToken = useSelector(state => state.user.tk);
    //Hooks para obtener los clientes
    //const [options, setOptions] = React.useState([]);
    //const [foundClient, setFoundClient] = useState({});
    const [open, setOpen] = React.useState(false);
    const loading = open && options.length === 0;
    //Para saber si es con datos o consumidor final
    const [isData, setIsData] = useState('D');
    const [existClient, setExistClient] = useState(false);

    //Effect para obtener los clientes
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    //método para la busqueda
    const searchClients = async (params) => {

        await clientBackend.get(`/admin/proveedores/buscar/activos/${getRUCEmpresa(userToken)}?parametroBusqueda=${params}`)
            .then(response => {
                if (response.status === 200) {
                    if (Array.isArray(response.data)) {
                        if (response.data.length > 0) {

                            //setOptions(Object.keys(response.data).map((cliente) => response.data[cliente].cliente));
                            setOptions(
                                response.data.map(function (data, index, array) {
                                    return {
                                        idCliente: data.cliente.idCliente,
                                        nombres: data.nombres,
                                        correo: data.correo,
                                        direccion: data.direccion,
                                        idAsignacionEmpresaCliente: data.idAsignacionEmpresaCliente,
                                        telefono: data.telefono
                                    }
                                }))
                            setExistClient(false)
                        } else {
                            setOptions([
                                {
                                    idCliente: "",
                                    nombres: "",
                                    correo: "No se encontró datos con el argumento ingresado",
                                },
                            ]);
                            setExistClient(true)
                        }
                    }

                    setOpen(true);
                }
            }
            ).catch(exception => {
                console.log({ exception });
                if (exception.response) {
                    setOptions([
                        {
                            idCliente: "",
                            nombres: "",
                            correo: "No se encontró datos con el argumento ingresado",
                        },
                    ]);
                } else {
                    setOptions([
                        {
                            idCliente: "",
                            nombres: "",
                            correo: `Error en la red al consultar el proeveedor.`,
                        },
                    ]);
                }
            }
            );
    }

    const handleChangeCheck = event => {
        if (event.target.value === 'F') {
            setIdAsgCliente({ idAsignacionEmpresaCliente: 0 });
        } else {
            setIdAsgCliente({});
        }
        setIsData(event.target.value);

    }

    const updateView = () => {

    }


    return (
        <Fragment >
            <div className={classesGlobales.rootDiv} >
                <Grid container xs={12} md={12}
                    justifyContent='center'
                >
                    <CardHeader
                        className={classesGlobales.cabecera}
                        title={'Datos del Proveedor'}
                        avatar={<AssignmentIcon></AssignmentIcon>}
                    />
                </Grid>
                <Grid container spacing={1} className={classesGlobales.containerForm} >
                    { /*<Grid container justify='center'>
                        <FormControl component="fieldset" >
                            <RadioGroup aria-label="datosCliente"
                                name="isData"
                                value={isData}
                                className={classes.radio} required
                                onChange={handleChangeCheck}
                            >
                                <FormControlLabel
                                    value="F"
                                    control={<Radio color="primary" className={classes.radioButton} />}
                                    label="Consumidor final"
                                    labelPlacement="start"
                                    className={classes.radioLable}
                                />
                                <FormControlLabel
                                    value="D"
                                    control={<Radio color="primary" className={classes.radioButton} />}
                                    label="Datos"
                                    labelPlacement="start"
                                    className={classes.radioLable}
                                />
                            </RadioGroup>
                        </FormControl>
    </Grid>*/}
                    {isData === 'D' ?
                        <Fragment>
                            <Grid item xs={12} md={2} className={classes.divForm} justifyContent="flex-start">
                                <label>CI / RUC / Pasaporte: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divInput} >
                                <Autocomplete
                                    id="rucCliente"
                                    onChange={(e, value) => {
                                        setFoundClient(value);
                                        if (value !== null) {
                                            setIdAsgCliente(value);
                                        } else {
                                            setIdAsgCliente({});
                                        }

                                    }}
                                    open={open}
                                    onOpen={() => {

                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    getOptionDisabled={(option) =>
                                        option.correo.includes(
                                            "No se encontró datos con el argumento ingresado"
                                        ) ||
                                        option.correo.includes(
                                            "Error en la red al consultar el proeveedor."
                                        )
                                    }
                                    getOptionSelected={(option, value) => option.idCliente === value.idCliente}
                                    getOptionLabel={(option) => `${option.idCliente} - ${option.nombres} - ${option.correo}`}
                                    options={options}
                                    noOptionsText={
                                        "No se encontró datos con el argumento ingresado"
                                    }
                                    loadingText="Ingrese al menos 3 caracteres"
                                    loading={options.length === 0}
                                    renderInput={(params) => (
                                        <TextField
                                            className={foundClient ? foundClient.idCliente ? "form-control  is-valid" : null : null}
                                            onChange={(event) => {
                                                if (event.target.value.length >= 2) {
                                                    searchClients(event.target.value)
                                                }
                                            }
                                            }
                                            {...params}
                                            label=""
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {existClient ?
                                <Grid item xs={12} md={6}  >
                                    <AddProviderBtn updateView={updateView} />
                                </Grid> :
                                null

                            }

                            <Grid item xs={12} md={2} className={classes.divForm}>
                                <label>Teléfono: </label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divInput}>
                                <input className={foundClient ? foundClient.telefono ? "form-control  is-valid" : "form-control " : "form-control"}
                                    type="text"
                                    disabled
                                    name="telefonoCliente"
                                    value={foundClient ? foundClient.telefono ? foundClient.telefono : '' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} className={classes.divForm}>
                                <label>Razón Social / Nombres: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divInput}>
                                <input className={foundClient ? foundClient.nombres ? "form-control  is-valid" : "form-control " : "form-control "}
                                    type="text"
                                    disabled
                                    name="nombres"
                                    value={foundClient ? foundClient.nombres ? foundClient.nombres : '' : ''}
                                />
                            </Grid>

                            <Grid item xs={12} md={2} className={classes.divForm}>
                                <label>Dirección:</label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divInput}>
                                <input className={foundClient ? foundClient.direccion ? "form-control  is-valid" : "form-control " : "form-control "}
                                    type="text"
                                    disabled
                                    name="direccionCliente"
                                    value={foundClient ? foundClient.direccion ? foundClient.direccion : '' : ''}
                                />
                            </Grid>

                            <Grid item xs={12} md={2} className={classes.divForm}>
                                <label>Correo Principal: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divInput}>
                                <input className={foundClient ? foundClient.correo ? "form-control  is-valid" : "form-control " : "form-control "}
                                    type="text"
                                    disabled
                                    name="emailCliente"
                                    value={foundClient ? foundClient.correo ? foundClient.correo : '' : ''}
                                />
                            </Grid>

                        </Fragment> :
                        null

                    }
                </Grid>

            </div>
        </Fragment>
    );
}

export default ClientData1;