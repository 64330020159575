import React, { Fragment, useState } from "react";
import {
  IconButton,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
//componentes del sistema
import { API_DELETE_SECUENCIAL_USER } from "../apis";
import { palette } from "configurations/styles/theme";
import clientBackend from "configurations/axios";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  textFieldComboBox: {
    minWidth: "75%",
    maxWidth: "95%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: palette.primary.main,
  },
}));

/**
 * @author {Dany_Lasso}
 * @param {Object} costumer
 */
const EditUserBtn = ({ updateView, data }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const classes = useStyles();
  const { asignacionEmpresaUsuario, secuencial } = data;

  const handleConfirmDelete = () => {
    setOpenLoader(true);
    return new Promise(async (resolve, reject) => {
      await clientBackend
        .get(API_DELETE_SECUENCIAL_USER + data.idAsignacionSecuencialUsuario)
        .then(() => {
          updateView();
          setOpenLoader(false);
          resolve({
            text: `El punto de emisión: ${secuencial.codigoEstablecimiento} - ${secuencial.puntoEmision},
                    ya no le pertenece al usuario: ${asignacionEmpresaUsuario.nombreUsuario} (${asignacionEmpresaUsuario.nombreUsuarioAcceso})
                    `,
            icon: iconsAlert.success,
            show: true,
          });
        })
        .catch((exception) => {
          if (exception.response) {
            if (exception.response.status === 400) {
              reject(
                `No se pudo retirar el punto de emisión: ${secuencial.codigoEstablecimiento} - ${secuencial.puntoEmision}, al usuario: 
                            ${asignacionEmpresaUsuario.nombreUsuario} (${asignacionEmpresaUsuario.nombreUsuarioAcceso}). Contáctese con el administrador del Sistema.`,
                iconsAlert.error
              );
            } else if (exception.response.status === 404) {
              reject(
                `No se encontró la asignación del punto de emisión: ${secuencial.codigoEstablecimiento} - ${secuencial.puntoEmision},
                                al usuario: ${asignacionEmpresaUsuario.nombreUsuario} (${asignacionEmpresaUsuario.nombreUsuarioAcceso}), Contáctese con el administrador del Sistema.
                            `,
                iconsAlert.error
              );
            } else {
              reject(
                `Error al retirar el punto de emisión: ${secuencial.codigoEstablecimiento} - ${secuencial.puntoEmision}, al usuario: 
                ${asignacionEmpresaUsuario.nombreUsuario} (${asignacionEmpresaUsuario.nombreUsuarioAcceso}). Contáctese con el administrador del Sistema.`,
                iconsAlert.error
              );
            }
          } else {
            reject(
              `Existen problemas en la red. Contáctese con el administrador del Sistema.`,
              iconsAlert.error
            );
          }
          setOpenLoader(false);
        });
    });
  };

  const handleClickDelete = () => {
    ConfirmAlert(
      `¿Está seguro de eliminar la asignación del punto de emisión: ${secuencial.codigoEstablecimiento} - ${secuencial.puntoEmision}, 
      al usuario: ${asignacionEmpresaUsuario.nombreUsuario} (${asignacionEmpresaUsuario.nombreUsuarioAcceso})?`,
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      handleConfirmDelete
    );
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickDelete}
        className={classes.IconButton}
      >
        <BackspaceIcon style={{ color: palette.error.main }} />
      </IconButton>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditUserBtn;
