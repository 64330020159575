import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  NativeSelect,
  withStyles,
  InputBase,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useStylesApp } from "hooks/useStyles";
import { valorIva } from "../Forms/typeVoucher";
import { valorICE } from "../Forms/typeVoucher";
import EditTable from "widgets/Tables/EditTable";
import EditIcon from "@material-ui/icons/Edit";
import validations, { styleInput } from "./validations";
import { serviceCalculationValues, serviceUpdateValues } from "./services";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const EditDetailGeneric = ({ onSubmit, productProp }) => {
  //estilos
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();

  //manejo de dialogos
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();

  //gestion datos del formulario
  const [product, setProduct] = useState(productProp);
  //manage form
  const [errors, setErrors] = useState({});
  const [discountCheck, setDiscountCheck] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setProduct(productProp);
    setDiscountCheck(productProp.isDescuentoPorcentaje)
  };

  const handleClose = () => {
    clearData();
    setOpen(false);
  };

  const handleAddProduct = () => {
    const newErrors = validations.submitDetail(product,discountCheck);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (typeof onSubmit === "function") {
        onSubmit(
          serviceUpdateValues(
            product,
            product.codigoPorcentajeIVA,
            product.codigoPorcentajeICE,
            product.informacionAdicional,
            discountCheck
          )
        );
        handleClose();
        enqueueSnackbar(`Detalle editado.`, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 6000,
        });
      }
    }
  };

  const handleChange = (nameInput) => (event, newValue) => {
    const newError = validations.onChange(nameInput, event.target.value);
    validations.onSave({
      error: newError,
      callbackSaveObj: handleSaveProduct,
      nameInput,
      valueInput: event.target.value || newValue,
    });

    setErrors({
      ...errors,
      [nameInput]: newError,
    });
  };

  const handleSaveProduct = (nameInput, valueInput) => {
    if (nameInput !== "codigoPrincipal") {
      setProduct({
        ...product,
        [nameInput]: valueInput,
      });
    } else {
      setProduct(valueInput);
    }
  };

  const handleChangeTaxes = (name) => (event) => {
    setProduct({
      ...product,
      [name]: event.target.value,
    });
  };

  const handleChangeMoreInfo = (moreInfo) => {
    setProduct({
      ...product,
      informacionAdicional: moreInfo,
    });
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const clearData = () => {
    setProduct({});
  };

  const handleChangeRadioDiscount = (event) => {
    setDiscountCheck(event.target.value === "P");
  };

  return (
    <Fragment>
      <Grid container justify="center" className={classes.divButton}>
        <IconButton onClick={handleClickOpen} className={classes.IconButton}>
          <EditIcon style={{ color: palette.info.dark }} />
        </IconButton>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Editar detalle</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              setOpenLoader(true);
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  {msgError.show ? (
                    <Alert
                      tipo={msgError.type}
                      mensaje={msgError.msg}
                      mesajeSubrayado={null}
                      titulo={null}
                      mostrar={msgError.show}
                      cerrarAlerta={closeAlert}
                    />
                  ) : null}
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Codigo Principal:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      type="text"
                      disabled
                      value={product ? product.codigoPrincipal : ""}
                      className={
                        product
                          ? product.codigoPrincipal
                            ? "form-control  is-valid"
                            : "form-control "
                          : "form-control "
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Codigo Auxiliar: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      type="text"
                      disabled
                      name="codigoAuxiliarProducto"
                      value={product ? product.codigoAuxiliar : ""}
                      autoComplete="off"
                      className={
                        product
                          ? product.codigoAuxiliar
                            ? "form-control  is-valid"
                            : "form-control "
                          : "form-control "
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Cantidad: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.cantidadProducto,
                        product.cantidadProducto
                      )}
                      type="text"
                      name="cantidadProducto"
                      value={
                        product.cantidadProducto ? product.cantidadProducto : ""
                      }
                      onChange={handleChange("cantidadProducto")}
                      autoComplete="off"
                    />
                    {errors.cantidadProducto ? (
                      <p className={classesApp.errorLetters}>
                        {errors.cantidadProducto}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Descripción: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      className={styleInput(null, product.descripcion)}
                      type="text"
                      disabled
                      name="descripcionProducto"
                      value={product ? product.descripcion : ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      Precio Unitario: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.precioUnitario,
                        product.precioUnitario
                      )}
                      type="text"
                      placeholder="Ej. 12.00"
                      name="precioProducto"
                      value={product ? product.precioUnitario : ""}
                      onChange={handleChange("precioUnitario")}
                      autoComplete="off"
                    />
                    {errors.precioUnitario ? (
                      <p className={classesApp.errorLetters}>
                        {errors.precioUnitario}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>Descuento: </label>
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.descuentoProducto,
                        product.descuentoProducto
                      )}
                      type="text"
                      placeholder="Ej. 1"
                      name="descuentoProducto"
                      value={product.descuentoProducto}
                      onChange={handleChange("descuentoProducto")}
                      autoComplete="off"
                    />
                    {errors.descuentoProducto ? (
                      <p className={classesApp.errorLetters}>
                        {errors.descuentoProducto}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divInput}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        className={classes.radio}
                        value={discountCheck ? "P" : "V"}
                        onChange={handleChangeRadioDiscount}
                      >
                        <FormControlLabel
                          value="V"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Valor"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                        <FormControlLabel
                          value="P"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Porcentaje"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>
                      IVA: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <FormControl className={classesGlobales.select}>
                      <NativeSelect
                        value={product.codigoPorcentajeIVA}
                        onChange={handleChangeTaxes("codigoPorcentajeIVA")}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" disabled>
                          --Seleccione--
                        </option>
                        {valorIva.map((iva) => (
                          <option key={iva.CODIGO} value={iva.CODIGO}>
                            {iva.NOMBRE}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={3} className={classes.divForm}>
                    <label>ICE:</label>
                  </Grid>
                  <Grid item xs={12} md={9} className={classes.divInput}>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        value={product.codigoPorcentajeICE}
                        onChange={handleChangeTaxes("codigoPorcentajeICE")}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" value=''>
                          --Ninguno--
                        </option>
                        {valorICE.map((ice) => (
                          <option key={ice.CODIGO} value={ice.CODIGO}>
                            {`${ice.CODIGO} - ${ice.NOMBRE}`}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  xs={12}
                  md={12}
                  justify="center"
                  alignItems="center"
                >
                  <EditTable
                    dataAditional={
                      Array.isArray(product.informacionAdicional)
                        ? product.informacionAdicional
                        : []
                    }
                    setDataAditional={handleChangeMoreInfo}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddProduct}
                  className={classesGlobales.button}
                >
                  Guardar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditDetailGeneric;
