import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { useDispatch } from "react-redux";
import { signOffUserAction } from "redux/actions/userActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  textExit: {
    color: "#3d3c3c",
  },
}));

export default function DialogConfirmExit({ openDialog, closeDialog }) {
  const ownClasses = useStyles();
  const [open, setOpen] = React.useState(openDialog);
  const history = useHistory();
  const dispatch = useDispatch();
  const signOff = () => dispatch(signOffUserAction());

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpen(false);
    closeDialog(false);
  };

  const handleExitApp = () => {
    history.push("/");
    signOff();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="primer logeo"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <DialogContentText>
          <Grid container align="center">
            <Typography
              aling="center"
              variant="h5"
              className={ownClasses.textExit}
            >
              ¿Está seguro que desea salir?
            </Typography>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item md={12} align="center">
            <Divider></Divider>
          </Grid>
          <Grid item md={6} align="center">
            <Button color="primary" variant="contained" onClick={handleExitApp}>
              Confirmar
            </Button>
          </Grid>
          <Grid item md={6} align="center">
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
