import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { palette } from 'configurations/styles/theme';
import { ConfirmAlert, iconsAlert } from 'utils/AlertsCustom';


const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
}));
const DeleteAddresseeBtn = ({ data, destinatarios, setDestinatarios, viewAddressee, setViewAddressee }) => {


    const classes = useStyles();
    const deleteAddressee = () => {
        ConfirmAlert(
            `¿Está seguro de eliminar este destinatario?`,
            "",
            iconsAlert.question,
            true,
            "Sí",
            "Cancelar",
            () => {
                setDestinatarios(destinatarios.filter(addresse => addresse.id !== data.id));
                if (viewAddressee === 1) {
                    setViewAddressee(2)
                } else {
                    setViewAddressee(1)
                }
            },
            () => {
                if (viewAddressee === 1) {
                    setViewAddressee(2)
                } else {
                    setViewAddressee(1)
                }
            }
          );    
    }
    return (
        <>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={deleteAddressee} className={classes.IconButton}>
                <DeleteForeverIcon style={{ color: palette.error.main }} />
            </IconButton>
        </>
    );
}
export default DeleteAddresseeBtn;