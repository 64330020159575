import {
    START_SEARCHAUT_VOUCHERS,
    SEARCHAUT_VOUCHERS_SECCES,
    SEARCHAUT_VOUCHERS_ERROR,
    SEARCHAUT_VOUCHERS_CLEAR
} from '../types/voucherTypes';

import  clientBackend  from 'configurations/axios';

export function searchVouchersAction(requestConfig,param) {
    return async (dispatch) => {
        // Empieza a leer
        dispatch(getSeacrhVouchers(true));
        await clientBackend.post(requestConfig.uri, {
            datosReceptor: param.identifier,
            fechaFin: param.dateEnd,
            fechaInicio: param.dateStart,
            filas: requestConfig.elementos,
            pagina: requestConfig.page,
            secuencial: param.sequencial,
            tipoComprobante: param.type,
            ...requestConfig.body
        }).then(
            response=>{
                if (response.status <=201) {                                       
                    //agrego los vouchers al state
                    dispatch(getSeacrhVouchersSuccess(response.data.content));
                }            }
        ).catch(exception=>{
            if (exception.response) {
                console.error('error 1', exception.response.data.apierror.mensaje);
            } else {
                console.error('error 2', exception);
            }
            dispatch(getSeacrhVouchersError(true));
        })
    };

}

const getSeacrhVouchers=(estado)=>({
    type:START_SEARCHAUT_VOUCHERS,
    payload:estado

})
const getSeacrhVouchersSuccess=(comprobantes)=>({
    type:SEARCHAUT_VOUCHERS_SECCES,
    payload:comprobantes
})
const getSeacrhVouchersError=(estado)=>({
    type:SEARCHAUT_VOUCHERS_ERROR,
    payload:estado
})

export const clearSearchVouchers = () => ({
    type: SEARCHAUT_VOUCHERS_CLEAR
});
