import React, { Fragment } from "react";
import { useStylesApp } from "hooks/useStyles";

const TextError = ({ text }) => {
  const classesApp = useStylesApp();
  return (
    <Fragment>
      {text ? <p className={classesApp.errorLetters}>{text}</p> : ""}
    </Fragment>
  );
};

export default TextError;
