import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
} from "@material-ui/core";
import ListNotifications from "./list";
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
    padding: "2%",
    display: "flex",
  },
  btnClose: {
    marginBottom: "1%",
  },
}));



const DialogNotifications = ({ open = true, onClose, notifications }) => {
  const classes = useStyles();
  const [openDialog, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={openDialog} onClose={handleClose}>
      <DialogTitle>Notificaciones</DialogTitle>
      <DialogContent>
        <ListNotifications notificationsProps={notifications} onClose={handleClose}/>
      </DialogContent>
      <Grid container justify="center" align="center" direction="row">
        <Grid item md={3} xs={12} className={classes.btnClose}>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
            fullWidth
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default React.memo(DialogNotifications);
