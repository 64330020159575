export function getByteArray(file) {
    return new Promise(function (resolve, reject) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = function () {
            let readerBase64 = new FileReader();
            var realMimeType = getRealMimeType(fileReader);
            if (realMimeType !== 'unknown') {
                readerBase64.readAsDataURL(file);
            } else {
                reject("error on mime type...");
            }

            fileReader.readAsArrayBuffer(file);

            readerBase64.onloadend = function () {
                var base64 = this.result;
                resolve(base64); 
                readerBase64.abort();
                fileReader.abort();
            }
            
        }
        fileReader.onerror = reject; // call reject if error

        
    })
}

function getRealMimeType(reader) {
    var arr = (new Uint8Array(reader.result)).subarray(0, 4);

    var header = '';
    var realMimeType;

    for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
    }

    // magic numbers: http://www.garykessler.net/library/file_sigs.html
    switch (header) {
        case "89504e47":
            realMimeType = "image/png";
            break;
        case "47494638":
            realMimeType = "image/gif";
            break;
        case "ffd8ffDB":
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            realMimeType = "image/jpeg";
            break;
        default:
            realMimeType = "unknown"; // Or you can use the blob.type as fallback
            break;
    }

    return realMimeType;
}

export function getByteArrayOfP12(file){
    return new Promise(function (resolve, reject) {
     
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = function () {
            let readerBase64 = new FileReader();
            readerBase64.readAsDataURL(file);
            fileReader.readAsArrayBuffer(file);
            var base64 = null;
            readerBase64.onloadend = function () {
                base64 = this.result;
                resolve(base64);  
                readerBase64.abort();
                fileReader.abort();
            }

        }
        fileReader.onerror = reject; // call reject if error
    })
}