import {
    START_AUTHORIZED_VOUCHERS,
    AUTHORIZED_VOUCHERS_SECCESS,
    AUTHORIZED_VOUCHERS_ERROR,
    CLEAR_AUTHORIZED_VOUCHERS
} from '../types/voucherTypes';

const initialState = {
    comprobantes: [],
    error: null,
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case START_AUTHORIZED_VOUCHERS:
            return ({
                ...state,
                loading: action.payload
            });
        case AUTHORIZED_VOUCHERS_SECCESS:
            return ({
                ...state,
                error: null,
                comprobantes: action.payload
            });
        case AUTHORIZED_VOUCHERS_ERROR:
            return ({
                ...state,
                loading: false,
                error: action.payload
            });
        case CLEAR_AUTHORIZED_VOUCHERS: 
            return initialState;
        default:
            return state;
    }
}