import React, { Fragment } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { palette } from "../../../../../configurations/styles/theme";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  divInfoUser: {
    borderStyle: "solid",
    borderColor: palette.primary.main,
    borderWidth: "1px",
    borderRadius: "8px",
    marginTop: "1%",
  },
  divlabel: {
    marginTop: "1%",
  },
  buttonClose: {
    width: "75%",
  },
}));

const DeleteReimbursementDetailBtn = ({
  reimbursementDetail,
  setReimbursementDetail,
  //   productsDetalle,
  //   setProductsDetalle,
  data,
  detalles,
  setDetalles,
  additionalInfoInvoice,
  setAdditionalInfoInvoice,
}) => {
  const classes = useStyles();

  const deleteDetail = () => {
    ConfirmAlert(
      `¿Está seguro de eliminar el reembolso?`,
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        setReimbursementDetail(
          reimbursementDetail.filter(
            (reimbursement) => reimbursement.id !== data.id
          )
        );
        setAdditionalInfoInvoice(
          additionalInfoInvoice.filter((addInfo) => addInfo.id !== data.id)
        );
        // setDetalles(detalles.filter((detalle) => detalle.id !== data.id));

        // if (viewDetalle === 1) {
        //     setViewDetalle(2)
        // } else {
        //     setViewDetalle(1)
        // }
      }
      // () => {
      //     if (viewDetalle === 1) {
      //         setViewDetalle(2)
      //     } else {
      //         setViewDetalle(1)
      //     }
      // }
    );
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={deleteDetail}
        className={classes.IconButton}
      >
        <DeleteForeverIcon style={{ color: palette.error.main }} />
      </IconButton>
    </Fragment>
  );
};

export default DeleteReimbursementDetailBtn;
