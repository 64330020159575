import React, { Fragment, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//componente del sistema
import Alert from "widgets/alert";

import { getUserProfile, getState, parseJwt } from "redux/actions/userActions";
import DialogChangePass from "./dialogChangePass";
import UseSecurity from "security/useSecurity";
import { getTkTmp } from "redux/sessionstorage";

const validStateUser = (stateUser, profile) => {
  switch (stateUser) {
    case "ACT":
      return {
        show: false,
        msg: null,
        redirect: "/facturacion",
      };
    case "PRI":
      return {
        show: false,
        msg: null,
        redirect: "/primer-logeo",
        profile: profile,
      };
    case "DES":
      return {
        show: true,
        msg: "El usuario fue desactivado.",
        redirect: null,
      };
    case "BLO":
      return {
        show: true,
        msg: "Número de intentos excedido. Comuníquese con el administrador.",
        redirect: null,
      };
      break;
    default:
      return {
        show: true,
        msg: "Estado del usuario indefinido. Comuníquese con el administrador.",
        redirect: null,
      };
  }
};

/**
 * @author {dany_lasso}
 * @param {history} hook_react_router_dom para rediccionar al módulo que corresponde el usuario
 * @param {*} userAuth objeto con la autenticacion del usuario
 */
const validUser = (userAuth) => {
  let notification = { show: false, msg: null, redirect: null };
  if (userAuth.error) {
    //ingresa cuando existe un error en la autenticación de usuarios
    notification = {
      show: true,
      msg: userAuth.error,
      redirect: null,
    };
  } else {
    //verifica el estado del usuario
    if (typeof userAuth.tk === "string") {
      // verifica que el token sea un string
      const userData = {
        estado: getState(userAuth.tk),
        perfil: getUserProfile(userAuth.tk),
      };
      notification = validStateUser(userData.estado, userData.perfil);
    } else {
      // verifica que el token sea un string
      notification = {
        show: true,
        msg:
          "datos de autenticación inválidos (token), Comuníquese con el administrador.",
        redirect: null,
      };
    }
  }
  return notification;
};

/**
 * @author {dany_lasso}
 */
const AuthUser = () => {
  const user = useSelector((state) => state.user);
  const [msgLogin, setMsgLogin] = useState({ show: false, msg: null });
  const [openDialogChangePass, setOpenDialogChangePass] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let notification = null;
    const timeout = UseSecurity().sessionTimeOut();
    if (user.tk !== null || user.error !== null) {
      notification = validUser(user);
      if (timeout >= 100) {
        if (notification.show) {
          setMsgLogin({
            show: true,
            msg: notification.msg,
          });
        } else if (notification.redirect === "/facturacion") {
          history.push(notification.redirect);
        } else {
          setOpenDialogChangePass(true);
        }
      }
    } else {
      setOpenDialogChangePass(false);
    }
    const tmpTk = getTkTmp();
    if (tmpTk !== undefined) {
      if (typeof tmpTk === "object") {
        if (typeof tmpTk.accessToken === "string") {
          if (parseJwt(tmpTk.accessToken).state === "PRI") {
            setOpenDialogChangePass(true);
          }
        }
      }
    }
  }, [user, history]);

  function closeAlert() {
    setMsgLogin({
      show: false,
    });
  }

  return (
    <Fragment>
      {msgLogin.show ? (
        <Alert
          tipo="error"
          mensaje={msgLogin.msg}
          mesajeSubrayado={null}
          titulo="Error"
          mostrar={msgLogin.show}
          cerrarAlerta={closeAlert}
        />
      ) : null}
      <DialogChangePass
        openDialog={openDialogChangePass}
        closeDialog={setOpenDialogChangePass}
        onClose={setOpenDialogChangePass}
      />
    </Fragment>
  );
};

export default AuthUser;
