import {
    PROCESS_LOADING,
    PROCESS_FINISHED
} from '../types/processAsyncType';

const initialState = false;

export default function (state = initialState, action) {
    switch (action.type) {
        case PROCESS_LOADING:
            return true;
        case PROCESS_FINISHED:
            return false;
        default:
            return state;
    }
}