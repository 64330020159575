import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Backdrop, CircularProgress, makeStyles, Grid, TextField, Divider } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useStylesApp } from 'hooks/useStyles';
import { retentionCode, typeTax, retentionCodeIva, retentionCodeIcd, typeInvoice } from 'views/facturacion/Forms/typeVoucher';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import bigDecimal from 'js-big-decimal';
import { useSnackbar } from 'notistack';
import uuid from 'react-uuid';
import InputMask from "react-input-mask";
import checkInputs from 'utils/checkInputs';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%'
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '1%',
        marginBottom: '1%',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));


export default function useAddTax(impuestoRetencions, setImpuestoRetencions, viewDetalle, setViewDetalle, taxDetail, setTaxDetail) {
    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobales = useStylesGlobal();
    const { enqueueSnackbar } = useSnackbar();
    const minDate = new Date();
    const minDate1 = minDate.setDate(minDate.getDate() - 90)
    const [open, setOpen] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [retention, setRetention] = useState({
        CODIGO: '',
        VALOR: 0,
        NOMBRE: ''
    });
    const [tax, setTax] = useState();
    const [retainedValue, setRetainedValue] = useState();
    const [fechaEmisionDocSustento, setFechaEmisionDocSustento] = useState(new Date());
    const [docSustento, setDocSustento] = useState();
    const [dataTax, setDataTax] = useState();
    const [retentionTax, setRetentionTax] = useState({
        baseImponible: 0,
        codigoDocSustento: "",
        codigoImpuesto: "",
        codigoRetencion: "",
        fechaEmisionDocSustento: fechaEmisionDocSustento,
        numeroDocSustento: "",
        porcentajeARetener: 0,
    })
    const [empty, setEmty] = useState({
        impuesto: true,
        retencion: true,
        codigo: true,
        base: true,
        documento: true,
        numero: true,
        fecha: true,
    });

    const handleClickOpen = () => {
        setOpen(true);
        //setUser({ idEmpresa: getRUCEmpresa(userToken) });
    };

    const handleClose = () => {
        setOpen(false);
        clearTax();
    };


    const handleChangeData = event => {
        event.preventDefault();
        const re = /^[0-9\b.]+$/;
        switch (event.target.name) {
            case 'baseImponible':
                if (event.target.value === '' || re.test(event.target.value)) {
                    if (Number(event.target.value.trim()) >= 0) {
                        if (checkInputs.countDecimals(event.target.value) <= 2 && checkInputs.countDigit(event.target.value) <= 14) {
                            setRetentionTax({ ...retentionTax, ['baseImponible']: event.target.value })
                            let retainedValue = bigDecimal.multiply(event.target.value, bigDecimal.divide(retention ? retention.VALOR ? retention.VALOR : '0' : '0', '100'));
                            setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));
                            if (typeof event.target.value !== 'undefined' && event.target.value.toString().trim() !== '' && Number(event.target.value !== 0)) {
                                setEmty({ ...empty, ['base']: true })
                            }
                        }
                    }
                }

                break;
            case 'VALOR':
                if (event.target.value === '' || re.test(event.target.value)) {
                    if (Number(event.target.value.trim()) >= 0) {
                        if (checkInputs.countDecimals(event.target.value) <= 2 && checkInputs.countDigit(event.target.value) <= 3) {
                            setRetention({
                                ...retention,
                                ['VALOR']: event.target.value,
                            })

                            let retainedValue = bigDecimal.multiply(retentionTax.baseImponible, bigDecimal.divide(event.target.value, '100'));
                            setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));

                            if (typeof event.target.value !== 'undefined' && event.target.value.toString().trim() !== '' && Number(event.target.value) !== 0) {
                                setEmty({ ...empty, ['codigo']: true })
                            }
                        }
                    }
                }

                break;
            default:
                break;
        }




    }

    const calculateRetainedValue = (base, porcent) => {
        if (validateDecimal(base.toString())) {
            let retainedValue = bigDecimal.multiply(base, bigDecimal.divide(porcent ? porcent : '0', '100'));
            setRetainedValue(bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP));
        }
    }


    const handleAddTax = (data) => {
        const errors = validateTax()
        const isValid = Object.values(errors).filter(item => item === false).length === 0
        setEmty(errors)
        if (isValid) {
            let uuId = uuid();
            setTaxDetail([...taxDetail, {
                id: uuId,
                codigoImpuesto: tax.CODIGO,
                codigoRetencion: retentionTax.codigoRetencion,                
                porcentaje: retention.VALOR,                
                baseImponible: retentionTax.baseImponible,
                valorRetenido: retainedValue,

            }])

            setImpuestoRetencions([...impuestoRetencions, {
                ...retentionTax,
                id: uuId,
                porcentajeARetener: retention.VALOR,
            }]);

            if (viewDetalle === 1) {
                setViewDetalle(2)
            } else if (viewDetalle === 2) {
                setViewDetalle(1)
            }

            handleClose();
            clearTax();
            enqueueSnackbar(`Impuesto agregado al comprobante`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
        }
    }

    const validateTax = () => {
        let errors = {
            impuesto: true,
            retencion: true,
            codigo: true,
            base: true,
            numero: true,
        };

        if (typeof tax === 'undefined' || tax === '') {
            errors.impuesto = false
        }

        if (typeof retention === 'undefined' || retention.CODIGO.trim() === '-' || retention.CODIGO.trim() === '') {
            errors.retencion = false
        }

        if (typeof retention === 'undefined' || Number(retention.VALOR.toString().trim()) < 0 || retention.VALOR.toString().trim() === '') {
            errors.codigo = false
        }

        if (typeof retentionTax.baseImponible === 'undefined' || retentionTax.baseImponible.toString().trim() === '' || !validateDecimal(retentionTax.baseImponible.toString())) {
            errors.base = false
        }

        return errors

    }

    const clearTax = () => {
        setRetentionTax({
            baseImponible: 0,
            codigoImpuesto: "",
            codigoRetencion: "",
        })
        setRetention({
            CODIGO: '',
            VALOR: 0,
            NOMBRE: ''
        });
        setRetainedValue();
        setTax();
        setEmty({
            impuesto: true,
            retencion: true,
            codigo: true,
            base: true,
            numero: true,
        })
    }

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            enqueueSnackbar(`El dato ingresado es erroneo utilice punto (.) para separar decimales`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                enqueueSnackbar(`Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }

    }

    return {
        classes,
        classesApp,
        classesGlobales,
        minDate,
        minDate1,
        open,
        openLoader,
        setOpenLoader,
        retention,
        tax,
        retainedValue,
        dataTax,
        setDataTax,
        retentionTax,
        empty,
        handleClickOpen,
        handleClose,
        handleChangeData,
        calculateRetainedValue,
        handleAddTax,
        setTax,
        setRetentionTax,
        setEmty,
        setRetention,
        setRetainedValue,
    }
}