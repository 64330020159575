import React from "react";
import { IconButton, Avatar, makeStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  IconButton: {
    marginTop: "1px",
    marginBottom: "1px",
    padding: "0px",
    marginRight: "5px",
  },
}));

const DeleteEmail = ({ data,emails,setEMails,viewEmails,setViewEmails,setUpdate }) => {
  const classes = useStyles();
  const deletePayment = () => {
    setEMails(emails.filter(email => email.correo !== data.correo))
    setUpdate(true);
    if(viewEmails===1){
      setViewEmails(2)
  }else{
    setViewEmails(1)
  }
}
  return (
    <div>
      <IconButton
        aria-label="delete"
        className={classes.IconButton}
        variant="outlined"
        color="secondary"
        onClick={deletePayment}
      >
        <CancelIcon></CancelIcon>
      </IconButton>
    </div>
  );
};

export default DeleteEmail;
