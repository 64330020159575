import React from "react";
import { makeStyles } from '@material-ui/core/styles';

//https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { palette } from "configurations/styles/theme";

const useStyles = makeStyles({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%'
    },
    containerTitle: {
        textAlign: "center",
        marginTop: "1%",
        marginBottom: "1%",
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "8px",
        borderColor: palette.primary.main
    },
    container: {
        marginTop: "1%",
        marginBottom: "1%",
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "8px",
        borderColor: palette.primary.main
    },
    editor: {
        borderRadius: "6px",
        borderStyle: "solid",
        borderWidth: "1px",
        boxShadow: "10px 10px 5px #aaaaaa",
        marginBottom: "0.5%",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        height: "400px",
        minHeight: "35%",
    },
    button: {
        borderColor: palette.primary.dark,
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: "1px",
        marginTop: "0.5%",
        maxWidth: "70%",
        padding: "0.5%",
        width: "70%",
        marginRight: "auto",
        marginLeft: "auto",
        "&:hover": {
            color: palette.primary.contrastText,
            background: palette.primary.main
        }
    }
})

const Assistance = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <section className={classes.containerTitle}>
                <hgroup>
                    <h5>Servicio al cliente</h5>
                    <strong>Asistencia</strong>
                </hgroup>
            </section>
            <section className={classes.container}>
                <div class="row">
                    <div class="col-sm-1 mt-1 mb-1" align="right">
                        <label>De:</label>
                    </div>
                    <div class="col-sm-6 mt-1 mb-1">
                        <input type="text" className="form-control" />
                    </div>
                    <hr />
                    <div class="col-sm-12">
                        <div className={classes.editor}>
                            <Editor />
                        </div>
                    </div>
                    <div class="col-sm-12" align="center">
                        <button className={classes.button}>Enviar</button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Assistance;