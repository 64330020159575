import React  from "react";
import { makeStyles } from '@material-ui/core/styles';
import { palette } from "configurations/styles/theme";

const useStyles = makeStyles({
    button: {
        width: "100%",
        maxWidth: "100%",
        padding: "0px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: palette.primary.dark,
        borderRadius: "8px",
        "&:hover": {
            color: palette.primary.contrastText,
            background: palette.primary.main
        }
    }
});

const BtnUpdateCertificate = () => {

    const classes = useStyles();
    return (
        <>
            <button className={classes.button}>Actualizar Certificado</button>
        </>
    );
}

export default BtnUpdateCertificate;