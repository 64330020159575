import { EnumErrorsNotifications } from "widgets/Notifications";
import {
  parseNotificationToArray,
  addErrorCompanyAction,
} from "redux/actions/resourcesActions";
import { httpCore } from "configurations/axios";
import Apis from "./apis";
import typesNotifications from "widgets/alert/types";
import uuid from "react-uuid";
export const checkPreRequirements = (sub, ruc, dispatch) => {
  const request = {
    idAsignacionEmpresaUsuario: sub,
    rucEmpresa: ruc,
  };
  return new Promise(async (resolve, reject) => {
    await httpCore
      .post(Apis.POST_VALIDATION_GENERATION_COMP, request)
      .then((response) => {
        if (typeof response.data === "object") {
          let errorsCompany = parseNotificationToArray(
            response.data.errores,
            typesNotifications.error
          );

          errorsCompany = errorsCompany.filter(
            (item) =>
              item.keyword === EnumErrorsNotifications.sinCertificado ||
              item.keyword === EnumErrorsNotifications.certificadoExp ||
              item.keyword === EnumErrorsNotifications.sinNumeracion ||
              item.keyword === EnumErrorsNotifications.paqueteExp ||
              item.keyword === EnumErrorsNotifications.sinComprobantes

          );
          dispatch(addErrorCompanyAction(errorsCompany));
          resolve(errorsCompany);
        }
        resolve([]);
      })
      .catch((error) => {
        dispatch(
          addErrorCompanyAction([
            {
              key: uuid(),
              type: typesNotifications.error,
              msg: `Existen problemas en la red, no se pudo determinar 
              si cumple los pre-requisitos para generar comprobantes electrónicos.
               Contáctese con el administrador del Sistema. `,
              keyword: "Network_error",
            },
          ])
        );
        reject(error);
      });
  });
};

export const filterErrorsOfGenerationComp = (errorsCompany = []) => {
  let errors = [];
  if (Array.isArray(errorsCompany)) {
    errors = errorsCompany.filter(
      (item) =>
        item.keyword === EnumErrorsNotifications.sinCertificado ||
        item.keyword === EnumErrorsNotifications.certificadoExp ||
        item.keyword === EnumErrorsNotifications.sinNumeracion ||
        item.keyword === EnumErrorsNotifications.paqueteExp ||
        item.keyword === EnumErrorsNotifications.sinComprobantes
    );
  }
  return errors;
};

export const checkPreRequirementsCoucher = () => {


}

export const checkPreRequirementsVoucher = async (sub, ruc,) => {
  await httpCore
    .post(Apis.POST_VALIDATION_GENERATION_COMP, {
      idAsignacionEmpresaUsuario: sub,
      rucEmpresa: ruc,
    })
    .then((response) => {
      if (typeof response.data === "object") {
        let errorsCompany = parseNotificationToArray(
          response.data.errores,
          typesNotifications.error
        );

        errorsCompany = errorsCompany.filter(
          (item) =>
            item.keyword === EnumErrorsNotifications.sinCertificado ||
            item.keyword === EnumErrorsNotifications.certificadoExp ||
            item.keyword === EnumErrorsNotifications.sinNumeracion ||
            item.keyword === EnumErrorsNotifications.paqueteExp ||
            item.keyword === EnumErrorsNotifications.sinComprobantes

        );

        return errorsCompany;
      }
    })
    .catch((exception) => {
      console.error({ exception });
      return false;
    });
};