import useTableAuthorizedIssuedVoucher from "./useTableAuthorizedIssuedVoucher";
import useIndexAuthorizedIssuedVoucher from "./useIndexAuthorizedIssuedVoucher";

export {
    useTableAuthorizedIssuedVoucher,
    useIndexAuthorizedIssuedVoucher
};

export function parseDate(dateISO) {
    let date = dateISO
    if (typeof dateISO !== "string") {
        date = dateISO.toString()
    }

    return date.substring(0, 10)
}

export function parseDatetime(dateISO) {
    let date = dateISO
    if (typeof dateISO !== "string") {
        date = dateISO.toString()
    }

    return date.substring(0, 10) + " " + (new Date(date)).toString().substring(15, 24)
}