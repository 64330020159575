import React, { useState, Fragment } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { palette } from "configurations/styles/theme";
import EditIcon from "@material-ui/icons/Edit";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { useSnackbar } from "notistack";

import TextError from "componets/TextError";
import { validationsDetailDispatcher } from "../validations";
import TextRequired from "componets/TextRequired";
import TextRequiredAsterisks from "componets/TextRequiredAsterisks";
import { styleInput } from "configurations/styles/theme";

const EditDetailDispatcher = ({
  data,
  detalleGuiaRemision,
  setDetalleGuiaRemision,
  viewDetalle,
  setViewDetalle,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [product, setProduct] = useState(data);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleUpdateDetail = () => {

    let newErros = validationsDetailDispatcher.onSubmit(product);
    if (Object.keys(newErros).length > 0) {
      setErrors({ ...errors, ...newErros });
    }else{
      detalleGuiaRemision.map(function updateProducts(prod, index) {
        if (compareObject(data, prod)) {
          detalleGuiaRemision.splice(index, 1, product);
          enqueueSnackbar(`Detalle fue editado`, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          });
        }
      });
  
      if (viewDetalle === 1) {
        setViewDetalle(2);
      } else if (viewDetalle === 2) {
        setViewDetalle(1);
      }
      handleClose();
    }

    
  };

  const compareObject = (rowData, prod) => {
    if (rowData.id === prod.id) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (event) => {
    event.preventDefault();

    const { error, value, keyWordError } = validationsDetailDispatcher.onChange(
      event.target.name,
      event.target.value
    );

    if (event.target.name === "codigoInterno") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "descripcion") {
      if (error === null) {
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "cantidad") {
      if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong") {
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
      }
      setErrors({
        ...errors,
        [event.target.name]: error,
      });
    }
    if (event.target.name === "codigoAdicional") {
      setProduct({
        ...product,
        [event.target.name]: event.target.value,
      });
    }
  };





  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
      >
        <EditIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">Editar detalle</DialogTitle>
        <DialogContent>
          <form >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  justify="center"
                  aria-controls="panel1a-content"
                ></Grid>

                <Grid item md={12}>
                  <TextRequiredAsterisks />
                </Grid>
                <Grid item md={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={2} className={classes.divForm}>
                  <TextRequired text={"Código interno"}></TextRequired>
                </Grid>
                <Grid item xs={4} className={classes.divInput}>
                  <input
                    type="text"
                    placeholder="Ej. 022044"
                    name="codigoInterno"
                    value={product.codigoInterno}
                    className={styleInput(
                      errors.codigoInterno,
                      product.codigoInterno
                    )}
                    onChange={handleChange}
                  />
                  <TextError text={errors.codigoInterno}></TextError>
                </Grid>

                <Grid item xs={2} className={classes.divForm}>
                  <label>Código adicional:</label>
                </Grid>
                <Grid item xs={4} className={classes.divInput}>
                  <input
                    type="text"
                    placeholder="Ej. 550"
                    name="codigoAdicional"
                    value={product.codigoAdicional}
                    onChange={handleChange}
                    className={styleInput(
                      errors.codigoAdicional,
                      product.codigoAdicional
                    )}
                  />
                </Grid>

                <Grid item xs={2} className={classes.divForm}>
                  <TextRequired text={"Descripción"}></TextRequired>
                </Grid>
                <Grid item xs={4} className={classes.divInput}>
                  <input
                    type="text"
                    placeholder="Ej. Ordenador"
                    name="descripcion"
                    value={product.descripcion}
                    onChange={handleChange}
                    className={styleInput(
                      errors.descripcion,
                      product.descripcion
                    )}
                  />
                  <TextError text={errors.descripcion}></TextError>
                </Grid>

                <Grid item xs={2} className={classes.divForm}>
                  <TextRequired text={"Cantidad"}></TextRequired>
                </Grid>
                <Grid item xs={4} className={classes.divInput}>
                  <input
                    placeholder="Ej. 45"
                    name="cantidad"
                    autoComplete="off"
                    value={product.cantidad}
                    onChange={handleChange}
                    className={styleInput(errors.cantidad, product.cantidad)}
                  />
                  <TextError text={errors.cantidad}></TextError>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              align="center"
              spacing={2}
            >
              <Grid item md={12}>
                <Divider></Divider>
              </Grid>

              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateDetail}
                >
                  Actualizar detalle
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default EditDetailDispatcher;
