import { Card, CardActions, CardContent, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import AddPackage from '../buttons/AddPackage';

const useStyles = makeStyles((theme) => ({

    root: {
        borderWidth: '2px',
        borderStyle: "solid",
        border: '#ccddf0',

    },
    root1: {
        minWidth: 275,
        borderStyle: "solid",
        borderWidth: '2px',
        //backgroundColor: '#87afdb',
        border: '#87afdb',

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 12,
    },
    subTitle: {
        marginTop: 12,
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 12,

    },
    pos: {
        marginBottom: 12,
    },
    margin: {
        width: '70%',
    },
    gridButton: {
        textAlign: 'center',
    }
}));



const Plan = ({ plan, size }) => {
    const classes = useStyles();
    var sizeGrid = 0;
    /* size=4 -->3;
    size =3 -->4
    size>=5-->4
    */
    const getSize = () => {
        switch (size) {
            case 4:
                return 3;
            case 3:
                return 4;
            default:
                return 4;
        }
    }
    return (
        <>{

        }
            <Grid item xs={12} md={getSize()}>
                <Paper>
                    <Card className={classes.root} variant="outlined">
                        <CardContent >
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                <strong> {plan.nombre}</strong>
                            </Typography>
                            <Divider />
                            <Typography className={classes.subTitle} >
                                <strong> $. {plan.precio.toFixed(2)} * + IVA</strong>
                            </Typography>
                            <Typography className={classes.subTitle} >
                                <strong> {plan.credito} comprobantes</strong>
                                <br />
                            </Typography>
                            <Typography className={classes.subTitle} gutterBottom>
                                Tiempo de vigencia de un año a partir de la fecha de contratación
                        </Typography>
                            <Typography className={classes.subTitle} gutterBottom>
                                Soporte vía Correo Electrónico en horarios de oficina
                        </Typography>
                            <Typography className={classes.subTitle} gutterBottom>
                                Lunes a Viernes de 08: 30 a 17: 30
                        </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions >
                            <Grid item xs={12} md={12} className={classes.gridButton}>
                                {<AddPackage
                                    plan={plan}
                                />}
                            </Grid>
                        </CardActions>
                    </Card>

                </Paper>
            </Grid>
        </>
    )
}

export default Plan;