import React, { useState } from 'react';
import {
    makeStyles, Dialog, DialogTitle, DialogContent, Grid, Button,
    Backdrop, CircularProgress
} from '@material-ui/core';
import { getByteArrayOfP12 } from "utils/servicesForFiles";
import DragAndDrop from "widgets/DragAndDrop";
import { palette } from 'configurations/styles/theme';
import BtnUpdateCertificate from "./Buttons/BtnUpdateCertificate";
import checkInputs from "utils/checkInputs";
import middleware from "configurations/axios";
import apis from "./apis"

const useStyles = makeStyles(theme =>({
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: "0px",
        borderRadius: "8px",
        background: "#FFFFFF"
    },
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const UpdateCertificate = ({ idEmpresa, onShowAlert }) => {
    const classes = useStyles();
    const [file, setFile] = useState({
        name: null,
        buffer: null,
    });
    const [open, setOpen] = useState(false);
    const [pass, setPass] = useState("");
    const [error, setError] = useState(null);
    const [openLoader, setOpenLoader] = useState(false);
    const [inputFile, setInputFile ]= useState(null);
    const handleFile = (newfile) => {
        //verifica la extension del archivo
        if (newfile.type === "application/x-pkcs12") {
            getByteArrayOfP12(newfile).then(
                (byteArray) => {
                    setFile({
                        name: newfile.name,
                        buffer: byteArray.substring(33)
                    })
                    setOpen(true);
                }
            ).catch(
                error => onShowAlert({ show: true, msg: "Ocurrió un error al cargar el certificado.", type: "error" })

            );

        } else {
            onShowAlert({ show: true, msg: "Solo se admiten archivos con la extensión: p12.", type: "error" });
        }
    }

    const procesarArchivo = event => {
        let files = event.target.files;
        if (files.length === 1) {
            //verifica la extension del archivo
            handleFile(files[0]);
        } else  if (files.length > 1){
            onShowAlert({ show: true, msg: '"Se detectó varios archivos seleccionados, por favor solo seleccione uno.', type: "error" });
        }
    }

    const handleChangePass = event => {
        setPass(event.target.value)
    }

    const handleClose = () => {
        setOpen(false);
        setFile({
            name: null,
            buffer: null,
        })
        setPass("");
        setOpen(false);
        setOpenLoader(false);
        setInputFile("");
    }

    const handleUpdate = async () => {
        if (!checkInputs.notNull(pass)) {
            setError("La contraseña es necesaria.");
            return;
        }
        setOpenLoader(true);
        await middleware.post(apis.update_p12, {
            certificado: file.buffer,
            claveCertificado: pass,
            idEmpresa: idEmpresa
        }).then(
            respones => {
                if (respones.status >= 200 && respones.status <= 300) {
                    onShowAlert({ show: true, msg: "Certificado actualizado.", type: "success" });
                   
                }
                setFile({
                    name: null,
                    buffer: null,
                })
                setPass("");
                setOpen(false);
                setOpenLoader(false);
                setInputFile("");
            }
        ).catch(
            exception => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        onShowAlert({ show: true, msg: exception.response.data.apierror.mensaje, type: "error" });
                    } else if (exception.response.status === 404) {
                        onShowAlert({ show: true, msg: exception.response.data.apierror.mensaje + '. Contáctese con el administrador del Sistema.', type: "error" });
                    }
                } else {
                    onShowAlert({ show: true, msg: 'Existen problemas en la red. Contáctese con el administrador del Sistema.', type: "error" });
                }
                setFile({
                    name: null,
                    buffer: null,
                })
                setPass("");
                setOpen(false);
                setOpenLoader(false);
                setInputFile("");
            }
        );

    }

    return (
        <>
            <label className={classes.content}>
                <input type="file" style={{ display: "none" }}  onChange={procesarArchivo} value={inputFile} />
                <DragAndDrop setFiles={handleFile} ButtonPostGetFile={BtnUpdateCertificate} />
            </label>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Actualización del Certificado</DialogTitle>
                <DialogContent>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <label>Los campos marcados con (  <font color={palette.error.main}> *</font> )son obligatorios:</label>
                        </Grid>
                        <Grid item xs={3}>
                            <label>Ruc:<font color={palette.error.main}> *</font> </label>
                        </Grid>
                        <Grid item xs={9}>
                            <input class="form-control is-valid"
                                value={idEmpresa}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <label>Archivo:<font color={palette.error.main}> *</font> </label>
                        </Grid>
                        <Grid item xs={9}>
                            <input class="form-control is-valid"
                                value={file.name}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <label>Contraseña del certificado: <font color={palette.error.main}> *</font> </label>
                        </Grid>
                        <Grid item xs={9}>
                            <input class={error ? " form-control is-invalid" : "form-control"}
                                type="password"
                                onChange={handleChangePass}
                                value={pass}
                            />
                            {error ? <small style={{ color: palette.error.main }}>{error}</small> : null}
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="primary" onClick={handleUpdate}>ACTUALIZAR</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>CANCELAR</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default UpdateCertificate;