import actions from "../types/debitNoteType";


export const addRUCAction = (ruc) => {
    return ({
        type: actions.ADD_RUC,
        payload: ruc
    })
}

export const addRISEAction = (rise) => {
    return ({
        type: actions.ADD_RISE,
        payload: rise
    })
}

export const addReasonsAction = (reasons) => {
    return ({
        type: actions.ADD_REASONS,
        payload: reasons
    })
}

export const AddUserAction = (user) => {
    return ({
        type: actions.ADD_USER,
        payload: user
    })
}

export const AddCostumerAction = (costumer) => {
    return ({
        type: actions.ADD_COSTUMER,
        payload: costumer
    })
}

export const AddCodeTaxICEAction = (codeTaxICE) => {
    return ({
        type: actions.ADD_CODE_TAX_ICE,
        payload: codeTaxICE
    })
}

export const AddCodeTaxIVAAction = (codeTaxIVA) => {
    return ({
        type: actions.ADD_CODE_TAX_IVA,
        payload: codeTaxIVA
    })
}

export const SaveCacheAction = (cache) => {
    return ({
        type: actions.SAVE_CACHE,
        payload: cache
    })
}

export const AddMoreInfoAction = moreInfo => {
    return ({
        type: actions.ADD_MORE_INFO,
        payload: moreInfo
    })
}

export const changeDateEmisionAction = date => {
    return ({
        type: actions.CHANGE_DATE_EMISION,
        payload: date
    })
}

export const changeDateAuthAction = date => {
    return ({
        type: actions.CHANGE_DATE_AUTH,
        payload: date
    })
}

export const changeNumDocAction = numDoc => {
    return ({
        type: actions.CHANGE_NUM_DOC,
        payload: numDoc
    })
}

export const clearDebitNoteAction = () => {
    return ({
        type: actions.CLEAR_ALL,
    })
}

export const changeErrorLabelsAction = (error) => ({
    type: actions.CHANGE_ERRORS_LABELS,
    payload: error
})