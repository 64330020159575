import React from "react";
import {
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { ConfirmAlert, iconsAlert } from "utils/AlertsCustom";
import DeleteIcon from "@material-ui/icons/Delete";
import { palette } from "configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
}));

const DeleteProduct = ({ product, onDelete }) => {

  const classes = useStyles();

  const handleClickOpen = () => {
    ConfirmAlert(
        "¿Está seguro que desea eliminar el detalle?",
        "",
        iconsAlert.question,
        true,
        "Eliminar",
        "Cancelar",
        handleDelete,
        () => {}
    )
  };



  const handleDelete = () => {
    onDelete(product);
    return Promise.resolve({text: "Detalle eliminado."});
  };

  return (
      <IconButton
        onClick={handleClickOpen}
        variant="outlined"
        className={classes.IconButton}
      >
        <DeleteIcon style={{ color: palette.error.main }} />
      </IconButton>
    
  );
};

export default DeleteProduct;
