import checkInputs from "utils/checkInputs";
import { validarNumDocumentoSRI } from "utils/checkDocument";

const validation = {
  newReason: {
    onChange: function checkChangeReason(nameInput = "", valueInput = "") {
      let error = null;
      switch (nameInput) {
        case "razon":
          if (!checkInputs.isAlphanumeric(valueInput)) {
            error = "No ingrese caracteres especiales.";
          } else if (checkInputs.countLenth(valueInput) > 300) {
            error = "No ingrese más de 300 caracteres.";
          }
          break;
        case "valor":
          if (!checkInputs.isDecimal(valueInput)) {
            error = "Solo ingrese números y/o el punto decimal.";
          } else if (checkInputs.countDecimals(valueInput) > 2) {
            error = "No ingrese más de 2 caracteres.";
          } else if (checkInputs.countLenth(valueInput) > 14) {
            error = "No ingrese más de 14 dígitos.";
          }
          break;
        default:
          break;
      }
      return error;
    },
    onSubmit: function checkSubmitReason(data) {
      const errors = {};

      if (!checkInputs.notNull(data.razon)) {
        errors.razon = "El motivo es necesario.";
      } else if (!checkInputs.isAlphanumeric(data.razon)) {
        errors.razon = "No ingrese caracteres especiales.";
      } else if (checkInputs.countLenth(data.razon) > 300) {
        errors.razon = "No ingrese más de 300 caracteres.";
      }

      if (!checkInputs.notNull(data.valor)) {
        errors.valor = "El valor es necesario.";
      } else if (!checkInputs.isDecimal(data.valor)) {
        errors.valor = "Solo ingrese números y/o el punto decimal.";
      } else if (checkInputs.countDecimals(data.valor) > 2) {
        errors.valor = "No ingrese más de 2 caracteres.";
      } else if (Number(data.valor) <= 0) {
        errors.valor = "Debe ingresar una valor mayor a cero para el Motivo.";
      } else if (checkInputs.countLenth(data.valor) > 300) {
        errors.valor = "No ingrese más de 300 caracteres.";
      }

      return errors;
    },
  },
  allData: function checkAllData(debitNote) {
    const errors = {};

    if (!checkInputs.notNull(debitNote.idAsignacionEmpresaCliente)) {
      errors.idAsignacionEmpresaCliente =
        "Los datos del cliente son necesarios.";
    }

    if (!checkInputs.notNull(debitNote.numeroDocSustento)) {
      errors.numeroDocSustento =
        "Debe ingresar el número del Documento de Sustento.";
    } else if (!validarNumDocumentoSRI(debitNote.numeroDocSustento)) {
      errors.numeroDocSustento =
        "Debe ingresar el número del Documento de Sustento, correctamente.";
    }

    if (!checkInputs.notNull(debitNote.fechaDocSustento)) {
      errors.fechaDocSustento =
        "Debe seleccionar una fecha de emisión del Documento de Sustento.";
    }

    if (!checkInputs.notNull(debitNote.fechaEmision)) {
      errors.fechaEmision =
        "Debe seleccionar una fecha de emisión de la Nota de Débito.";
    }

    if (
      checkInputs.notNull(debitNote.fechaDocSustento) &&
      checkInputs.notNull(debitNote.fechaEmision)
    ) {
      try {
        const fechaDocSustento = new Date(debitNote.fechaDocSustento);
        const fechaEmision = new Date(debitNote.fechaEmision);
        if (fechaDocSustento.getFullYear()  ===  fechaEmision.getFullYear()) {
          if (fechaDocSustento.getMonth() <= fechaEmision.getMonth()) {
            if(fechaDocSustento.getMonth() === fechaEmision.getMonth()){
              if (fechaDocSustento.getDate() > fechaEmision.getDate()) {
                errors.fechaDocSustento =
                  "La fecha de Emisión de la Nota de Crédito no puede ser antes de la fecha del Documento de Sustento.";
              }
            }
          } else {
            errors.fechaDocSustento =
              "La fecha de Emisión de la Nota de Crédito no puede ser antes de la fecha del Documento de Sustento.";
          }
        } else if(fechaDocSustento.getFullYear() > fechaEmision.getFullYear()) {
          errors.fechaDocSustento =
            "La fecha de Emisión de la Nota de Crédito no puede ser antes de la fecha del Documento de Sustento.";
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (debitNote.motivos.length === 0) {
      errors.motivos =
        "Debe ingresar por lo menos un motivo en la Nota de Débito.";
    }

    if (!debitNote.codigoImpuestoIva && !debitNote.codigoImpuestoIce) {
      errors.codigoImpuestoIva =
        "Debe seleccionar al menos un impuesto, IVA y/o ICE.";
    }

    if (checkInputs.notNull(debitNote.rise)) {
      if (!checkInputs.isAlphanumeric(debitNote.rise)) {
        errors.rise = "No ingrese caracteres especiales, en el RISE.";
      } else if (checkInputs.countLenth(debitNote.rise) > 40) {
        errors.rise = "No ingrese más 40 caracteres, en el RISE.";
      }
    }

    return errors;
  },
  rise: function checkRISE(valueInput) {
    let error = null;
    if (!checkInputs.isAlphanumeric(valueInput)) {
      error = "No ingrese caracteres especiales.";
    } else if (checkInputs.countLenth(valueInput) > 40) {
      error = "No ingrese más 40 caracteres.";
    }
    return error;
  },
};

export default validation;
