import React, { useState } from "react";
import "./DragAndDrop.css";

const DragAndDrop = ({ setFiles, ButtonPostGetFile }) => {

    const [isOver, setIsOver] = useState(false);
    const [fileData, setFileData] = useState(null);

    const dragOver = (event) => {
        event.preventDefault();
        setIsOver(true);
    }

    const dragEnter = (event) => {
        event.preventDefault();
    }

    const dragLeave = (event) => {
        event.preventDefault();
        setIsOver(false);
    }

    const fileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        setFileData(files[0]);
        setFiles(files[0]);
    }

    return (
        <>
            <div className="container pl-0 pr-0">
                <div className={isOver ? "container-hover" : "drop-container"}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    {
                        fileData ?
                            <p>Archivo: {fileData.name}, Cargado</p>
                            :
                            <div className="drop-message">
                                <div className="upload-icon"></div>
                                Cambiar Certificado<br />
                                <small>Arrastre el certificado o {" "}
                                <font className="cert-link-btn">clic aquí</font></small>
                            </div>
                    }

                </div>
                <small className="small-message">Su certificado tiene la extensión '.p12'.</small>
                {fileData ? <ButtonPostGetFile /> : null}
            </div>
        </>
    );
}

export default DragAndDrop;