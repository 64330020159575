import React, {useReducer} from "react";

const initialContextCreditNote = {
    costumer: {}
}

const context = React.createContext(initialContextCreditNote);

export const ACTIONS_TYPES =  {
    ON_CHANGE_USER: "ON_CHANGE_USER"
}

function reducer(state, action) {
    switch (action.type) {
      case ACTIONS_TYPES.ON_CHANGE_USER:
        return action.payload;
      default:
        return state;
    }
  }

export const CreditNoteProvaider = ({children}) => {
    const [state, dispatch] = useReducer(reducer,initialContextCreditNote);
    return (  
        <context.Provider  value={{state, dispatch}}>
            {children}
        </context.Provider>
    );
}
 
export default context;