import React, { Fragment } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import OptionsList from "./optionList";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "none",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#FFFFFF",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "50px",
  },
  drawerAppComprador: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  card: {
    marginTop: "0",
    marginBottom: "0",
    border: "none",
    marginLeft: "auto",
    marginRight: "auto",
  },

  settingButtom: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  botonMenu: {
    color: "rgb(59 58 58)",
  },
}));

export default function Menu() {
  const [state, setState] = React.useState(false);
  const classes = useStyles();

  const handleClickMenu = () => {
    setState(!state);
  };

  const toggleDrawer = (open) => () => {
    setState(open);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="upload picture"
        component="span"
        className={classes.botonMenu}
        onClick={handleClickMenu}
      >
        <MenuIcon className={classes.botonMenu} />
      </IconButton>
      <Drawer
        open={state}
        //className={classes.drawer}
        variant="temporary"
        anchor="left"
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper, root: classes.root }}
      >
        <OptionsList onClose={toggleDrawer(false)} />
      </Drawer>
    </Fragment>
  );
}
